import React, { Component } from "react";
import '../../styles/navbar.css';
import resicap_logo from '../../images/resicap_logo.png';
import avatar from '../../images/avatar.jpg';
import apicall from '../../utils/apicall';
import config from '../../utils/config';
import EditUserModal from '../analysts/EditUserModal';
export default class NavbarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            openModal: false,
            userid: '',
            mobile:''
        }
    }

    componentWillMount() {
        config.localforage.getItem('email').then(
            resp => {
                this.setState({
                    email: resp
                })
            }
        )
        config.localforage.getItem('userid').then(
            resp => {
                this.setState({
                    userid: resp
                })
            }
        )
        config.localforage.getItem('mobile').then(
            resp => {
                this.setState({
                    mobile: resp
                })
            }
        )
    }

    refereshData = (mobile) => {
        this.setState({
            mobile:mobile
        })
    }

    toggleModal = () => {
        console.log('togllellllll')
        this.setState({
            openModal: !this.state.openModal
        })
    }

    render() {
        return (
            <React.Fragment>
                <header className="header">
                    <div className="header__logo hidden-sm-down header-logo" id="headerleft">
                        <img src={resicap_logo} alt="Resicap Logo" />
                    </div>
                    <div className="user-div">
                        <div className="users-sub-div">
                            <p>{this.state.email}</p>
                            <p className="logout-btn" onClick={() => apicall.logout()}>Logout</p>
                        </div>
                        <div className="userimg">
                            <img className="user__img" src={avatar} alt="Avatar " onClick={this.toggleModal} />
                        </div>
                    </div>
                </header>
                {
                    this.state.openModal &&
                    <EditUserModal toggle={this.toggleModal} modal={this.state.openModal} 
                    mobile={this.state.mobile} id={this.state.userid} refereshData={this.refereshData}/>
                }
            </React.Fragment>
        )
    }
}