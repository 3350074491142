import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/ledger.css';

export default class LedgerListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transfer_date: new Date()
        }
    }
    handleTransferDate = (date) => {
        this.setState({
            transfer_date: date,
            foreclosure_date: new Date()
        })
    }
    handleForeclosureDate = (date) => {
        this.setState({
            foreclosure_date: date
        })
    }
    render() {

        let analysts = this.props.analystArray;
        const columns = [{
            dataField: 'invoice no',
            text: 'Invoice No'
        }, {
            dataField: 'invoice date',
            text: 'Invoice Date'
        }, {
            dataField: 'payment type',
            text: 'Payment Type'
        }, {
            dataField: 'payment description',
            text: 'Payment Description'
        }, {
            dataField: 'payable to',
            text: 'Payable To'
        }, {
            dataField: 'current status',
            text: 'Current Status'
        }, {
            dataField: '$payment amount',
            text: '$Payment Amount'
        }, {
            dataField: 'check number/transaction id',
            text: 'Check Number/Transaction ID'
        }, {
            dataField: 'payment date',
            text: 'Payment Date'
        }, {
            dataField: '$accont balance',
            text: '$Accont balance'
        }];

        return (
            <div className="row">
                <section className="date">
                    <div className="dates">
                        <div className="from-date">
                            <h6>From Date</h6>
                            <DatePicker id="transfer_date" selected={this.state.transfer_date} onChange={this.handleTransferDate} value={this.state.transfer_date} />
                        </div>
                        <div className="to-date">
                            <h6>To Date</h6>
                            <DatePicker id="foreclosure_date" selected={this.state.foreclosure_date} onChange={this.handleForeclosureDate} value={this.state.foreclosure_date} />
                        </div>
                    </div>
                    <BootstrapTable keyField='id' data={analysts} columns={columns} />
                </section>
            </div>
        )
    }
}           