import config from '../config';
import { withRouter } from 'react-router';
import helpers from '../helpers';

var analystapi = {

    createAnalyst: async function (name, email, password, password_confirmation, role, mobile, status) {
        let url = config.baseUrl + '/api/v1/auth';
        var requestObject = {
            'user': {
                'name': name,
                'email': email,
                'password': password,
                'password_confirmation': password_confirmation,
                'role': role,
                'mobile': mobile,
                'status': status
            }
        }
        var init = {
            method: "POST",
            headers: await helpers.defaultHeaders(),
            body: JSON.stringify(requestObject)
        };
        var createAnalystResponse = null;
        await fetch(url, init)
            .then(response => {
                return response.json();
            }).then(
                responsejson => {
                    createAnalystResponse = responsejson;
                }
            )
            .catch(function (error) {

            })
        return createAnalystResponse;
    },
    updateAnalyst: async function (user, id) {
        let url = config.baseUrl + '/api/v1/users/' + id;
        var requestObject = {
            'user': user
        }
        var init = {
            method: "PUT",
            headers: await helpers.defaultHeaders(),
            body: JSON.stringify(requestObject)
        };
        var updateAnalystResponse = null;
        await fetch(url, init)
            .then(response => {
                return response.json();
            }).then(
                responsejson => {
                    updateAnalystResponse = responsejson;
                }
            )
            .catch(function (error) {

            })
        return updateAnalystResponse;
    },

    fetchUserRole: async function () {
        var userRole = [];
        let url = config.baseUrl + '/api/v1/users/roles';
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getting analyst' + response)
            }).then(analystList => {
                userRole = analystList;
            }).catch(function (error) {

            })
        return userRole;
    },

    getAnalysts: async function (pageNo, role = "All", perPage) {
        var analysts = [];
        let url = config.baseUrl + '/api/v1/users?role=' + (role !== "All" ? role : '');
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        if (pageNo) {
            url = url + "&page=" + pageNo;
        }
        if(perPage)
            url = `${url}&per_page=${perPage}`

        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getting analyst' + response)
            }).then(analystList => {
                analysts = analystList;
            }).catch(function (error) {

            })
        return analysts;
    },
    getAreaAnalysts: async function (pageNo, perPage, q) {
        var analysts = [];
        let url = config.baseUrl + '/api/v1/area_analysts';
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        let params = {}
        params["page"] = pageNo
        params["per_page"] = perPage
        params["q"] = q

        let query = Object.keys(params)
            .filter(k => typeof params[k] !== "undefined")
            .map(k => `${k}=` + encodeURIComponent(params[k]))
            .join("&")

        url = `${url}?${query}`

        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getting analyst' + response)
            }).then(analystList => {
                analysts = analystList;
            }).catch(function (error) {

            })
        return analysts;
    },
    getAreaAnalyst: async function (analyst_id) {
        var analyst;
        let url = config.baseUrl + '/api/v1/area_analysts/' + analyst_id;
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getting analyst' + response)
            }).then(a => {
                analyst = a;
            }).catch(function (error) {

            })
        return analyst;
    },
    updateAreaAnalyst: async function (area_analyst) {
        var res = '';
        let url = config.baseUrl + '/api/v1/area_analysts/' + area_analyst.id;
        var init = {
            method: "PUT",
            headers: await helpers.defaultHeaders(),
            body: JSON.stringify({ area_analyst: area_analyst })
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while area analyst update' + response)
            }).then(a => {
                res = a;
            }).catch(function (error) {

            })
        return res;
    },
    createAreaAnalyst: async function (area_analyst) {
        let url = config.baseUrl + '/api/v1/area_analysts';
        var init = {
            method: "POST",
            headers: await helpers.defaultHeaders(),
            body: JSON.stringify({ area_analyst: area_analyst })
        };
        return await fetch(url, init)
            .then(helpers.fetchHandler)
            .then(json => json.json)
    },
    deleteAreaAnalyst: async function (id) {
        var res = '';
        let url = config.baseUrl + '/api/v1/area_analysts/' + id;
        var init = {
            method: "DELETE",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return config.success;
                }
                throw new Error('Something went wrong, while deleting analyst' + response)
            }).then(a => {
                res = a;
            }).catch(function (error) {

            })
        return res;
    }
}
export default withRouter(analystapi);