import React from "react";
import {
  Button,
  FormGroup,
  Label,
  CustomInput,
} from "reactstrap"
import apicall from "../../../utils/apicall"
import accesscodesapi from '../../../utils/api/accesscodesapi'
import { FaEdit, FaLink, FaTrashAlt } from "react-icons/fa"
import "../../../styles/_access-codes.scss"
import AccessCodeModal from "./AccessCodeModal"
import helpers from '../../../utils/helpers'
import _ from "lodash"

export default class AccessCodeTab extends React.Component {
  constructor(props) {
    const accessCodeField = `${props.accessFor}_access_code`;
    super(props);
    this.state = {
      rows: [],
      openModal: false,
      isUpdate: true
    };
  }

  componentWillMount() {
    const accessCodeField = `${this.props.accessFor}_access_code`;
  	apicall.getProperty(this.props.propertyid)
      .then(response =>
        {
          this.setState({separateAccessCodes: response.separate_building_access_codes,
            isAccessControlled: response[accessCodeField]})
        }
        )
  }

  render() {
    const props = this.props;
    const accessCodeField = `${props.accessFor}_access_code`;
    const isAccessControlled = this.state.isAccessControlled;
    const separateAccessCodes = this.state.separateAccessCodes;
    const openModal = this.state.openModal;
    const isUpdate = this.state.isUpdate;
    const rows = this.state.rows;
    const currentAccessCodes = props.accessCodes.filter(x => x.access_for === props.accessFor)

    const nameValueDiv = (name, value) => {
      if (value === null || typeof value === "undefined" || value === "Null")
        return null;

      return (
        <div style={{ display: "flex" }}>
          <div style={{ width: "35%", fontWeight: "bold" }}>
            {_.startCase(name)}
          </div>
          <div>{value}</div>
        </div>
      );
    };
    const createBtn = _.isEmpty(currentAccessCodes) &&
      isAccessControlled && (
        <Button
          onClick={() => {
            this.setState({ isUpdate: false, openModal: true });
          }}
        >
          Create
        </Button>
      );

    const editBtn = !_.isEmpty(currentAccessCodes) && (
      <div className="edit-link">
        <FaTrashAlt
          style={{ float: "right", "margin-left": "10px" }}
          size="1.5em"
          onClick={() => {
            props.accessCodes.filter(x => x.access_for === props.accessFor )
              .forEach((x, ix) => {
                accesscodesapi.deleteAccessCodes(x.id, props.propertyid)
                  .then(() => {
                    helpers.notify("Access Code deleted.")
                    props.refreshList()
                  })
              })
          }}
        />
        <FaEdit
          style={{ float: "right" }}
          size="1.5em"
          onClick={() => {
            this.setState({
              rows: currentAccessCodes,
              openModal: true,
              isUpdate: true
            })
          }}
        />

      </div>
    );

    const viewIsAccessControlled = () => {
      if (
        isAccessControlled === null ||
        _.isEmpty(currentAccessCodes)
      ) {
        return (
          <div>
            <div className="addMarginBottom">
              Is {props.accessForTxt} controlled?
            </div>
            <div>
              <FormGroup style={{ display: "flex" }}>
                <CustomInput
                  id="leaseyes"
                  type="radio"
                  name="hideAdd"
                  value={true}
                  label="Yes"
                  onClick={() => {
                    this.setState({ isAccessControlled: true });
                    console.log("  >>{[accessCodeField]: true}: ", { [accessCodeField]: true }, accessCodeField );
                    apicall.updateProperty({ [accessCodeField]: true }, props.propertyid )
                      .then(
                        resp => {
                          if(resp){
                            helpers.notify("Property updated successsully.")
                          }
                        }
                      );
                  }}
                  checked={isAccessControlled}
                />
                <CustomInput
                  id="leaseno"
                  type="radio"
                  name="hideAdd"
                  value={false}
                  label="No"
                  onClick={() => {
                    this.setState({ isAccessControlled: false });
                    console.log("  >>{[accessCodeField]: false}: ", { [accessCodeField]: false }, accessCodeField );
                    apicall
                      .updateProperty({ [accessCodeField]: false }, props.propertyid).then(
                        resp => {
                          if(resp){
                            helpers.notify("Property updated successsully.")
                          }
                        }
                      );
                  }}
                  checked={
                    isAccessControlled === null ? false : !isAccessControlled
                  }
                />
              </FormGroup>
              {viewSeparateAccessCodes()}
            </div>
          </div>
        );
      }
    };

    const viewSeparateAccessCodes = () => {
      if (props.accessFor !== "building" || !isAccessControlled) return null;

      return (
        <div className="questions">
          <FormGroup>
            <Label>Do Owner and Vendor have separate Access Codes?</Label>
            <div className="radio-buttons">
              <CustomInput
                type="radio"
                name={`separate_access_code`}
                id={`separate_access_code_yes`}
                placeholder=""
                onChange={e => this.setState({ separateAccessCodes: true })}
                label="Yes"
                checked={separateAccessCodes}
              />
              <CustomInput
                type="radio"
                name={`separate_access_code`}
                id={`separate_access_code_no`}
                placeholder=""
                onChange={e => this.setState({ separateAccessCodes: false })}
                label="No"
                checked={!separateAccessCodes}
              />
            </div>
          </FormGroup>
        </div>
      );
    };

    const codes = props.accessCodes
      .filter(x => x.access_for === props.accessFor)
      .map((x, ix) => {
        return (
          <div className="code" key={ix}>
            {props.accessFor === "building" &&
              nameValueDiv("access_to", _.startCase(x.access_to))}
            {nameValueDiv("access_control", _.startCase(x.access_control))}
            {nameValueDiv("access_contact_email", x.access_contact_email)}
            {nameValueDiv("access_contact_name", x.access_contact_name)}
            {nameValueDiv("access_contact_phone", x.access_contact_phone)}
            {nameValueDiv("access_control_cost", x.access_control_cost)}
            {nameValueDiv("access_description", x.access_description)}
            {nameValueDiv("code", x.code)}
            {x.documents[0] &&
              nameValueDiv(
                "access_form",
                <a
                  href={x.documents[0].file_url}
                  target="_blank"
                  style={{ float: "right", color: "black" }}
                >
                  <FaLink size="1em" />
                </a>
              )}
          </div>
        );
      });
    return (
      <div className="access-codes-container">
        {viewIsAccessControlled()}
        {createBtn}
        {editBtn}
        {codes}
        {openModal && (
          <AccessCodeModal
            toggle={() => this.setState({ openModal: false })}
            modal={true}
            isUpdate={isUpdate}
            accessFor={props.accessFor}
            propertyid={props.propertyid}
            refreshList={props.refreshList}
            separateAccessCodes={separateAccessCodes}
            accessCodesObject={rows}
          />
        )}
      </div>
    );
  }
}
