import config from "./config";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import _ from "lodash";

var helpers = {
  formatOptions: function(array, groupName) {
    let options = [];
    options = array.map(object => {
      if (groupName)
        return { value: object.id, label: object.name, group: groupName };
      else return { value: object.id, label: object.contact_email };
    });
    return options;
  },
  isBlank: function(value) {
    return (_.isEmpty(value) && !_.isNumber(value)) || _.isNaN(value);
  },
  isNull: function(value) {
    return value === null  
  },
  isPresent: function(value) {
    return !this.isBlank(value);
  },
  joinArrayBy: function(array, param) {
    return array.map(x => x.name).join(param);
  },
  formatAddress: function(object, fields, extraFields) {
    var zip = null;

    const Fields = fields
      .filter(x => object[x] && object[x].trim())
      .map(x => object[x] && object[x].trim())
      .join(", ");

    if (object[extraFields] && object[extraFields].trim())
      zip = object[extraFields].trim();

    return Fields + " " + zip;
  },
  getErrorMsg: function(error) {
    console.log(error);
    let errorMsg = "";
    let errorkeys = Object.keys(error);
    for (let i = 0; i < errorkeys.length; i++) {
      errorMsg = errorMsg + errorkeys[i] + " " + error[errorkeys[i]] + " ";
    }
    return errorMsg;
  },
  defaultHeaders: async function() {
    let defaultHeader = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    let tokenObj = await this.getSavedToken();
    return { ...defaultHeader, ...tokenObj };
  },
  getSavedToken: async function() {
    let tokenObj = {};
    await config.localforage.getItem(config.accessToken).then(token => {
      tokenObj["access-token"] = token;
    });
    await config.localforage.getItem("uid").then(uuid => {
      tokenObj["uid"] = uuid;
    });
    await config.localforage.getItem("expiry").then(expiryTime => {
      tokenObj["expiry"] = expiryTime;
    });
    await config.localforage.getItem("client").then(clientId => {
      tokenObj["client"] = clientId;
    });
    return tokenObj;
  },
  notify: function(errorMsg) {
    toast(errorMsg);
  },
  getBoolean: function(string) {
    if (string === "true") {
      return true;
    } else if (string === "false") {
      return false;
    } else {
      return string;
    }
  },
  capitalizeFirstLetter: function(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  stripLastChar: function(str) {
    return str.substring(0, str.length - 1);
  },
  getFormattedData: function(data) {
    if (typeof data !== "undefined" && data !== null) {
      if (typeof data === "string") {
        return this.capitalizeFirstLetter(data);
      } else if (typeof data === "number") {
        return data;
      }
      if (typeof data === "boolean" && data === true) {
        return "Yes";
      } else if (typeof data === "boolean" && data === false) {
        return "No";
      }
    } else {
      return "Nil";
    }
  },
  restructureDate: function(date) {
    if (date.toString().length > 10) return new Date(date);
    else return new Date(date + " 00:00:00");
  },
  formatDate: function(date) {
    if (date) return dayjs(date).format(config.US_DATE_FORMAT);
  },
  formatApiDate: function(date) {
    if (date) return dayjs(date).format(config.API_DATE_FORMAT);
  },
  blobHandler: function(response, key) {
    console.log("key", key);
    if (response.ok) {
      return response.blob().then(blob => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", key + `.csv`);

        // 3. Append to html page
        document.body.appendChild(link);

        // 4. Force download
        link.click();

        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      });
    } else {
      return response
        .json()
        .catch(err => {
          // the status was not ok and there is no json body
          console.log("  >>err: ", err);
          helpers.notify(response.statusText);
          throw response.statusText;
        })
        .then(json => {
          // the status was not ok but there is a json body
          console.log("  >>json error: ", json);
          if (json.hasOwnProperty("errors")) {
            let errorMsg = helpers.getErrorMsg(json.errors);
            if (errorMsg) {
              helpers.notify(errorMsg);
              throw errorMsg;
            }
            throw "Error occured";
          } else if (json.hasOwnProperty("error")) {
            helpers.notify(json.error);
            throw json.error;
          }
        });
    }
  },

  fetchHandler: function(response) {
    if (response.ok) {
      return response
        .json()
        .then(json => {
          // the status was ok and there is a json body
          if(json.hasOwnProperty("message")) {
            helpers.notify(json.message);
          }
          return Promise.resolve({ json: json, response: response });
        })
        .catch(err => {
          // the status was ok but there is no json body
          return Promise.resolve({ json: {}, response: response });
        });
    } else {
      return response
        .json()
        .catch(err => {
          // the status was not ok and there is no json body
          console.log("  >>err: ", err);
          helpers.notify(response.statusText);
          throw response.statusText;
        })
        .then(json => {
          // the status was not ok but there is a json body
          console.log("  >>json error: ", json);
          if (json.hasOwnProperty("errors")) {
            let errorMsg = helpers.getErrorMsg(json.errors);
            if (errorMsg) {
              helpers.notify(errorMsg);
              throw errorMsg;
            }
            throw "Error occured";
          } else if (json.hasOwnProperty("error")) {
            helpers.notify(json.error);
            throw json.error;
          }
        });
    }
  },
};

export default helpers;
