import config from "../config"
import { withRouter } from "react-router"
import helpers from "../helpers"
var notesapi = {
	getNotes: async function(propertyId, pageNo) {
		let url = `${config.baseUrl}/api/v1/notes?property_id=${propertyId}&page=${pageNo}`
		var init = {
			method: "GET",
			headers: await helpers.defaultHeaders(),
		}

		return await fetch(url, init)
			.then(helpers.fetchHandler)
			.then(json => json.json)
	},
	createNote: async function(propertyId, description) {

		let url = `${config.baseUrl}/api/v1/notes?property_id=${propertyId}`
		var init = {
			method: "POST",
			headers: await helpers.defaultHeaders(),
			body: JSON.stringify({note: {description}})
		}

		return await fetch(url, init)
			.then(helpers.fetchHandler)
			.then(json => json.json)
	},
}

export default withRouter(notesapi)
