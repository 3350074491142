import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { Button } from 'reactstrap';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import config from '../../utils/config';
import Select from 'react-select';
import '../../styles/pagination.css'
export default class PaginationComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            perPage: props.page,
            pageNo: props.pageNo
        }
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            this.setState({
                ...this.state,
                ...newprops
            });
        }
    }


    handleTableChange = (type, { page, sizePerPage }) => {

    }

    handleSelectChange = (currentPageEle) => {
        let currentPageNO = currentPageEle.value;
        const event = new Event("change", { bubbles: true })
        this.setState({pageNo: parseInt(currentPageNO)})
        this.props.handleSubmit(event, parseInt(currentPageNO), this.state.perPage);
    }

    rowStyle = (row, rowIndex) => {
        if (rowIndex % 2 !== 0) {
            return (
                { backgroundColor: 'lightgray' }
            )
        }
    };

    render() {
        const options = {
            custom: true,
            sizePerPage: this.state.perPage || 10
        };
        const customStyles = {
            container: (base, state) => ({
              ...base,
              border: state.isFocused ? null : null,
              transition:
                "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
              "&:hover": {
                boxShadow: "0 2px 4px 0 rgba(41, 56, 78, 0.1)"
              },
              width:"10%",
              height:"15px"
            }),
            control: (base, state) => ({
              ...base,
              width:"100%",
              height:"15px"
            }),
            valueContainer: (base, state) => ({
              ...base,
              width:"100%",
            })
          };
        if (this.props.rows) {
            var pageCountDiv = null;
            let totalCount = 0;

            if (this.props.totalCount && this.props.totalCount > 0) {
                totalCount = Math.ceil((this.props.totalCount) / options.sizePerPage)
                pageCountDiv = " of " + totalCount;

            }else if (this.props.rows.length > 0){
                totalCount = 1
                pageCountDiv = " of " + totalCount;
            }
            let pageNo = this.state.pageNo || "1";
            let perPage = this.state.perPage || "10";

            var pageOptions = [];
            for (let pageSelectIterator = 1; pageSelectIterator <= parseInt(totalCount); pageSelectIterator++) {
                pageOptions.push({
                    label: pageSelectIterator,
                    value: pageSelectIterator
                })
            }

            const perPageOptions = [10, 25, 50, 100].map(x => { return {label:x, value: x} })
            const perPageClass = typeof this.props.hidePerPage === 'undefined' ? "per-page" : "hidden"
            return (
                <>
                    <PaginationProvider pagination={paginationFactory(options)}>
                        {
                            ({
                                paginationProps,
                                paginationTableProps
                            }) => (
                                    <div>

                                        <BootstrapTable remote
                                            keyField={"id"}
                                            data={this.props.rows}
                                            columns={this.props.columns}
                                            onTableChange={this.handleTableChange}
                                            {...paginationTableProps}
                                            rowStyle={this.rowStyle}
                                        />
                                        <div className='page-wrapper'>
                                            <Button disabled={(parseInt(pageNo) > 1) ? false : true} color="primary" style={{ float: "left" }} onClick={(event) => {
                                                 AwesomeDebouncePromise(() => {
                                                    let currentPageNO = parseInt(pageNo);
                                                    if (currentPageNO > 1) {
                                                        paginationProps.onPageChange(parseInt(pageNo) - 1);
                                                        this.setState({pageNo: currentPageNO - 1})
                                                        this.props.handleSubmit(event, currentPageNO - 1, this.state.perPage);
                                                    }
                                                }, config.debounceTimer)();
                                            }}>Previous</Button>
                                            <div style={{ "display": "flex", "width": "75%" }}>
                                                <div className="page-no">
                                                    Page No : <Select className="paginationPageSelect" styles={customStyles}
                                                        value={{label:pageNo,value:pageNo}}
                                                        onChange={this.handleSelectChange}
                                                        isSearchable={false} isClearable={false}
                                                        options={pageOptions}
                                                    />
                                                    {pageCountDiv}
                                                </div>
                                                <div className={perPageClass}>
                                                    Per page: <Select className="paginationPageSelect" styles={customStyles}
                                                        value={{label:perPage,value:perPage}}
                                                        onChange={(opt) => {
                                                                const event = new Event("change", { bubbles: true })
                                                                this.setState({perPage: opt.value, pageNo: 1})
                                                                this.props.handleSubmit(event, 1, opt.value)
                                                            }
                                                        }
                                                        isSearchable={false} isClearable={false}
                                                        options={perPageOptions}
                                                    />
                                                </div>
                                            </div>
                                            <Button disabled={(totalCount <= pageNo) ? true : false}
                                                color="primary" style={{ float: "right" }} onClick={(event) => {
                                                    AwesomeDebouncePromise(() => {
                                                        let currentPageNO = parseInt(pageNo);
                                                        if (this.props.rows.length === options.sizePerPage) {
                                                            paginationProps.onPageChange(parseInt(pageNo) + 1);
                                                            this.setState({pageNo: currentPageNO + 1})
                                                            this.props.handleSubmit(event, currentPageNO + 1, this.state.perPage);
                                                        }
                                                    }, config.debounceTimer)();
                                                }}>Next</Button>
                                        </div>

                                    </div>
                                )}
                    </PaginationProvider>
                </>)
        }
        return null;
    }
}