import React, { Component } from "react";
import { FormGroup, Label, Input, Table, Col } from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { FaTrashAlt } from "react-icons/fa";
import { Animated } from "react-animated-css";
export default class DocumentUploadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }
  render() {
    let documentComponent = null;
    if (
      typeof this.props.showDocuments === "undefined" ||
      !this.props.showDocuments ||
      this.props.showDocuments === "false"
    ) {
      return null;
    }
    documentComponent = (
      <>
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
          {this.props.showInvoiceDate ? (
            this.props.uploadDoc && (
              <>
                <FormGroup row>
                  <Label for="" sm={4}>
                    Document Name *
                  </Label>
                  <Col sm={8}>
                    <TextValidator
                      type="text"
                      name="documents_attributes[][name]"
                      id={"documents_attributes[][name]"}
                      placeholder="Document Name"
                      onChange={this.props.onChange}
                      validators={["required"]}
                      errorMessages={["Document Name is required"]}
                      value={
                        this.state.row["documents_attributes[][name]"]
                          ? this.state.row["documents_attributes[][name]"]
                          : ""
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="" sm={4}>
                    Document Memo Notes
                  </Label>
                  <Col sm={8}>
                    <TextValidator
                      type="text"
                      id={"documents_attributes[][memo_notes]"}
                      name="memo_notes"
                      placeholder="Document Memo Notes"
                      onChange={this.props.onChange}
                      value={
                        this.state.row["documents_attributes[][memo_notes]"]
                          ? this.state.row["documents_attributes[][memo_notes]"]
                          : ""
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="" sm={4}>
                    Document File Upload
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="file"
                      name="file"
                      id={"documents_attributes[][file]"}
                      onChange={this.props.onChange}
                      required
                    />
                  </Col>
                </FormGroup>
              </>
            )
          ) : (
            <>
              {this.props.hideDocumentName ? null : (
                <FormGroup>
                  <Label for="">Document Name *</Label>
                  <TextValidator
                    type="text"
                    name="documents_attributes[][name]"
                    id={"documents_attributes[][name]"}
                    placeholder="Document Name"
                    onChange={this.props.onChange}
                    validators={["required"]}
                    errorMessages={["Document Name is required"]}
                    value={
                      this.state.row["documents_attributes[][name]"]
                        ? this.state.row["documents_attributes[][name]"]
                        : ""
                    }
                  />
                </FormGroup>
              )}
              {!this.props.notApplicable && (
                <>
                  <FormGroup>
                    <Label for="">Document Memo Notes *</Label>
                    <TextValidator
                      type="text"
                      id={"documents_attributes[][memo_notes]"}
                      name="memo_notes"
                      placeholder="Document Memo Notes"
                      onChange={this.props.onChange}
                      validators={["required"]}
                      errorMessages={["Document Memo Notes is required"]}
                      value={
                        this.state.row["documents_attributes[][memo_notes]"]
                          ? this.state.row["documents_attributes[][memo_notes]"]
                          : ""
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="">Document File Upload</Label>
                    <Input
                      type="file"
                      name="file"
                      id={"documents_attributes[][file]"}
                      onChange={this.props.onChange}
                      required
                    />
                  </FormGroup>
                </>
              )}
            </>
          )}
        </Animated>
      </>
    );
    let tableRowEntries = null;
    if (this.state.row.documents) {
      tableRowEntries = this.state.row.documents.map((document) => {
        var fileUrlArray = document.file_url.split("/");
        let fileName = decodeURIComponent(
          fileUrlArray[fileUrlArray.length - 1]
        );
        return (
          <tr key={"docu" + document.id}>
            <td scope="row">{document.id}</td>
            <td>{document.name}</td>
            <td>
              <a href={document.file_url} target="_blank">
                {fileName}
              </a>
            </td>
            <td>{document.memo_notes}</td>
            <td>
              <FaTrashAlt
                onClick={() => this.props.deleteDocument(document.id)}
              />
            </td>
          </tr>
        );
      });
    }
    return (
      <>
        {documentComponent}
        <div className="documentTable">
          <Table striped>
            <thead>
              <tbody>{tableRowEntries}</tbody>
            </thead>
          </Table>
        </div>
      </>
    );
  }
}
