import React, { Component, useState, useEffect }  from 'react'
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Input, Col, Row } from 'reactstrap';
import apicall from '../../../utils/apicall';
import helpers from '../../../utils/helpers'
import '../../../styles/propertyupload.css';
import config from '../../../utils/config';

export default (props) => {
    const [file, set_file] = useState(null)

    const handleChange = (event) => {
        set_file(event.target.files[0])
    }

    const handleSubmit = (event) => {
        apicall.uploadPropertyFile(file, "association-verification").then(
            response => {
                    helpers.notify(" Uploaded Successfully")
            }
        ).catch(e => {

        })
        event.preventDefault();
    }
	return (
		<section className="content">
		    <header className="content-title property-manage-component">
				<h1>Bulk update AssociationVerification status</h1>
                <a href={`${config.baseUrl}/upload_templates/AssociationVerificationStatusUpload.xlsx`}>
                    Download Template File
                </a>
			</header>
            <br/>
            <Row>
                <Col xs="4">
        			<Form onSubmit={handleSubmit}>
        			    <FormGroup className="import-assoc-file">
        			        <Input type="file" name="file" id="name" onChange={handleChange} />
        			        <button>Submit</button>
        			    </FormGroup>
        			</Form>
                </Col>
            </Row>
		</section>
	)
}