import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import AreaAnalystModal from "./AreaAnalystModal";
import ImportAreaAnalystModal from "../helper_components/ImportModal";
import apicall from "../../utils/apicall";
import { toast } from "react-toastify";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import PaginationComponent from "../helper_components/PaginationComponent";
import config from "../../utils/config";
import helpers from "../../utils/helpers";
export default class AreaAnalystListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openFormModal: false,
      refreshList: props.refreshList,
      modalAnalystsWindow: new Array(20).fill(false),
      rowIndex: 0,
      pageNo: 1,
      row: {}
    };
  }

  toggleEditFormModal = () => {
    let existingStatus = this.state.openFormModal
    this.setState({
      openFormModal: !this.state.openFormModal
    });
    if(!existingStatus){
      this.props.refreshList();
  }
  };

  toggleAddFormModal = () => {
    this.setState({
      row: {},
      openFormModal: !this.state.openFormModal
    });
    this.props.refreshList();
  };

  toggleImportModal = () => {
    this.setState({
      openImportModal: !this.state.openImportModal
    });
    this.props.refreshList();
  };

  buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal"
        }}
      >
        <FaEdit
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => {
            var boolArray = this.state.modalAnalystsWindow;
            boolArray[rowIndex] = !boolArray[rowIndex];
            this.setState({
              modalAnalystsWindow: boolArray,
              rowIndex: rowIndex,
              row: row,
              openFormModal: true
            });
          }}
        />
        <FaTrashAlt
          style={{ marginRight: "4%" }}
          size="1.5em"
          onClick={() => this.deleteRecord(row)}
        />
      </div>
    );
  };

  deleteRecord = row => {
    apicall.deleteAreaAnalyst(row.id).then(response => {
      if (response == config.success) {
        helpers.notify("Deleted successfully");
        this.state.refreshList();
      } else {
        helpers.notify("Deletion Failed");
      }
    });
  };

  render() {
    let analysts = this.props.areaAnalystArray;
    const columns = [
      {
        dataField: "state",
        text: "State"
      },
      {
        dataField: "zip",
        text: "Zip"
      },
      {
        dataField: "area_senior_analyst_email",
        text: "Senior Analyst"
      },
      {
        dataField: "area_payment_analyst_email",
        text: "Payment Analyst"
      },
      {
        dataField: "area_association_analyst_email",
        text: "Association Analyst"
      },
      {
        dataField: "area_controller_email",
        text: "Area Controller"
      },
      {
        dataField: "area_leasing_sales_analyst_email",
        text: "Sales Analyst"
      },
      {
        dataField: "area_coordinator_email",
        text: "Area Coordinator"
      },
      {
        dataField: "area_onboarding_analyst_email",
        text: "Onboarding Analyst"
      },
      {
        dataField: "area_violation_analyst_email",
        text: "Violation Analyst"
      },
      {
        dataField: "action",
        text: "Actions",
        sort: false,
        formatter: this.buttonFormatter,
        headerAttrs: { width: 200 },
        attrs: { width: 200, class: "table-actions" }
      }
    ];

    return (
      <React.Fragment>
        <div className="btn-group actions">

          <a className="btn template" style={{"color": "blue"}} href={`${config.baseUrl}/upload_templates/AreaAnalystUpload.xlsx?v=1`}>
              Download Template File
          </a>
          <Button onClick={() => this.toggleAddFormModal()}>Add</Button>
          <Button onClick={() => this.toggleImportModal()}>Import</Button>
          <ImportAreaAnalystModal
            uploadType="analyst-file"
            toggleParentModal={this.toggleImportModal}
            className=""
            title="Area Analysts"
            open={this.state.openImportModal}
          />
        </div>
        <PaginationComponent
          columns={columns}
          rows={analysts}
          handleSubmit={this.props.refreshList}
          pageNo={this.props.pageNo}
          // perPage={this.props.perPage}
          totalCount={this.props.totalCount}
        />
        {this.state.openFormModal &&
                <AreaAnalystModal
                  toggle={this.toggleEditFormModal}
                  open={this.state.openFormModal}
                  row={this.state.row}
                  area_analyst={{}}
                />}
      </React.Fragment>
    );
  }
}
