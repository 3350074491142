import React, { Component } from "react";
import '../../styles/_general.scss';
import '../../styles/amenities.css';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Table, Label, Form, Input, CustomInput, Col, Row } from 'reactstrap';
import amenitiesApi from '../../utils/api/amenitiesapi'
import config from '../../utils/config'
import helpers from '../../utils/helpers';
import { toast } from "react-toastify";
import Loader from '../helper_components/LoaderComponent'
import apicall from "../../utils/apicall"
export default class Amenities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amenities: [],
            utilities: [],
            isLoading: true,
            isDisabled: false,
        }
    }

    componentWillMount() {
        this.getAmenities(this.props.propertyid);
        if (config['generalInfoRead'].includes(this.props.userRole)) {
            this.setState({
                isDisabled: true
            })
        }
    }

    handleCheckbox = (event) => {
        this.setState({ "utilities_aminities_completed": !this.state.utilities_aminities_completed, isLoading: true })
        let property = {
            "utilities_aminities_completed": event.target.checked
        }
        apicall.updateProperty(property, this.props.propertyid).then(
            response => {
                if (response) {
                    this.setState({
                        "utilities_aminities_completed": response.utilities_aminities_completed,
                        isLoading: false
                    })
                }
            }
        )
        event.preventDefault();
    }

    handleCheckbox = (event) => {
        this.setState({ "utilities_aminities_completed": !this.state.utilities_aminities_completed })
        let property = {
            "utilities_aminities_completed": event.target.checked
        }
        apicall.updateProperty(property, this.props.propertyid).then(
            response => {
                if (response) {
                    this.setState({
                        "utilities_aminities_completed": response.utilities_aminities_completed
                    })
                }
            }
        )
        event.preventDefault();
    }

    render() {

        if (this.state.amenities.length == 0) {
            return null
        }

        const handleUtilityChange = (id, responsibililty) => {
            const utilities =
                this.state.utilities.map(utility => id == utility.id ? { ...utility, responsibililty } : utility)
            this.setState({ ...this.state, utilities })
        }

        const handleAmenityChange = (id, val) => {
            const amenities =
                this.state.amenities.map(amenity => id == amenity.id ? { ...amenity, available: val } : amenity)
            this.setState({ ...this.state, amenities })
        }

        const amenities = this.state.amenities.map(
            amenity => <tr>
                <td>{amenity.amenity_name}</td>
                <td className="tac">
                    <CustomInput id={"amenity" + amenity.id} onClick={() => handleAmenityChange(amenity.id, true)}
                        type="radio" checked={amenity.available} name={amenity.amenity_name} disabled={this.state.isDisabled} />
                </td>
                <td className="tac">
                    <CustomInput id={"amenityno" + amenity.id} onClick={() => handleAmenityChange(amenity.id, false)}
                        type="radio" checked={!amenity.available} name={amenity.amenity_name} disabled={this.state.isDisabled} />
                </td>
            </tr>)
        const utilities = this.state.utilities.map(
            utility => <tr>
                <td>{utility.utility_name}</td>
                <td className="tac">
                    <CustomInput id={"hoa" + utility.id} onClick={() => handleUtilityChange(utility.id, "hoa")}
                        type="radio" checked={utility.responsibililty == "hoa"} name={utility.utility_type} value={utility.id}
                        disabled={this.state.isDisabled} />
                </td>
                <td className="tac">
                    <CustomInput id={"owner_tenant" + utility.id} onClick={() => handleUtilityChange(utility.id, "owner_tenant")}
                        type="radio" checked={utility.responsibililty == "owner_tenant"} name={utility.utility_type} value={utility.id}
                        disabled={this.state.isDisabled} />
                </td>
            </tr>)
        return (
            <>
                {this.state.isLoading ? <Loader /> : (
                    <>
                        <Row className="details">
                            <Col xs="6">
                                <Form>
                                    <Table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th><h3>Utilities</h3></th>
                                                <th className="tac">HOA</th>
                                                <th className="tac">Owner/<br />Tenant</th>
                                            </tr>
                                        </thead>
                                        {utilities}
                                    </Table>
                                </Form>
                                {
                                    !config['generalInfoRead'].includes(this.props.userRole) &&
                                    <Button style={{float:'left'}} onClick={() => this.updateAmenities(this.props.propertyid)}>Update</Button>
                                }
                            </Col>

                            <Col xs="6">
                                <Form>
                                    <Table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th><h3>Amenities</h3></th>
                                                <th className="tac">Yes</th>
                                                <th className="tac">No</th>
                                            </tr>
                                        </thead>
                                        {amenities}
                                    </Table>
                                </Form>
                            </Col>
                        </Row>
                    </>
                )}
            </>
        )
    }

    getAmenities(propertyId) {
        amenitiesApi.getAmenities(propertyId).then(
            response => {
                if (response !== null && response.hasOwnProperty('errors')) {
                    let errorMsg = helpers.getErrorMsg(response.errors);
                    if (errorMsg)
                        helpers.notify(errorMsg);
                } else {
                    this.setState({
                        amenities: response.amenities,
                        utilities: response.utilities,
                        isLoading: false
                    })
                }
            }
        )
    }

    updateAmenities(propertyId) {
        amenitiesApi.updateAmenities(propertyId, this.state.amenities, this.state.utilities).then(
            response => {
                if (response !== null && response.hasOwnProperty('errors')) {
                    let errorMsg = helpers.getErrorMsg(response.errors);
                    if (errorMsg)
                        helpers.notify(errorMsg);
                } else {
                    helpers.notify("Update Successful");
                }
            }
        )
    }

}