import config from '../config';
import { withRouter } from 'react-router';
import helpers from '../helpers';
var accesscodesapi = {
    getAcessCodes: async function (propertId, pageNo, perPage) {
        var questions = {};
        let url = config.baseUrl + '/api/v1/access_codes?property_id=' + propertId;
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        if (pageNo) {
            url = url + "&page=" + pageNo;
        }
        if(perPage)
            url = `${url}&per_page=${perPage}`

        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getting access codes' + response)
            }).then(questionsJson => {
                questions = questionsJson;
            }).catch(function (error) {

            })
        return questions;
    },
    createAccessCodes: async function (accessCodesObject, propertyid, file) {
        let url = config.baseUrl + '/api/v1/access_codes'
        var form = new FormData();
        for (var key in accessCodesObject) {
            form.append("access_code[" + key + "]", accessCodesObject[key]);
        }
        if (accessCodesObject.access_form_exist === true) {
            form.append('access_code[documents_attributes[][file]]', file);
        }
        form.append('property_id', propertyid);
        let init = {
            method: "POST",
            headers: await helpers.getSavedToken(),
            body: form
        };
        var createAccessCodeResponse = null;
        await fetch(url, init)
            .then(response => {
                return response.json();
            }).then(
                responsejson => {
                    createAccessCodeResponse = responsejson;
                }
            )
            .catch(function (error) {

            })
        return createAccessCodeResponse;
    },
    updateAccessCodes: async function (accessCodesObject, propertyid, accessCodeId, file) {
        let url = config.baseUrl + '/api/v1/access_codes/' + accessCodeId;
        var form = new FormData();
        for (var key in accessCodesObject) {
            form.append("access_code[" + key + "]", accessCodesObject[key]);
        }
        if (accessCodesObject.access_form_exist === true) {
            form.append('access_code[documents_attributes[][file]]', file);
        }
        form.append('property_id', propertyid);
        let init = {
            method: "PUT",
            headers: await helpers.getSavedToken(),
            body: form
        };
        var updateAccessCodesResponse = null;
        await fetch(url, init)
            .then(response => {
                return response.json();
            }).then(
                responsejson => {
                    updateAccessCodesResponse = responsejson;
                }
            )
            .catch(function (error) {

            })
        return updateAccessCodesResponse;
    },
    deleteAccessCodes: async function (accessCodeId, propertId) {
        var res = '';
        let url = config.baseUrl + '/api/v1/access_codes/' + accessCodeId + '?property_id=' + propertId;
        var init = {
            method: "DELETE",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while deleting access codes' + response)
            }).then(a => {
                res = a;
            }).catch(function (error) {

            })
        return res;
    },
    getAccessFor: async function (propertyId) {
        var questions = {};
        let url = config.baseUrl + '/api/v1/access_codes/access_for?property_id=' + propertyId;
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getting access for' + response)
            }).then(questionsJson => {
                questions = questionsJson;
            }).catch(function (error) {

            })
        return questions;
    },
    getAccessTo: async function (propertyId) {
        var questions = {};
        let url = config.baseUrl + '/api/v1/access_codes/access_to?property_id=' + propertyId;
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getting access to' + response)
            }).then(questionsJson => {
                questions = questionsJson;
            }).catch(function (error) {

            })
        return questions;
    },
    getAccessControls: async function (propertyId) {
        var questions = {};
        let url = config.baseUrl + '/api/v1/access_codes/access_control?property_id=' + propertyId;
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getting access control' + response)
            }).then(questionsJson => {
                questions = questionsJson;
            }).catch(function (error) {

            })
        return questions;
    },

}
export default withRouter(accesscodesapi);