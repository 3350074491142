import FormComp from "../crud/FormComp";
import React from 'react';
import DatePicker from 'react-datepicker';
import { FormGroup, Label, Input, Table } from 'reactstrap';
import TextValidator from '../helper_components/TextValidator';
import "react-datepicker/dist/react-datepicker.css";
import TabsComponent from "../helper_components/TabsComponent";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
export default class InspectionModal extends FormComp {

    componentDidMount() {
        apicall.inspectionFetch(this.state.row, this.state.propertyID, this.state.actionID).then(
            response => {
                this.setState({
                    row: response
                })
            }
        )
    }

    handleSubmit = () => {
        apicall.inspectionUpdate(this.state.row, this.state.propertyID, this.state.actionID).
            then(
                response => {
                    if (response) {
                        this.notify("Inspection Updated Successfully.");
                        this.setState({
                            row: response
                        })
                    }
                }
            );
    }

    updateInspectionForm = () => {
        return (
            <>
                <FormGroup>
                    <Label for="">Inspection Type *</Label>
                    <TextValidator type="text" name="inspection_type" id="inspection_type"
                        placeholder="Inspection Type" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Inspection Type is required']}
                        value={this.state.row["inspection_type"]} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Work Order/Ticket No *</Label>
                    <TextValidator type="text" name="work_order_ticket_no" id="work_order_ticket_no"
                        placeholder="Work Order/Ticket No" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Work Order/Ticket No is required']}
                        value={this.state.row["work_order_ticket_no"]} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Completed By *</Label>
                    <TextValidator type="text" name="completed_by" id="completed_by"
                        placeholder="Completed By" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Completed By is required']}
                        value={this.state.row["completed_by"]} />
                </FormGroup>
                 <FormGroup>
                    <Label style={{ marginRight: "2%" }} for="completed_on">Completed on</Label>
                    <DatePicker id="completed_on" 
                    selected={this.state.row["completed_on"] ? helpers.restructureDate(this.state.row["completed_on"]) : null}                       
                        onSelect={(date) => {
                            this.handleDateChange("completed_on", date) 
                        }}/>
                </FormGroup>
            </>
        )
    }

    formElements = () => {
        var listofTabs = ["Inspection", "Documents"];
        let tabsComponentsList = new Array(listofTabs.length).fill(null);
        tabsComponentsList[0] = this.updateInspectionForm();
        tabsComponentsList[1] = this.documentComp();
        return <>
            <TabsComponent theme="modalTabs" components={tabsComponentsList}
                listofTabs={listofTabs} generateTabURL={false} />
        </>;
    }
}
