import React from "react";
import { FormGroup, Label, Input, Col, CustomInput } from "reactstrap";
import apicall from "../../utils/apicall";
import TextValidator from "../helper_components/TextValidator";
import FormComp from "../crud/FormComp";
import HoaAsyncSelect from "../helper_components/HoaAsyncSelect";
import { ValidatorForm } from "react-form-validator-core";
import "../../styles/_management.scss";
import config from "../../utils/config";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import _ from "lodash";
import { FaTimes, FaPlus, FaTrashAlt } from "react-icons/fa";
import SelectOptions from "../helper_components/SelectOptions";
import helpers from "../../utils/helpers";
import AddressComponent from "../management_companies/AddressComponent";
import AssessmentComponent from "./Testcomponent";
import SelectManagementCompany from "../helper_components/AsyncSelectManagementCompany";
class FormComponent extends FormComp {
  constructor(props) {
    console.log('FormComponent',props)
    super(props);
    this.state = {
      open: false,
      row: props.row,
      title: props.title,
      managementCompanies: [],
      className: "hoaForm"
    };
    this.state = {
      ...this.state,
      ...this.props
    };
  }

  getHoaById = hoaId => {
    apicall.getHoa(hoaId).then(resp => {
      this.setState({
        row: resp
      });
    });
  };

  handleAddEvent = evt => {
    var id = (+new Date() + Math.floor(Math.random() * 9999)).toString(36);
    var fyMap = {
      id: id,
      fiscal_year_start: "",
      days_past_due: "",
      assesment_dues: "",
      assessment_frequency: "",
      special_assessment_dues: "",
      special_assessment_frequency: "",
      late_fee_description: "",
      status: "temp"
    };
    this.state.fyArray.push(fyMap);
    this.setState(this.state.fyArray);
  };

  handleFyChange = evt => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value
    };
    var fyArray = this.state.fyArray.slice();
    var newfyArray = fyArray.map((fy, index) => {
      for (var key in fy) {
        if (key == item.name && fy.id == item.id) {
          fy[key] = item.value;
        }
      }
      return fy;
    });
    this.setState({ fyArray: newfyArray });
  };

  handleSubmit = event => {
    let row = this.state.row;
    let fyArray = this.state.fyArray;
    //remove temporary Id'
    for (let index in fyArray) {
      if (_.has(fyArray[index], "status")) {
        delete fyArray[index]["id"];
        delete fyArray[index]["status"];
      }
    }
    row.assessments_attributes = fyArray;
    row.management_company_id = this.getSelectionValue(
      row.management_company_id
    );
    row.first_due_date = row.fiscal_year_start;
    if (row.id) {
      apicall.updateHoa(row).then(response => {
        if (response) {
          this.notify("Updated successfully.");
          this.toggleModal();
          let diff = dayjs(row.first_due_date).diff(dayjs(), "days");

          if (row.first_due_date && diff <= 60) this.props.toggleConfirmBox();
        }
      });
    } else {
      apicall.createHoa(row).then(response => {
        if (response) {
          this.props.setRow(response);
          this.notify("Added successfully.");
          // this.toggleModal();
          let diff = dayjs(row.first_due_date).diff(dayjs(), "days");

          if (row.first_due_date && diff <= 60) this.props.toggleConfirmBox();
        }
      });
    }
    event.preventDefault();
  };

  componentDidMount = () => {
    {
      this.props.isDisplayHOA && this.setCheckedPoc(this.props.row);
    }
  };

  setCheckedPoc = (row, newprops) => {
    if (row.id && row.management_company_id && row.management_company) {
      let option = {};
      option["value"] = row.management_company["id"];
      option["label"] = row.management_company["name"];

      if (option && newprops) newprops.row.management_company_id = option;
      else row.management_company_id = option;
    }
  };

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      var row = newprops.row;
      this.setCheckedPoc(row, newprops);
      this.setState({
        ...this.state,
        ...newprops
      });
    }
  }

  handleManagementCompany = (id, valueObj) => {
    let pocs = valueObj.point_of_contacts;
    let temp = {};
    for (let poc of pocs) {
      temp[poc.id] = false;
    }
    this.setState({
      row: Object.assign(this.state.row, { [id]: valueObj }),
      checkedPoc: Object.assign({}, temp)
    });

    this.setState(prevState => ({
      ...prevState,
      row: {
        ...prevState.row,
        management_company: {
          ...prevState.row.management_company,
          id: valueObj.value,
          name: valueObj.label,
          point_of_contacts: valueObj.point_of_contacts
        }
      }
    }));
  };

  fy_assesment_div = (fyMap, index) => {
    const fy_assesment_div = (
      <>
        <div className="fy_assessment">
          <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
            {"FY Assessment "}
          </div>
          <FaTrashAlt
            style={{ float: "right", cursor: "pointer" }}
            size="1.5em"
            onClick={event => this.handleDeleteEvent(event, fyMap)}
          />
          <FormGroup row>
            <Label sm={4}>Fiscal Year Month</Label>
            <Col sm={8}>
              <Input
                type="select"
                name=""
                id={fyMap.id}
                name="fiscal_year_start"
                value={fyMap.fiscal_year_start}
                onChange={this.handleFyChange}
              >
                {<SelectOptions options={config.Months_Array} />}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup>
            <Label for="">Days past Due</Label>
            <TextValidator
              type="text"
              name="days_past_due"
              id={fyMap.id}
              placeholder=""
              onChange={this.handleFyChange}
              validators={["isNumber"]}
              errorMessages={["Please enter valid number"]}
              value={fyMap.days_past_due}
            />
          </FormGroup>
          <FormGroup>
            <Label for="">Assessment Dues</Label>
            <TextValidator
              type="text"
              name="assesment_dues"
              id={fyMap.id}
              placeholder="$"
              onChange={this.handleFyChange}
              validators={["isFloat"]}
              errorMessages={["Please enter valid number"]}
              value={fyMap.assesment_dues}
            />
          </FormGroup>
          <FormGroup>
            <Label>Frequency</Label>
            <Input
              type="select"
              name="assessment_frequency"
              id={fyMap.id}
              value={_.toLower(fyMap.assessment_frequency)}
              onChange={this.handleFyChange}
            >
              {<SelectOptions options={config.FrequencyMap} />}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="">Special Assessment Dues</Label>
            <TextValidator
              type="text"
              name="special_assessment_dues"
              id="special_assessment_dues"
              placeholder="$"
              onChange={this.handleFyChange}
              validators={["isFloat"]}
              errorMessages={["Please enter valid number"]}
              value={this.state.row.special_assessment_dues}
            />
          </FormGroup>
          <FormGroup>
            <Label>Special Assessment Frequency</Label>
            <Input
              type="select"
              name="special_assessment_frequency"
              id={fyMap.id}
              value={_.toLower(fyMap.special_assessment_frequency)}
              onChange={this.handleFyChange}
            >
              {<SelectOptions options={config.FrequencyMap} />}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="">Late Fee Description</Label>
            <Input
              type="textarea"
              name="late_fee_description"
              id={fyMap.id}
              value={fyMap.late_fee_description}
              onChange={this.handleFyChange}
            />
          </FormGroup>
        </div>
      </>
    );
    return fy_assesment_div;
  };

  pocModel = pocCount => {
    ValidatorForm.addValidationRule("isUSAPhoneNumMatch", value => {
      var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return phoneNumberPattern.test(value);
    });

    let pocTitleOptions = [];
    let PocTitles = config.HoaPocTitles;
    pocTitleOptions.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    PocTitles.map((title, index) => {
      pocTitleOptions.push(
        <option value={title} key={title + index}>
          {title}
        </option>
      );
    });
    var emailValidators = ["isEmail"];
    var emailValidatorMessage = ["Invalid Email-ID"];
    var phoneValidators = [];
    var phoneValidatorMessage = [];
    let pocNameId = "poc" + pocCount;
    let pocEmailId = "email" + pocCount;
    let pocPhoneId = "phone" + pocCount;
    let pocTitleId = "title" + pocCount;
    if (
      (this.state.row[pocEmailId] == null ||
        this.state.row[pocEmailId] === "") &&
      (this.state.row[pocPhoneId] == null || this.state.row[pocPhoneId] === "")
    ) {
      emailValidators = ["required", "isEmail"];
      emailValidatorMessage = [
        "POC Email or Phone Number is required",
        "Invalid Email-ID"
      ];
    } else if (
      this.state.row[pocPhoneId] != null &&
      this.state.row[pocPhoneId] !== ""
    ) {
      phoneValidators = ["isUSAPhoneNumMatch"];
      phoneValidatorMessage = ["Invalid phone number"];
    }
    let pocContentPage = (
      <>
        {
          <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
            {"Point of Contact " + pocCount + (pocCount === 1 ? "*" : "")}
          </div>
        }
        <div className="poc-style">
          <FormGroup row>
            <Label sm={2} for="">
              Name{" "}
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name={pocNameId}
                id={pocNameId}
                placeholder=""
                onChange={this.handleChange}
                value={this.state.row[pocNameId]}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2}>Title</Label>
            <Col sm={10}>
              <Input
                type="select"
                name=""
                id={pocTitleId}
                name={pocTitleId}
                value={this.state.row[pocTitleId]}
                onChange={this.handleChange}
              >
                {pocTitleOptions}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="">
              Email
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name={pocEmailId}
                id={pocEmailId}
                placeholder=""
                onChange={this.handleChange}
                validators={pocCount === 1 ? emailValidators : null}
                errorMessages={pocCount === 1 ? emailValidatorMessage : null}
                value={this.state.row[pocEmailId]}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="">
              Phone{" "}
            </Label>
            <Col sm={10}>
              <TextValidator
                type="text"
                name={pocPhoneId}
                id={pocPhoneId}
                placeholder=""
                onChange={this.handleChange}
                validators={pocCount === 1 ? phoneValidators : null}
                errorMessages={pocCount === 1 ? phoneValidatorMessage : null}
                value={this.state.row[pocPhoneId]}
              />
            </Col>
          </FormGroup>
        </div>{" "}
      </>
    );
    return pocContentPage;
  };

  handleCheckbox = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let checkedPoc = this.state.checkedPoc;
    checkedPoc[name] = value;
    this.setState({
      checkedPoc: Object.assign({}, checkedPoc)
    });
  };

  assessmentsForm = () => {
    return (
      <AssessmentComponent
        hoaID={this.state.row.id}
        refreshHoa={this.getHoaById}
        hoa={this.state.row}
        toggleModal={this.toggleModal}
      />
    );
  };

  formElements = () => {
    let addressTypes = config.addressTypes;
    let managementCompany = this.state.row.management_company;
    let pocArray = _.has(managementCompany, "point_of_contacts")
      ? managementCompany.point_of_contacts
      : [];
    let mc_pocs = [];
    mc_pocs.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    for (let poc of pocArray) {
      mc_pocs.push(
        <option value={poc.id} key={poc.id}>
          {poc.name + (poc.title && " | " + config.McPocTitles[poc.title])}
        </option>
      );
    }

    return (
      <>
        <div className="add-management-company">
          <div className="add-model-left-portion">
            <FormGroup>
              <Label for="">Name *</Label>
              <TextValidator
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["Name is required"]}
                value={this.state.row.name}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Management company </Label>
              {/* <HoaAsyncSelect
                id="management_company_id"
                callapi={apicall.searchManagementCompanies}
                placeholder="Search Company..."
                handleOnChange={(id, value) => {
                  this.handleManagementCompany(id, value);
                }}
                valueShown={this.state.row.management_company_id}
                isManagementCompany={true}
              /> */}
              <SelectManagementCompany 
                id="management_company_id"
                placeholder="Search Company..."
                handleOnChange={(id, value) => {
                  this.handleManagementCompany(id, value);
                }}
                valueShown={this.state.row.management_company_id}
                isRequired={true}
              />
            </FormGroup>
            {this.state.row.management_company_id && (
              <>
                <Label>Management Company POC 1</Label>
                <Input
                  type="select"
                  name="management_poc1_id"
                  id="management_poc1_id"
                  value={this.state.row.management_poc1_id}
                  onChange={this.handleChange}
                >
                  {mc_pocs}
                </Input>
                <Label>Management Company POC 2</Label>
                <Input
                  type="select"
                  name="management_poc2_id"
                  id="management_poc2_id"
                  value={this.state.row.management_poc2_id}
                  onChange={this.handleChange}
                >
                  {mc_pocs}
                </Input>
                <Label>Management Company POC 3</Label>
                <Input
                  type="select"
                  name="management_poc3_id"
                  id="management_poc3_id"
                  value={this.state.row.management_poc3_id}
                  onChange={this.handleChange}
                >
                  {mc_pocs}
                </Input>
              </>
            )}
            {Object.keys(addressTypes).map((addressType, index) => {
              return (
                <AddressComponent
                  key={index + "address"}
                  type={addressType}
                  handleChange={this.handleChange}
                  managementCompany={this.state.row}
                  heading={addressTypes[addressType]}
                  hoaName={this.state.row.name}
                />
              );
            })}
            {/* <FormGroup>
              <Label for="">Account </Label>
              <TextValidator
                type="text"
                name="account"
                id="account"
                placeholder="Account"
                onChange={this.handleChange}
                value={this.state.row.account}
              />
            </FormGroup> */}
            <FormGroup>
              <Label for="">Fax </Label>
              <TextValidator
                type="text"
                name="contact_fax"
                id="contact_fax"
                placeholder="Fax"
                onChange={this.handleChange}
                value={this.state.row.contact_fax}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">EIN/TaxID</Label>
              <TextValidator
                type="text"
                name="ein_taxid"
                id="ein_taxid"
                placeholder="EIN/TaxID"
                onChange={this.handleChange}
                value={this.state.row.ein_taxid}
              />
            </FormGroup>
            <FormGroup>
              <Label for=""> Status </Label>
              <Input
                type="select"
                name="status"
                id="status"
                onChange={this.handleChange}
                value={this.state.row.status}
              >
                <option value="" key="defaultValue">
                  Select...
                </option>
                <option value="Active" key="Active">
                  Active
                </option>
                <option value="Dissolved" key="Dissolved">
                  Dissolved
                </option>
                <option value="Inactive" key="Inactive">
                  Inactive
                </option>
                <option value="Master" key="Master">
                  Master
                </option>
                <option value="Voluntary" key="Voluntary">
                  Voluntary
                </option>
              </Input>
            </FormGroup>
          </div>
          <div className="add-model-right-portion">
            {this.pocModel(1)}
            {this.pocModel(2)}
            {this.pocModel(3)}
            {/* <FormGroup>
              <Label for="">Website</Label>
              <TextValidator
                type="text"
                name="website_address"
                id="website_address"
                placeholder="Website"
                onChange={this.handleChange}
                value={this.state.row.website_address}
              />
            </FormGroup> */}
            {/* <FormGroup>
              <Label for="">Website username</Label>
              <TextValidator
                type="text"
                name="website_username"
                id="website_username"
                placeholder="Website username"
                onChange={this.handleChange}
                value={this.state.row.website_username}
              />
            </FormGroup>
            <FormGroup>
              <Label for="">Website password</Label>
              <TextValidator
                type="text"
                name="website_password"
                id="website_password"
                placeholder="Website password"
                onChange={this.handleChange}
                value={this.state.row.website_password}
              />
            </FormGroup> */}
          </div>
        </div>
        <div className="add_assessment">
          {/* {this.state.fyArray &&
            this.state.fyArray.map((fyMap, index) =>
              this.fy_assesment_div(fyMap, index)
            )} */}
          {/* <div className="add_fy_Assesment" onClick={this.handleAddEvent}>
            <span className="text">Add FY Assessment</span>
            <FaPlus size="1.5em" />
          </div> */}
        </div>
      </>
    );
  };
}

export default FormComponent;
