import React,{useState,useEffect} from 'react'
import config from '../../utils/config'
import apicall from '../../utils/apicall'
import _ from 'lodash'
import DatePicker from 'react-datepicker';
import {FormGroup,Label,Button} from 'reactstrap';
export default (props) => {
    const[start_date, setStartDate] = useState(null);
    const[end_date, setEndDate] = useState(null);
    let reportsMap = config.reportsMap;
    let reportsDiv = Object.keys(reportsMap).map(
        (reportKey, index) => {
            return <li><a id={reportKey + index} href="#" 
            onClick={(e) => {
                apicall.downloadReports(reportKey,start_date,end_date)
                e.preventDefault()
            }}>{reportsMap[reportKey]}</a></li>
        }
    )
    console.log(start_date)

    return (
        <section className="content">
            <h1>Reports</h1>
            <FormGroup>
                    <Label for="" style={{ marginRight: '20px' }}>Start Date</Label>
                    <DatePicker id="start_date" selected={start_date ?
                        new Date(start_date) : null}
                        onChange={(date) => {
                            setStartDate(date);
                        }}
                     //   dateFormat={config.US_DATE_FORMAT}
                    />
            </FormGroup>
            <FormGroup>
                    <Label for="" style={{ marginRight: '25px' }}>End Date</Label>
                    <DatePicker id="end_date" selected={end_date ?
                        new Date(end_date) : null}
                        onChange={(date) => {
                            setEndDate(date);
                        }}
                    //   dateFormat={config.US_DATE_FORMAT}
                    />
                </FormGroup>
                    <Button style={{position:'absolute',top:'50%',left:'45%'}} onClick= {() => {
                        setStartDate(null)
                        setEndDate(null)
                    }}>Clear</Button>
            <ul>
                {reportsDiv}
            </ul>
        </section>
    )
}
