import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, CustomInput, FormGroup, Label, Input } from 'reactstrap';
import config from '../../../utils/config'
import apicall from '../../../utils/apicall'
import helpers from '../../../utils/helpers'
import { toast } from 'react-toastify';
import FormComp from '../../crud/FormComp';
import FieldHelperComponent from '../../helper_components/FieldHelperComponent';
import { ValidatorForm } from 'react-form-validator-core';
import _ from "lodash"
export default class FinanceLeaseFormComponent extends FormComp {

    constructor(props) {
        super(props)
        this.state = {
            leaseInfoObject: {},
            row: {},
            className: "lease_info_modal"
        }
    }

    toggleForm = () => {
        this.state.toggle();
    }

    handleRadioButton = (event) => {
        var row = this.state.row;
        let value = event.target.value;
        let name = event.target.name;
        if (typeof value === 'string') {
            value = helpers.getBoolean(value);
        }
        row[event.target.name] = value;
        if ((name === "at_lease_cap" || name === "owner_reside_before_lease" || name==="own_before_leasing_min"
            || name === "min_age_for_leasing" || name==="lease_max_times_per_year") && value) {     
            this.setState({
                critical_rental_restrictions: true
            })
        }

        this.setState({
            row: row
        });
        event.stopPropagation();
    }

    handleSubmit = (event) => {
        let isUpdate = this.state.row.id ? true : false;
        let leaseInfoObject = this.state.row;
        leaseInfoObject.critical_rental_restrictions = true
        if(!leaseInfoObject.at_lease_cap &&
            !leaseInfoObject.owner_reside_before_lease &&
            !leaseInfoObject.own_before_leasing_min && 
            !leaseInfoObject.min_age_for_leasing &&
            !leaseInfoObject.lease_max_times_per_year){
                leaseInfoObject.critical_rental_restrictions = false
            }
        if (!isUpdate) {
            apicall.createLeaseInfo(leaseInfoObject, this.state.propertyid).then(
                response => {
                    if (response !== null && response.hasOwnProperty('errors')) {
                        let errorMsg = helpers.getErrorMsg(response.error);
                        if (errorMsg)
                            this.notify(errorMsg);

                    } else {
                        this.notify("Leasing Info Created Successfully");
                        this.state.reload();
                    }
                }
            )
        } else {
            apicall.updateleaseInfo(leaseInfoObject, this.state.propertyid, leaseInfoObject.id).then(
                response => {
                    if (response !== null && response.hasOwnProperty('errors')) {
                        let errorMsg = helpers.getErrorMsg(response.error);
                        if (errorMsg)
                            this.notify(errorMsg);

                    } else {
                        this.notify("Leasing Info Updated Successfully");
                        this.state.reload();
                    }
                }
            )
        }
        this.toggleForm();
        event.preventDefault();
    }

    checkboxQuestion = (legalQuestionsMap) => {
        const Questions = config.legalInfoMap;
        let keysArray = Object.keys(legalQuestionsMap);
        let row = this.state.row;
        let QuestionsDiv = keysArray.map(
            (questionkey, index) => {
                let questionType = legalQuestionsMap[questionkey];
                if (questionType === 'boolean' && typeof this.state.row !== 'undefined') {
                    return (
                        <>
                            <div className="addMarginBottom">
                                {Questions[questionkey]}
                            </div>
                            <FormGroup style={{ display: 'flex' }}>
                                <CustomInput id={questionkey + "yes"} type="radio" name={questionkey} value={true} onClick={this.handleRadioButton}
                                    checked={this.state.row[questionkey] === true ? 'checked' : ''} />
                                <Label style={{ marginRight: "2%" }}>Yes</Label>
                                <CustomInput id={questionkey + "no"} type="radio" name={questionkey} value={false} 
                                onClick={e => {
                                    this.handleRadioButton(e)
                                    if (_.has(config.leaseInfoMap,e.target.name)){
                                        row[config['leaseInfoMap'][e.target.name]] = null
                                        this.setState({row})
                                    }
                                }}
                                    checked={this.state.row[questionkey] === false ? 'checked' : ''} />
                                <Label style={{ marginRight: "2%" }}>No</Label>

                            </FormGroup>
                        </>
                    )
                } else if (typeof this.state.row !== 'undefined') {
                    return (
                        <FieldHelperComponent key={questionkey} fieldNames={[questionkey]} fieldLabels={[Questions[questionkey]]} formMap={this.state.row}
                            handleInputChange={this.handleChange} fieldTypes={['number']} showFields={this.state.row[keysArray[index - 1]] ? this.state.row[keysArray[index - 1]] : false} />
                    )
                }
            }
        )
        return QuestionsDiv;

    }

    formElements = () => {
        const legalQuestionsMap = config.legalQuestionsMap;
        let isUpdate = this.state.isUpdate;
        let headerText = '';
        if (isUpdate) {
            headerText = 'Update Lease data'
        } else {
            headerText = 'Create Lease Data'
        }
        return (
            <ValidatorForm onSubmit={this.handleSubmit} autocomplete="off">
                {this.checkboxQuestion(legalQuestionsMap)}
            </ValidatorForm>
        )
    }


}