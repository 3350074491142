/* eslint-disable no-lone-blocks */
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Form, Input, CustomInput, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import accesscodesapi from '../../../utils/api/accesscodesapi';
import helpers from '../../../utils/helpers';
import '../../../styles/_access-codes.scss';
import config from '../../../utils/config';
import _ from 'lodash'

export default class AccessCodeModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            ...this.props
        }
        const aco = _.isEmpty(props.accessCodesObject) ?
                        (props.separateAccessCodes ? [{}, {}] : [{}])
                        : props.accessCodesObject
        this.state = {
            ...this.state,
            accessCodesObject: aco,
            accessForObject: null,
            accessToObject: null,
            accessControlsObject: {},
            accessFor: {},
            files: [{}, {}],
            showCreate: false
        }
    }

    componentWillMount() {
        this.getAccessTo(this.props.propertyid)
        this.getAccessFor(this.props.propertyid);
        this.getAccessControls(this.props.propertyid);
    }

    getAccessFor = (propertyid) => {
        accesscodesapi.getAccessFor(propertyid).then(
            resp => {
                this.setState({
                    accessForObject: resp
                })
            }
        )
    }

    getAccessTo = (propertyid) => {
        accesscodesapi.getAccessTo(propertyid).then(
            resp => {
                this.setState({
                    accessToObject: resp
                })
            }
        )
    }

    getAccessControls = (propertyid) => {
        accesscodesapi.getAccessControls(propertyid).then(
            resp => {
                this.setState({
                    accessControlsObject: resp
                })
            }
        )
    }

    toggleAccessCodeModal = () => {
        this.state.toggle();
    }

    handleChange = (event, ix) => {
        let name = helpers.stripLastChar(event.target.name)
        if (event.target.type === 'file') {
            let files = this.state.files
            files[ix] = event.target.files[0]
            this.setState({files});
        }
        var aco = this.state.accessCodesObject[ix];
        aco[name] = event.target.value
        if (this.props.accessFor === "building" && this.state.accessCodesObject.length > 1 &&
            (aco['access_to'] === null || typeof aco['access_to'] === 'undefined')) {
            aco['access_to'] = ix === 0 ? 'Owner' : 'Vendor'
        }

        this.setState({
            accessCodesObject: this.state.accessCodesObject.map((x, i) => i === ix ? aco : x)
        });
    }

    handleRadioButton = (event, isBoolean, ix) => {
        var aco = this.state.accessCodesObject[ix];
        let value = event.target.value;
        let name = helpers.stripLastChar(event.target.name)
        let id = helpers.stripLastChar(event.target.id)
        if (typeof value === 'string') {
            value = helpers.getBoolean(value);
        }
        {
            !isBoolean ?
                (
                    aco[name] = id
                ) : (
                    aco[name] = value
                )
        }
        // if (name === 'code' || name === 'other') {
        //     accessCodesObject['access_contact_name'] = null;
        //     accessCodesObject['access_contact_email'] = null;
        //     accessCodesObject['access_contact_phone'] = null;
        //     accessCodesObject['access_control_cost'] = null;
        // }
        this.setState({
            accessCodesObject: this.state.accessCodesObject.map((x, i) => i === ix ? aco : x)
        });
    }

    notify = (errorMsg) => toast(errorMsg)

    handleSubmit = (event) => {
        let isUpdate = this.state.isUpdate;
        let accessCodeObject = this.state.accessCodesObject;
        if (!isUpdate) {
            accessCodeObject.forEach((x, ix) => {
                x.access_for = this.props.accessFor
                accesscodesapi.createAccessCodes(x, this.state.propertyid, this.state.files[ix]).then(
                    response => {
                        if (response !== null && response.hasOwnProperty('errors')) {
                            let errorMsg = helpers.getErrorMsg(response.errors);
                            if (errorMsg)
                                this.notify(errorMsg);

                        } else {
                            this.notify("Access Codes Created Successfully");
                            this.props.toggle()
                            this.props.refreshList();
                        }
                    }
                )
            })
        } else {
            accessCodeObject.forEach((x, ix) => {
                x.access_for = this.props.accessFor
                accesscodesapi.updateAccessCodes(x, this.state.propertyid, x.id, this.state.files[ix]).then(
                    response => {
                        if (response !== null && response.hasOwnProperty('errors')) {
                            this.notify('Error while updating access code');

                        } else {
                            this.notify("Access Codes Updated Successfully");
                            this.props.toggle()
                            this.props.refreshList();
                        }
                    }
                )
            })
        }
        // this.toggleAccessCodeModal();
        event.preventDefault();
    }

    generateAccessForInput = (accessForObject) => {
        let accessForKeys = Object.keys(accessForObject);
        let inputRadio = accessForKeys.map(
            (accessFor, index) => {
                return (
                    <CustomInput type="radio" id={accessFor} name='accessFor' onClick={this.handleRadioButton}
                        label={accessForObject[accessFor]} value={this.state.accessCodesObject.access_for} />
                )
            }
        )
        return inputRadio;
    }

    generateRadioButtons = (object, name, label, isBoolean, ix) => {
        let aco = this.state.accessCodesObject[ix]
        let objectkeys = Object.keys(object);
        let inputRadio = null;
        {
            (!isBoolean) ?
                (
                    inputRadio = objectkeys.map(
                        (key, index) => {
                            return (
                                <CustomInput type="radio" id={key+ix} name={name+ix} onClick={(event) => this.handleRadioButton(event, isBoolean, ix)}
                                    label={object[key]} checked={(key === aco[name]) ? 'checked' : ''} value={key} required />
                            )
                        }
                    )
                ) : (
                    inputRadio = objectkeys.map(
                        (key, index) => {

                            return (
                                <CustomInput type="radio" id={key + '-' + name+ix} name={name+ix} onClick={(event) => this.handleRadioButton(event, isBoolean, ix)}
                                    label={key} checked={(object[key] === aco[name]) ? 'checked' : ''}
                                    value={object[key]} required />)
                        }
                    )
                )
        }

        return (
            <div className="questions">
                <div className="question">
                    {label}
                </div>
                <div className="radio-buttons">
                    {inputRadio}
                </div>
            </div>

        )
    }

    scanCardDiv = (ix) => {
        let aco = this.state.accessCodesObject[ix]
        return (
            <div className="control-details">
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="">{`Who needs to be contacted for ${this.state.accessControlsObject[aco.access_control]}?`}</Label>
                            <Input type="text" name={`access_contact_name${ix}`} id={`access_contact_name${ix}`} placeholder="Name" onChange={(e) => this.handleChange(e, ix)}
                                value={aco && aco.access_contact_name} required />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Phone</Label>
                            <Input type="text" name={`access_contact_phone${ix}`} id={`access_contact_phone${ix}`} placeholder="(000) 000-0000" onChange={(e) => this.handleChange(e, ix)}
                                value={aco && aco.access_contact_phone} required />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input type="text" name={`access_contact_email${ix}`} id={`access_contact_email${ix}`} placeholder="Email" onChange={(e) => this.handleChange(e, ix)}
                                value={aco && aco.access_contact_email} />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Cost for the Key Card / Fob?</Label>
                            <Input type="text" name={`access_control_cost${ix}`} id={`access_control_cost${ix}`} placeholder="" onChange={(e) => this.handleChange(e, ix)}
                                value={aco && aco.access_control_cost} />
                        </FormGroup>
                    </Col>
                </Row>

            </div>
        )
    }

    codeDiv = (ix) => {
        let aco = this.state.accessCodesObject[ix]
        return (
            <div className="control-details">
                <FormGroup>
                    <Label>What is the Code?</Label>
                    <Input type="text" name={`code${ix}`} id={`code${ix}`} placeholder="" onChange={(e) => this.handleChange(e, ix)}
                        value={aco.code} required />
                </FormGroup>
            </div>

        )
    }

    callAheadDiv = (ix) => {
        let aco = this.state.accessCodesObject[ix]
        return (
            <div className="control-details">
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="">Who needs to be called?</Label>
                            <Input type="text" name={`access_contact_name${ix}`} id={`access_contact_name${ix}`} placeholder="Name / Office" onChange={(e) => this.handleChange(e, ix)}
                                value={aco && aco.access_contact_name} required />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Phone</Label>
                            <Input type="text" name={`access_contact_phone${ix}`} id={`access_contact_phone${ix}`} placeholder="(000) 000-0000" onChange={(e) => this.handleChange(e, ix)}
                                value={aco && aco.access_contact_phone} required />
                        </FormGroup>
                    </Col>
                </Row>

            </div>
        )
    }

    otherDiv = (ix) => {
        let aco = this.state.accessCodesObject[ix]
        return (
            <div className="control-details">
                <FormGroup>
                    <Label>Briefly describe how to obtain access.</Label>
                    <Input type="text" name={`access_description${ix}`} id={`access_description${ix}`} placeholder="" onChange={(e) => this.handleChange(e, ix)}
                        value={aco && aco.access_description} />
                </FormGroup>
            </div>
        )
    }



    accessCodeForm = (accessCodesObject, ix) => {
        const booleanMap = config.booleanMap;
        let accessControlsObject = this.state.accessControlsObject;
        let accessControl = accessCodesObject.access_control;
        let access_form_exist = accessCodesObject.access_form_exist;

        const heading = () => {
            if(this.state.accessCodesObject.length === 1)
                return null
            if (this.state.accessCodesObject[ix].access_to) {
                return <h4>{this.state.accessCodesObject[ix].access_to}</h4>
            }
            return ix === 0 ? <h4>Owner</h4> : <h4>Vendor</h4>
        }

        return <div className="aco">
            {heading()}
            {accessControlsObject && this.generateRadioButtons(accessControlsObject, 'access_control', 'Access is controlled by', false, ix)}
            {(accessControl === 'scan' || accessControl === 'key')
                && this.scanCardDiv(ix)}
            {accessControl === 'code' && this.codeDiv(ix)}
            {accessControl === 'call' && this.callAheadDiv(ix)}
            {accessControl === 'other' && this.otherDiv(ix)}
            {this.generateRadioButtons(booleanMap, 'access_form_exist', 'Is an access form required?', true, ix)}
            {access_form_exist === true &&
                <div className="access-form">
                    <div>
                        Access Form Control
                    </div>
                    <FormGroup className="import-file">
                        <Input type="file" name={`file${ix}`} id={`file${ix}`} onChange={(e) => this.handleChange(e, ix)}
                            required />
                    </FormGroup>
                </div>
            }
        </div>
    }

    resetState = () => {

        this.setState({
            accessCodesObject: [{}],
            showCreate: !this.state.showCreate
        })
    }

    renderModal = () => {
        let isUpdate = this.state.isUpdate

        let headerText = '';
        if (isUpdate) {
            headerText = 'Update Access Codes'
        } else {
            headerText = 'Create Access Codes'
        }
        let accessCodesObject = this.state.accessCodesObject
        let accessForObject = this.state.accessForObject;
        return (
            <div>
                <ModalHeader toggle={this.toggleAccessCodeModal}>
                    <div className="">
                        <span className="heading">
                            {headerText}
                        </span>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={this.handleSubmit}>
                        {(accessForObject) && <h3>{accessForObject[this.props.accessFor]}</h3>}
                        {/*accessToObject && this.generateRadioButtons(accessToObject, 'access_to', "Access Code To", false)*/}
                        {accessCodesObject.map((x, ix) => this.accessCodeForm(x, ix))}
                        <Button>Submit</Button>
                    </Form>
                    {
                        this.state.showCreate && !isUpdate &&
                        <Button className="create-Btn" onClick={this.resetState}>Create</Button>
                    }
                </ModalBody>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Modal
                    className="access-code-modal"
                    isOpen={this.state.modal}
                    toggle={this.toggleAccessCodeModal}
                >
                    {this.renderModal()}
                </Modal>
            </div>
        )
    }
}

