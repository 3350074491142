import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

export default (props) => {
        return (
            <div>
                <Modal isOpen={true} toggle={props.onNoClick}>
	                <ModalHeader>
	                    <div className="">
	                        <span className="heading">
	                            {props.heading}
	                        </span>
	                    </div>
	                </ModalHeader>
	                <ModalBody>
	                    {props.children}
	                    <Button onClick={props.onYesClick}>Yes</Button>
	                    &nbsp;
	                    <Button onClick={props.onNoClick}>No</Button>
	                </ModalBody>
	            </Modal>
            </div>
        );
}
