import React from "react"
import { Button, Modal, ModalHeader, ModalBody, Form, CustomInput } from "reactstrap"
import { toast } from "react-toastify"
import apicall from "../../utils/apicall"

class AskSearchQuestionsModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            questions: null,
            checkedMap: {},
            noneChecked: false,
        }
        this.state = {
            ...this.state,
            ...this.props,
        }
    }

    componentDidMount() {
        let propertyId = this.props.propertyID
        this.getQuestions(propertyId)
    }

    getQuestions = propertyId => {
        let questions = null
        apicall.getSearchQuestions(propertyId).then(response => {
            questions = response
            this.setState({
                questions: questions,
            })
        })
    }

    toggleQuestions = () => {
        this.props.toggle()
    }

    notify = errorMsg => toast(errorMsg)

    handleSubmit = event => {
        let hoaCheckArray = Object.keys(this.state.checkedMap)
        apicall.hoaCheck(hoaCheckArray, this.props.propertyID, this.props.row.actionable_id).then(response => {
            if (response !== null && response.hasOwnProperty("error")) {

                let errorMsg = response.error


                if (errorMsg) this.notify("Failed: " + errorMsg)
            } else {
                this.notify("HOA Checked Successfully")
            }
            this.toggleQuestions()
        })
        event.preventDefault()
    }

    handleInputChange = event => {
        const target = event.target
        const value = target.type === "checkbox" ? target.checked : target.value
        const name = target.name
        let checkedMap = Object.assign({}, this.state.checkedMap)
        checkedMap[name] = value
        this.setState({
            checkedMap,
        })
    }

    unCheckAll = event => {
        const target = event.target
        const value = target.type === "checkbox" ? target.checked : target.value
        this.setState({
            noneChecked: value,
        })
        if (value === true) {
            let checkedMap = Object.assign({}, this.state.checkedMap)
            for (let checked in checkedMap) {
                checkedMap[checked] = false
            }
            this.setState({
                checkedMap,
            })
        }
    }

    notify = message => toast(message)

    createQuestions = questions => {
        let questionsKeys = Object.keys(questions)
        let questionDiv = questionsKeys.map((question, index) => {
            if (question !== "none") {
                return (
                    <CustomInput
                        type="checkbox"
                        name={question}
                        id={question}
                        label={questions[question]}
                        key={"question" + index}
                        value={this.state.checkedMap[question]}
                        onChange={event => {
                            if (!this.state.noneChecked) {
                                this.handleInputChange(event)
                            }
                        }}
                        checked={this.state.checkedMap[question]}
                    />
                )
            } else {
                return (
                    <CustomInput
                        type="checkbox"
                        name={question}
                        id={question}
                        label={questions[question]}
                        key={"question" + index}
                        value={this.state.noneChecked}
                        onChange={this.unCheckAll}
                        checked={this.state.noneChecked}
                    />
                )
            }
        })
        return questionDiv
    }

    renderModal = () => {
        if (this.state.questions) {
            let questionsDiv = this.createQuestions(this.state.questions)
            return (
                <div>
                    <ModalHeader toggle={this.toggleQuestions}>Please Answer the questions below</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.handleSubmit}>
                            {questionsDiv}
                            {(this.props.row && this.props.row.current_status.trim().toLowerCase() === "closed") ? null : (
                                <Button>Submit</Button>
                            )}
                        </Form>
                    </ModalBody>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <Modal className="questions-modal" isOpen={this.props.modal} toggle={this.toggleQuestions}>
                    {this.renderModal()}
                </Modal>
            </div>
        )
    }
}

export default AskSearchQuestionsModal
