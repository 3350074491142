import React, { Component, useState, useEffect } from "react"
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	Table,
	Label,
	Form,
	Input,
	CustomInput,
	Col,
	Row,
} from "reactstrap"
import { FaAngleUp } from 'react-icons/fa';
import apicall from "../../utils/apicall"
import DatePicker from "react-datepicker"
import dayjs from "dayjs"
import Loader from "../helper_components/LoaderComponent"
import HoaAsyncSelect from "../helper_components/HoaAsyncSelect"
import _ from "lodash"
import QuickStats from './QuickStats'
import COLORS from "./Colors"
import '../../styles/_dashboard.scss'
import { Link } from "react-router-dom"
import {
	PieChart,
	Pie,
	Legend,
	Tooltip,
	Cell,
	ResponsiveContainer,
} from "recharts"
import Select from 'react-select'
import PaginationComponent from "../helper_components/PaginationComponent"
import AskSearchQuestionsModal from "../properties/AskSearchQuestionsModal"
import LienModal from "../lien/LienModal"
import InspectionModal from "../inspection/InspectionModal"
import ViolationModal from "../violations/ViolationModal"
import PaymentModal from "../payments/paymentModal"
import helpers from "../../utils/helpers";
import config from "../../utils/config";

export default props => {
	const [graph_data, set_graph_data] = useState({})
	const [table_data, set_table_data] = useState()
	const [loading, set_loading] = useState(false)
	const [form_inputs, set_form_inputs] = useState({
		action_type: '',
		start_date: '',
		end_date: '',
		page_no: 1,
		sort:{}
	})
	const [states, set_States] = useState()
	const [total, set_total] = useState()
	const [modal_inputs, set_modal_inputs] = useState({
		associationverificationModal: false,
		lienModal: false,
		inspectionModal: false,
		violationModal: false,
		paymentModal: false,
		associationinformationModal: false
	})
	const [row,set_row] = useState()

	useEffect(
		() => {
			getStates()
		}, []
	)

	const getStates = () => {
		apicall
			.getStates()
			.then(response => {
				let statesArray = [];
				statesArray = response.results.states.map(
					x => { return { value: x, label: x } }
				)
				set_States(statesArray)
			})
	}


	useEffect(() => {
		getActionTypeActions()
	}, [
			form_inputs.action_type,
			form_inputs.due_date_check,
			form_inputs.start_date,
			form_inputs.end_date,
			form_inputs.analyst,
			form_inputs.states,
			form_inputs.page_no,
			form_inputs.per_page,
			form_inputs.sort
		])

	const getActionTypeActions = () => {
		set_loading(true)
		apicall
			.getDashboardActions(form_inputs)
			.then(response => {
				set_graph_data(response.graph_data)
				set_table_data(response.results)
				set_total(response.total)
				// set_form_inputs({ ...form_inputs, start_date: response.start_date, end_date: response.end_date })
				// set_form_inputs({ ...form_inputs, start_date: response.start_date })
				set_loading(false)
			})
			.catch(e => set_loading(false))
	}
	const ActionTypes = [
		"AssociationVerification",
		"DocumentInfoReport",
		"Inspection",
		"Lien",
		"Payment",
		"Pir",
		"Violation",
	]
	let typeActions = []
	typeActions.push(<option value="">Select Action Type</option>)
	for (let type in ActionTypes) {
		typeActions.push(
			<option key={ActionTypes[type]} value={ActionTypes[type]}>
				{ActionTypes[type]}
			</option>
		)
	}
	const all_zeroes = arr => _.every(arr, x => _.values(x)[0] === 0 || typeof _.values(x)[0] === "undefined")

	const renderCharts = (graph_data) => {
		const transformResponse = (result, v, k) => result.concat({ name: k, value: v })
		let total_actions = _.reduce(graph_data, transformResponse, [])

		const all_zeroes = arr => _.every(arr, x => x.value === 0)

		if (all_zeroes(total_actions))
			return <h2>No Data</h2>
		else
			return (
				<>
					<h4 style={{ textAlign: 'center' }}>Properties Status</h4>
					<ResponsiveContainer width="100%" height={300}>
						<PieChart width={400} height={400}>
							<Pie
								cursor={'pointer'}
								data={total_actions}
								dataKey="value"
								innerRadius={60}
								onClick={(a, b) => {
									set_form_inputs({ ...form_inputs, action_type: a.name ,page_no:1})
								}}
								outerRadius={90}
								fill="#82ca9d"
							>
								{total_actions.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={COLORS[entry.name]} />
								))}
							</Pie>
							<Legend
								align="right"
								iconType="square"
								layout="vertical"
								verticalAlign="middle"
								iconSize="12"
								formatter={(value, entry) => {
									const { color } = entry

									return (
										<span style={{ color }} className="pie-legend">
											{value}
										</span>
									)
								}}
							/>
							<Tooltip />
						</PieChart>
					</ResponsiveContainer>
				</>
			)
	}

	const dashboardTable = () => {
		const actionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
			if (cell.trim().toLowerCase() !== "documentinforeport"
				&& cell.trim().toLowerCase() !== "pir" && cell.trim().toLowerCase() !== "associationverification") {
				return (
					<React.Fragment>
						<div
							style={{ cursor: "pointer", color: "red" }}
							onClick={() => {
								let modalName=cell.trim().toLowerCase() + "Modal";
								set_modal_inputs({...modal_inputs, [modalName] :!modal_inputs[modalName]})
								set_row(row)
							}}
						>
							{cell}
						</div>
					</React.Fragment>
				)
			}
			let tabNo = cell.trim().toLowerCase() === "documentinforeport" ? 5 : null
			tabNo = cell.trim().toLowerCase() === "pir" ? 1 : tabNo
			if (tabNo !== null) {
				return (
					<Link className="link" to={"/property-manage/" + tabNo + "/" + row.property_id} target="_blank">
						{cell}
					</Link>
				)
			}
			return cell
		}
		const linkFormatter = (cell, row, rowIndex, formatExtraData) => {
			if (typeof row !== "undefined") {
				return (
					<Link to={"/property-manage/2/" + row.property_id + '/' + row.hoa_id} target="_blank" key={cell + row.id}>
						{cell}
					</Link>
				)
			}
			return null
		}
		const notesFormatter = (cell,row) => {
			let notesArr = !_.isEmpty(cell) && cell.split(/\n/g)
			let notes = _.isArray(notesArr) && notesArr.filter(x=> x !== "").map(
				(x,ind) => {
					return <p>{x}</p>
				}
			)
			return(
				<>
				<p>{notes}</p>
				</>
			)
		}

		const dateFormatter = (cell) => {
			return helpers.formatDate(cell)
		}

		const columns = () => {
			return [
				{
					dataField: "clientid",
					text: "Client ID",
					formatter: linkFormatter,
					sort: true,
					onSort: (a,b) => {
						let obj = {[a]:b}
						set_form_inputs({...form_inputs, sort : obj})
					}
				},
				{
					dataField: "actionable_type",
					text: "Action Type",
					formatter: actionTypeFormatter,
					sort: true,
					onSort: (a,b) => {
						let obj = {[a]:b}
						set_form_inputs({...form_inputs,sort : obj})
					}
				},
				{
					dataField: "full_address",
					text: "Address",
					classes:'address-width'
				},
				{
					dataField: "zip",
					text: "Zip",
					classes: 'reduce-width',
					sort: true,
					onSort: (a,b) => {
						let obj = {[a]:b}
						set_form_inputs({ ...form_inputs,sort : obj })
					}
				},
				{
					dataField: "age",
					text: "Age in days",
					classes: 'reduce-width',
					sort: true,
					onSort: (a,b) => {
						let obj = {'age_in_days':b}
						set_form_inputs({ ...form_inputs,sort : obj })
					}
				},
				{
					dataField: "due_date",
					text: "Due Date",
					classes: 'reduce-width',
					sort: true,
					onSort: (a,b) => {
						let obj = {[a]:b}
						set_form_inputs({...form_inputs,sort : obj})
					},
					formatter: dateFormatter
				},
				{
					dataField: "current_status",
					text: "Status",
					classes: 'reduce-width',
					sort: true,
					onSort: (a,b) => {
						let obj = {[a]:b}
						set_form_inputs({...form_inputs,sort : obj})
					}
				},
				{
					dataField: "notes",
					text: "Notes",
					classes: "notes-width",
					formatter: notesFormatter
				},
			]
		}


		let properties = table_data
			.map(x => {
				const full_address = [x.property && x.property.street_address, x.property.city, x.property.state].join(", ")
				let diff
				if (x.created_at) diff = dayjs().diff(dayjs(x.created_at), "day")
				const age = diff && diff < 0 ? 0 : diff
				let clientid = x.property.clientid;
				let zip = x.property.zip;
				return { ...x, full_address, age,clientid ,zip}
			});
		return (
			<React.Fragment key={form_inputs.action_type}>
				<PaginationComponent
					columns={columns()}
					rows={properties}
					handleSubmit={(event, page, perPage) => {
						console.log('page-->',page)
						console.log('perPage-->',perPage)
						set_form_inputs({...form_inputs,page_no: page, per_page: perPage})
						event.preventDefault()
					}
					}
					pageNo={form_inputs.page_no}
					totalCount={table_data && total}
					// hidePerPage={true}
					perPage={form_inputs.per_page}
					totalCount={table_data && total}
				/>
				{modal_inputs.lienModal && (
					<LienModal
						toggle={() => {
							set_modal_inputs({...modal_inputs,lienModal : !modal_inputs.lienModal})
						}}
						title="Lien"
						open={true}
						row={row}
						propertyID={row.property_id}
						actionID={row.actionable_id}
					/>
				)}
				{modal_inputs.inspectionModal && (
					<InspectionModal
						toggle={() => {
							set_modal_inputs({...modal_inputs,inspectionModal: !modal_inputs.inspectionModal})
						}}
						title="Inspection"
						open={true}
						row={row}
						propertyID={row.property_id}
						actionID={row.actionable_id}
					/>
				)}
				{modal_inputs.violationModal && (
					<ViolationModal
						toggle={() => {
							set_modal_inputs({...modal_inputs,violationModal:!modal_inputs.violationModal})
						}}
						title="Violation"
						open={true}
						row={row}
						propertyID={row.property_id}
						actionID={row.actionable_id}
					/>
				)}
				{modal_inputs.paymentModal && (
					<PaymentModal
						toggle={() => {
							set_modal_inputs({...modal_inputs,paymentModal : !modal_inputs.paymentModal})
						}}
						title="Payment"
						open={true}
						row={row}
						propertyID={row.property_id}
						actionID={row.actionable_id}
						dashboardProperty={true}
					/>
				)}
				{/* {modal_inputs.associationinformationModal &&
					<AssociationInformationModal toggle={() => {
						set_modal_inputs({...modal_inputs,associationinformationModal : !modal_inputs.associationinformationModal})
					}} title="HOA" open={true} 
							row={_.has(row,'hoa') && rowhoa}
						propertyID={this.state.propertyID} actionID={this.state.actionID} />
				} */}
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<QuickStats states={states}/>
			{loading && <Loader />}
			<Row className="graph-data">
				<Col xs='5'>
					{renderCharts(graph_data)}
				</Col>
				<Col className="form">

					<FormGroup>
						<h6>Start Date</h6>
						<DatePicker
							id="from_date"
							selected={(form_inputs.start_date && form_inputs.start_date !== '') ? new Date(form_inputs.start_date + "") : null}
							onChange={date => {
								set_form_inputs({ ...form_inputs, start_date: date,page_no:1 })
							}}
						/>
					</FormGroup>
					<FormGroup>
						<h6>End Date</h6>
						<DatePicker
							id="to_date"
							selected={(form_inputs.end_date && form_inputs.end_date !== '') ? new Date(form_inputs.end_date + "") : null}
							minDate = {(form_inputs.start_date && form_inputs.start_date !== '') ? new Date(form_inputs.start_date + "") : null}
							onChange={date => {
								set_form_inputs({ ...form_inputs, end_date: date,page_no:1 })
							}}
						/>

					</FormGroup>

					<FormGroup>
						<Label>
							Action Type
							<CustomInput
								type="select"
								name="action_type"
								id="action_type"
								value={form_inputs.action_type}
								onChange={event => {
									set_form_inputs({ ...form_inputs, action_type: event.target.value ,page_no:1})
								}}
							>
								{typeActions}
							</CustomInput>
						</Label>
					</FormGroup>
					<FormGroup style={{ width: '60%' }}>
						<HoaAsyncSelect
							callapi={apicall.searchAnalysts}
							handleOnChange={(x, y) => {
								set_form_inputs({ ...form_inputs, analyst: y ,page_no:1})
							}}
							valueShown={form_inputs.analyst}
							placeholder="Search Analyst..."
							isClearable={true}
						/>
					</FormGroup>
					<FormGroup style={{ width: '60%' }}>
						<Select
							options={states ? states : []}
							isMulti={true}
							onChange={
								(value) => {
									set_form_inputs({ ...form_inputs, states: value, page_no:1})
								}
							}
							placeholder="Select States..."
							className="states-select"
						/>
					</FormGroup>
				</Col>
			</Row>
			{
				table_data && dashboardTable()
			}
		</React.Fragment>
	)
}





