import localforage from "localforage";

const config = {
  baseUrl: "https://hoa.cortexcraft.com",
  // 'baseUrl': 'https://hoa-prod.cortexcraft.com',
  loginUrl: "https://hoa-web.cortexcraft.com",
  accessToken: "access-token",
  success: "SUCCESS",
  localforage: localforage.createInstance({
    name: "hoa",
  }),
  US_DATE_FORMAT: "MM/DD/YYYY",
  US_DATE_TIME_FORMAT: "MM/DD/YYYY HH:mm:ss",
  API_DATE_FORMAT: "YYYY-MM-DD",
  debounceTimer: 500,
  actionType: "actionTypes",
  listofTabs: [
    "Dashboard",
    "Create Analyst",
    "Area Analysts",
    "Property Upload",
    "Property Search",
    "Property Manage",
  ],
  userId: "",
  userRole: "",
  PropertyMap: {
    clientid: "Property ID",
    status: "Status",
    mortgage_amount: "Mortgage Amount",
    street_address: "Street Address",
    city: "City",
    state: "State",
    zip: "Zip",
    county: "County",
    entity_name: "Entity Name",
    vested_name: "Vested Name",
  },
  accessCodesmap: {
    access_for: "access_for",
    access_to: "access_to",
    access_contact_name: "access_contact_name",
    access_contact_phone: "access_contact_phone",
    access_contact_email: "access_contact_email",
    access_control: "access_control",
    access_control_cost: "access_control_cost",
    access_description: "access_description",
    code: "code",
  },
  hoaMap: {
    name: " HOA Name",
    contact_email: "Email",
    contact_phone: "Phone Number",
  },
  legalInfoMap: {
    id: "Legal Information ID",
    critical_rental_restrictions: "Is there a Critical Rental Restrictions ?",
    at_lease_cap: "Is there a Lease Cap ?",
    owner_reside_before_lease:
      "Must the owner reside in the unit before leasing?",
    own_before_leasing_min:
      "Must the owner own the unit for a minimum number of months before leasing ?",
    own_before_leasing_min_months: "Number of months unit must be owned ?",
    min_age_for_leasing: "Is there a minimum age for leasing ?",
    min_age_for_leasing_months: "Minimum age for leasing ?",
    lease_max_times_per_year:
      "Is there a maximum number of times a unit can be leased in a 12-month period ?",
    lease_max_times_per_year_value:
      "Maximum number of times unit may be leased in a 12-month period ?",
    lease_min: "Must the unit be leased for a minimum number of months ?",
    lease_min_months: "Minimun number of months for leasing ?",
    lease_max: "Is there a maximum number of months the unit may be leased?",
    lease_max_months: "Maximum number of months for leasing ?",
    lease_license_required: "Is a lease license required ?",
    lease_approval_required: "Is lease approval required by the association ?",
    tenant_application_required: "Is a tenant application required ?",
    association_move_in_checklist:
      "Is there an association move-in checklist ?",
  },
  legalQuestionsMap: {
    //critical_rental_restrictions: 'boolean',
    at_lease_cap: "boolean",
    owner_reside_before_lease: "boolean",
    own_before_leasing_min: "boolean",
    own_before_leasing_min_months: "string",
    min_age_for_leasing: "boolean",
    min_age_for_leasing_months: "string",
    lease_max_times_per_year: "boolean",
    lease_max_times_per_year_value: "string",
    lease_min: "boolean",
    lease_min_months: "string",
    lease_max: "boolean",
    lease_max_months: "string",
    lease_license_required: "boolean",
    lease_approval_required: "boolean",
    tenant_application_required: "boolean",
    association_move_in_checklist: "boolean",
  },
  booleanMap: {
    Yes: true,
    No: false,
  },
  paymentResponsiblities: {
    management: "Management",
    owner: "Owner",
    tenant: "Tenant",
  },
  responsibility: {
    owner: "Owner",
    tenant: "Tenant",
  },
  chargeTypes: {
    assessment: "Assessment",
    fine: "Fine",
    legal: "Legal",
    estoppel: "eStoppel",
    administrative: "Administrative",
    special_assessment: "Special Assessment",
    documents: "Documents",
    others: "Others",
  },
  periodicity: {
    Daily: "day",
    Weekly: "week",
    "Bi-Weekly": "bi-week",
    Monthly: "month",
    Quarterly: "quarter",
    "Semi-Annually": "semi-annual",
    Annually: "annual",
  },
  periodicityDummy: {
    day: "Daily",
    week: "Weekly",
    "bi-week": "Bi-Weekly",
    month: "Monthly",
    quarter: "Quarterly",
    "semi-annual": "Semi-Annually",
    annual: "Annually",
  },

  attorneyTypes: {
    ResicapAttorney: "Resicap",
    HoaAttorney: "HOA",
  },
  actionTypes: {
    AccountReview: "Account Review",
    AssociationVerification: "Association Verification",
    DocumentInfoReport: "Document Info Report",
    Pir: "PIR",
  },
  hoa_mc_access: [
    "admin",
    "manager",
    "senior_analyst",
    "violation_analyst",
    "leasing_sales_analyst",
    "payment_analyst",
    "coordinator",
    "controller",
    "association_analyst",
    "onboarding_analyst",
    "executive",
  ],
  readOnlyTable: ["general_user", "executive", "vendor"],
  createAction: [
    "admin",
    "manager",
    "senior_analyst",
    "violation_analyst",
    "leasing_sales_analyst",
    "payment_analyst",
    "coordinator",
    "controller",
    "association_analyst",
    "onboarding_analyst",
  ],
  onlyAdminWrite: ["admin"],
  showHoa: ["admin", "manager", "senior_analyst", "onboarding_analyst"],
  propertyUpload: ["admin", "manager"],
  paymentRead: [
    "violation_analyst",
    "leasing_sales_analyst",
    "executive",
    "vendor",
  ],
  paymentWrite: [
    "payment_analyst",
    "senior_analyst",
    "onboarding_analyst",
    "coordinator",
    "controller",
    "admin",
    "manager",
  ],
  paymentHide: ["association_analyst", "general_user"],
  chargesCreateOnly: ["violation_analyst", "leasing_sales_analyst", "admin"],
  chargesWrite: [
    "senior_analyst",
    "payment_analyst",
    "coordinator",
    "controller",
    "manager",
  ],
  chargesRead: ["executive", "vendor"],
  chargeApprove: ["senior_analyst", "manager"],
  chargesHide: ["onboarding_analyst", "executive", "vendor"],
  ViolationHide: ["association_analyst", "general_user"],
  ViolationWrite: [
    "senior_analyst",
    "violation_analyst",
    "leasing_sales_analyst",
    "payment_analyst",
    "coordinator",
    "controller",
    "admin",
    "manager",
  ],
  ViolationRead: ["onboarding_analyst", "executive", "vendor"],
  LeaseInfoRead: ["executive", "vendor", "general_user"],
  LeaseInfoWrite: [
    "senior_analyst",
    "violation_analyst",
    "payment_analyst",
    "leasing_sales_analyst",
    "admin",
    "manager",
    "coordinator",
    "controller",
    "onboarding_analyst",
  ],
  LeaseHide: ["association_analyst"],
  LiensRead: [
    "violation_analyst",
    "leasing_sales_analyst",
    "payment_analyst",
    "onboarding_analyst",
    "coordinator",
    "controller",
    "executive",
    "vendor",
  ],
  LiensWrite: ["senior_analyst", "admin", "manager"],
  LiensHide: ["association_analyst", "general_user"],
  payeeTypes: {
    Hoa: "HOA",
    ManagementCompany: "Management Company",
    Attorney: "Attorney",
  },
  generalInfoRead: ["executive", "vendor", "general_user"],
  FrequencyMap: {
    monthly: "Monthly",
    quarterly: "Quarterly",
    "semi-annually": "Semi Annually",
    annually: "Annually",
  },
  documentNames: [
    "CC&Rs",
    "Bylaws",
    "Amendments",
    "ARC Form",
    "Move-In Checklist",
    "Tenant Registration",
    "Parking Permit Form",
    "Pool Pass Form",
    "W9",
    "Others",
  ],
  HoaPocTitles: [
    "President",
    "Vice-President",
    "Secretary",
    "Treasurer",
    "Other",
  ],
  McPocTitles: {
    "property-manager": "Property Manager",
    "assisstant-property-manager": "Assisstant Property Manager",
    accounting: "Accounting",
    other: "Other",
  },
  charge_type: [
    "Administrative",
    "Assessment",
    "Common Charge",
    "Documents",
    "eStoppel",
    "Fine",
    "Interest",
    "Legal",
    "Late Fee",
    "Maintenance Fee",
    "SA1 - Special Assessment 1",
    "SA2 - Special Assessment 2",
    "SA3 - Special Assessment 3",
    "Special Assessment",
    "UC - Utility (Cable/WiFi)",
    "UE - Utility (Electric)",
    "UG - Utility (Gas)",
    "UT - Utility (Trash)",
    "UW - Utility (Water)",
    "Other",
  ],

  Months_Array: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  commentType: {
    Approved: "approval",
    Rejected: "rejection",
  },
  reportsMap: {
    hoas: "Hoas",
    managementCompany: "Management Companies",
    properties: "Properties",
    accessCodes: "Access Codes",
    inspectionCategory: "Inspection Categories",
    utilities: "Utilities",
    amneties: "Amneties",
    tenantMoveIn: "Tenant Move-in",
    attorneys: "Attorneys",
    leasingInfo: "Leasing Information",
    actions: "Actions",
    violations: "Violations",
    documents: "Documents",
    notes: "Notes",
    payments: "Payments",
    approved_payments: "Approved Payments",
    mpal_report: "MPAL Report",
  },
  leaseInfoMap: {
    own_before_leasing_min: "own_before_leasing_min_months",
    min_age_for_leasing: "min_age_for_leasing_months",
    lease_max_times_per_year: "lease_max_times_per_year_value",
    lease_min: "lease_min_months",
  },
  addressTypes: {
    physical: "Physical Address",
    mailing: "Local Mailing Address",
    payment: "Payment Mailing Address",
  },
  paymentHoaAction: ["fy_assessment", "special_assessment", "account_review"],
  payment_settlementStatus: [
    "Outstanding",
    "Cleared",
    "Stop Payment/Void",
    "Re-Issued",
  ],
  payment_reason: ["Sold", "Wrong Payee", "Wrong Amount", "Other"],
};
export default config;
