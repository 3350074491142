import FormComp from "../../crud/FormComp"
import React from "react"
import { FormGroup, Label, Button, CustomInput } from "reactstrap"
import TextValidator from "../../helper_components/TextValidator"
import { ValidatorForm } from "react-form-validator-core"
import FieldHelperComponent from "../../helper_components/FieldHelperComponent"
import DocumentUploadComponent from "../../helper_components/DocumentUploadComponent"
import apicall from "../../../utils/apicall"
import helpers from "../../../utils/helpers"
export default class TenantMoveInModal extends FormComp {
    constructor(props) {
        super(props)
        this.state = {
            row: props.row,
            title: props.title,
            propertyid: props.propertyid,
        }
        this.state = {
            ...this.state,
            ...this.props,
        }
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {

            this.setState({
                ...this.state,
                ...newprops,
            })
        }
    }

    handleSubmit = event => {
        var row = this.state.row
        if (row.id) {
            apicall.updateTenantMovein(row, this.state.propertyid).then(response => {
                if (response) {
                    this.notify("Updated successfully.")
                    this.toggleModal()
                }
            })
        } else {
            apicall.createTenantMovein(row, this.state.propertyid).then(response => {
                if (response) {
                    this.notify("Added successfully.")
                    this.toggleModal()
                }
            })
        }
        event.preventDefault()
    }

    formElements = () => {

        let row = this.state.row
        return (
            <ValidatorForm onSubmit={this.handleSubmit} style={{ width: "70%" }} className="tenantForm" autocomplete="off">
                <div className="addMarginBottom">Is a lease license required?</div>
                <FormGroup style={{ display: "flex" }}>
                    <CustomInput
                        id="lease_license_requiredYes"
                        type="radio"
                        name="lease_license_required"
                        value={true}
                        onClick={this.handleRadioButton}
                        checked={this.state.row.lease_license_required === true ? "checked" : ""}
                    />
                    <Label style={{ marginRight: "2%" }}>Yes</Label>
                    <CustomInput
                        id="lease_license_requiredNo"
                        type="radio"
                        name="lease_license_required"
                        value={false}
                        onClick={e => {
                            this.handleRadioButton(e)
                            row["township_name"] = ''
                            this.setState({ row })
                        }}
                        checked={this.state.row.lease_license_required === false ? "checked" : ""}
                    />
                    <Label style={{ marginRight: "2%" }}>No</Label>
                </FormGroup>
                <FieldHelperComponent
                    key="township_name"
                    fieldNames={["township_name"]}
                    fieldLabels={["Township Name"]}
                    formMap={this.state.row}
                    handleInputChange={this.handleChange}
                    fieldTypes={["text"]}
                    showFields={this.state.row.lease_license_required}
                />
                <FormGroup>
                    <div className="addMarginBottom">Is Lease Approval Required?</div>
                    <FormGroup style={{ display: "flex" }}>
                        <CustomInput
                            id="lease_approval_requiredyes"
                            type="radio"
                            name="lease_approval_required"
                            value={true}
                            checked={this.state.row.lease_approval_required === true ? "checked" : ""}
                            onClick={this.handleRadioButton}
                        />
                        <Label>Yes</Label>
                        <CustomInput
                            id="lease_approval_requiredno"
                            type="radio"
                            name="lease_approval_required"
                            value={false}
                            checked={this.state.row.lease_approval_required === false ? "checked" : ""}
                            onClick={e => {
                                this.handleRadioButton(e)
                                row["lease_days_before_move_in"] = null
                                this.setState({ row })
                            }}
                        />
                        <Label>No</Label>
                    </FormGroup>
                    <FieldHelperComponent
                        key="lease_days_before_move_in"
                        fieldNames={["lease_days_before_move_in"]}
                        fieldLabels={["Lease Days Before Move in"]}
                        formMap={this.state.row}
                        handleInputChange={this.handleChange}
                        fieldTypes={["number"]}
                        showFields={this.state.row.lease_approval_required}
                    />
                </FormGroup>
                <FormGroup>
                    <div className="addMarginBottom">Is Tenant Approval Required?</div>
                    <FormGroup style={{ display: "flex" }}>
                        <CustomInput
                            id="tenant_approval_required_yes"
                            type="radio"
                            name="tenant_approval_required"
                            value={true}
                            checked={this.state.row.tenant_approval_required === true ? "checked" : ""}
                            onClick={this.handleRadioButton}
                        />
                        <Label>Yes</Label>
                        <CustomInput
                            id="tenant_approval_required_no"
                            type="radio"
                            name="tenant_approval_required"
                            value={false}
                            checked={this.state.row.tenant_approval_required === false ? "checked" : ""}
                            onClick={e => {
                                this.handleRadioButton(e)
                                row["tenant_days_before_move_in"] = null
                                this.setState({ row })
                            }}
                        />
                        <Label>No</Label>
                    </FormGroup>
                    <FieldHelperComponent
                        key="tenant_days_before_move_in"
                        fieldNames={["tenant_days_before_move_in"]}
                        fieldLabels={["Tenant Days Before Move in"]}
                        formMap={this.state.row}
                        handleInputChange={this.handleChange}
                        fieldTypes={["number"]}
                        showFields={this.state.row.tenant_approval_required}
                    />
                </FormGroup>
                <FormGroup>
                    <div className="addMarginBottom">Lease addendum's required by the HOA?</div>
                    <FormGroup style={{ display: "flex" }}>
                        <CustomInput
                            id="lease_addendums_required_yes"
                            type="radio"
                            name="lease_addendums_required"
                            value={true}
                            checked={this.state.row.lease_addendums_required === true ? "checked" : ""}
                            onClick={this.handleRadioButton}
                        />
                        <Label>Yes</Label>
                        <CustomInput
                            id="lease_addendums_required_no"
                            type="radio"
                            name="lease_addendums_required"
                            value={false}
                            checked={this.state.row.lease_addendums_required === false ? "checked" : ""}
                            onClick={this.handleRadioButton}
                        />
                        <Label>No</Label>
                    </FormGroup>
                    <DocumentUploadComponent
                        row={this.state.row}
                        onChange={this.handleChange}
                        showDocuments={this.state.row.lease_addendums_required}
                    />
                </FormGroup>
                <FormGroup>
                    <div className="addMarginBottom">Is a copy of the lease required?</div>
                    <FormGroup style={{ display: "flex" }}>
                        <CustomInput
                            id="copyOfLeaseRequiredYes"
                            type="radio"
                            name="lease_copy_required"
                            value={true}
                            checked={this.state.row.lease_copy_required === true ? "checked" : ""}
                            onClick={this.handleRadioButton}
                        />
                        <Label>Yes</Label>
                        <CustomInput
                            id="copyOfLeaseRequiredNo"
                            type="radio"
                            name="lease_copy_required"
                            value={false}
                            checked={this.state.row.lease_copy_required === false ? "checked" : ""}
                            onClick={this.handleRadioButton}
                        />
                        <Label>No</Label>
                    </FormGroup>
                </FormGroup>
                <FormGroup>
                    <div className="addMarginBottom">Is tenant information required on leasing?</div>
                    <FormGroup style={{ display: "flex" }}>
                        <CustomInput
                            id="tenant_info_required_on_leasingYes"
                            type="radio"
                            name="tenant_info_required_on_leasing"
                            value={true}
                            checked={this.state.row.tenant_info_required_on_leasing === true ? "checked" : ""}
                            onClick={this.handleRadioButton}
                        />
                        <Label>Yes</Label>
                        <CustomInput
                            id="tenant_info_required_on_leasingNo"
                            type="radio"
                            name="tenant_info_required_on_leasing"
                            value={false}
                            checked={this.state.row.tenant_info_required_on_leasing === false ? "checked" : ""}
                            onClick={this.handleRadioButton}
                        />
                        <Label>No</Label>
                    </FormGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="">Rental Fee</Label>
                    <TextValidator
                        type="number"
                        name="rental_fee"
                        id="rental_fee"
                        placeholder="Rental Fee"
                        onChange={this.handleChange}
                        validators={["required", "minNumber:0"]}
                        errorMessages={["Rental Fee is required", "Negative values not allowed"]}
                        value={this.state.row.rental_fee ? this.state.row.rental_fee : ""}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="">Move-in deposit</Label>
                    <TextValidator
                        type="number"
                        name="move_in_deposit"
                        id="move_in_deposit"
                        placeholder="Move in deposit"
                        onChange={this.handleChange}
                        validators={["required", "minNumber:0"]}
                        errorMessages={["Move In Deposit is required", "Negative values not allowed"]}
                        value={this.state.row.move_in_deposit ? this.state.row.move_in_deposit : ""}
                    />
                </FormGroup>
                <FormGroup className="formsOtherGroup">
                    <Label for="">Other Fee</Label>
                    <div>
                        <TextValidator
                            type="number"
                            name="other_fee"
                            id="other_fee"
                            placeholder="Other Fee"
                            onChange={this.handleChange}
                            validators={["minNumber:0"]}
                            errorMessages={["Negative values not allowed"]}
                            value={this.state.row.other_fee ? this.state.row.other_fee : ""}
                        />
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label for="">Payment Method</Label>
                    <TextValidator
                        type="text"
                        name="payment_method"
                        id="payment_method"
                        placeholder="Payment Method"
                        onChange={this.handleChange}
                        validators={["required"]}
                        errorMessages={["Payment Method is required"]}
                        value={this.state.row.payment_method ? this.state.row.payment_method : ""}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="">Payee</Label>
                    <TextValidator
                        type="text"
                        name="payee"
                        id="payee"
                        placeholder="Payee"
                        onChange={this.handleChange}
                        validators={["required"]}
                        errorMessages={["Payee is required"]}
                        value={this.state.row.payee ? this.state.row.payee : ""}
                    />
                </FormGroup>
            </ValidatorForm>
        )
    }
}
