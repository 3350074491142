import FormComp from "../crud/FormComp";
import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Col,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import apicall from "../../utils/apicall";
import TabsComponent from "../helper_components/TabsComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Charges from "../payments/Charges";
import "../../styles/_payments.scss";
import config from "../../utils/config";
import Select from "react-select";
import helpers from "../../utils/helpers";
import DocumentUploadComponent from "../helper_components/DocumentUploadComponent";
import EditAddressModal from "./EditAddressModal";
import { FaEdit } from "react-icons/fa";
import { ValidatorForm } from "react-form-validator-core";
import LoaderComponent from "../helper_components/LoaderComponent";
import _ from "lodash";
import dayjs from "dayjs";
import { FaTrashAlt } from "react-icons/fa";
import DocumentsModal from "../documents/DocumentsModal";
import DocumentsTab from "../documents/DocumentsTab";
import "../../styles/_tab.scss";
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

export default class PaymentModal extends FormComp {
  constructor(props) {
    super(props);
    this.state = {
      row: {},
      paymentHoas: [],
      className: " payment-modal",
      submitButton: "",
      paymentData: {},
      payeeRecipients: null,
      documentUpload: null,
      addressModal: false,
      address: "",
      isLoading: true,
      dasboardPropertyInfo: null,
      assessments: [],
      openAddDocumentModal: false,
    };
    this.state = {
      ...this.state,
      ...this.props,
    };
  }

  getPropertyInfo = () => {
    apicall.getProperty(this.props.propertyID).then((response) => {
      this.setState({
        dasboardPropertyInfo: response,
      });
    });
  };

  getAssessments = (hoaId) => {
    let actionHoaId = hoaId ? hoaId : this.props.actionHoaId;
    apicall
      .fetchPropertyAssessments(this.props.propertyID, actionHoaId)
      .then((response) => {
        this.setState({
          assessments: response,
        });
        console.log("assessments===>", response);
      });
  };

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  toggleAddressModal = () => {
    this.setState({
      addressModal: !this.state.addressModal,
    });
  };

  fetchPayment = () => {
    apicall
      .fetchPaymentData(this.state.actionID, this.props.propertyID)
      .then((response) => {
        var row = this.createOptions(response);
        this.setState({
          row: row,
          isLoading: false,
        });
      });
  };

  deletePaymentDocument = (documentId) => {
    this.setState({ isLoading: true });
    console.log("event-->", documentId);
    apicall
      .deletePaymentDoc(this.props.propertyID, this.state.row.id, documentId)
      .then((response) => {
        helpers.notify("Document deleted successfully");
        this.fetchPayment();
      });
  };

  componentDidMount() {
    this.fetchPayment();
    this.props.dashboardProperty && this.getPropertyInfo();
    this.getAssessments();
  }

  createOptions = (row) => {
    if (row.id && row.payee) {
      let option = {};
      option["value"] = row.payee["id"];
      option["label"] = row.payee["name"];
      if (option) row.payee_id = option;
    }
    if (row.id && row.payee_recipient) {
      let option = {};
      option["value"] = row.payee_recipient["id"];
      option["label"] = row.payee_recipient["name"];
      if (option) row.payee_recipient_id = option;
    }
    if (row.id && row.negotiated_by) {
      let option = {};
      option["value"] = row.negotiated_by["id"];
      option["label"] = row.negotiated_by["email"];
      if (option) row.negotiated_user_id = option;
    }
    return row;
  };

  componentWillMount() {
    this.hideSubmit();
    // if(config['paymentRead'].includes(this.props.userRole)){
    //     this.hideSubmit()
    // }
    apicall.fetchPayee(this.props.propertyID).then((response) => {
      this.setState({
        payeeRecipients: response,
      });
    });

    apicall.fetchPaymentHoas(this.props.propertyID).then((response) => {
      this.setState({
        paymentHoas: response,
      });
    });
  }

  renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={this.toggleModal}>
          <div className="">
            <span className="heading">
              {(this.state.row && this.state.row.id ? "Update " : "Add ") +
                this.state.title}
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm autocomplete="off">
            {this.formElements()}
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  handleSubmit = () => {
    // debugger;
    console.log("handle submit called");
    this.setState({
      isLoading: true,
    });
    var row = this.state.row;
    row.payee_id = this.getSelectionValue(row.payee_id);
    row.payee_recipient_id = this.getSelectionValue(row.payee_recipient_id);
    row.negotiated_user_id = this.getSelectionValue(row.negotiated_user_id);
    if (row["other_payee"]) {
      delete row["payee_id"];
    }
    if (row["other_payee_recipient"]) {
      delete row["payee_recipient_id"];
    }
    if (row["payee_id"]) {
      delete row["other_payee_details"];
    }
    if (row["payee_recipient"]) {
      delete row["other_payee_recipient_details"];
    }
    delete row["payee"];
    delete row["payee_recipient"];
    delete row["negotiated_by"];
    for (let key in row) {
      if (row[key] == null) delete row[key];
    }
    apicall
      .paymentUpdate(row, this.state.propertyID, this.state.actionID)
      .then((response) => {
        if (response) {
          this.notify("payment Updated Successfully.");
          var row = this.createOptions(response);
          this.setState({
            row: response,
            isLoading: false,
          });
          //this.state.refershActions(this.state.propertyID);
        }
      });

    let payment_status_comment = row.payment_status_comment;
    let payments_status = row.payment_status;
    let submit_approval_comment = row.submit_approval_comment;
    let commentsArray = [];
    let approval_comment = null;
    let status_comment = null;
    if (submit_approval_comment) {
      approval_comment = {
        comment: submit_approval_comment,
        comment_type: "submit_approval",
      };
      commentsArray.push(approval_comment);
    }
    if (payments_status && payment_status_comment) {
      status_comment = {
        comment: payment_status_comment,
        comment_type: config["commentType"][payments_status],
      };
      commentsArray.push(status_comment);
    }

    if (!_.isEmpty(commentsArray)) {
      let comments = {
        comments: {
          comments: commentsArray,
        },
      };
      apicall.addComments(this.state.actionID, comments).then((response) => {
        this.fetchPayment();
      });
    }
    // if(!_.isEmpty(row.hoa_id))
    // {
    //     console.log("this.getAssessments(row.hoa_id);")
    //     this.getAssessments(row.hoa_id);
    // }
  };

  formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  handleSelection = (valuekey, valueObj, group) => {
    this.setState({
      row: Object.assign(this.state.row, { [valuekey]: valueObj }),
      row: Object.assign(this.state.row, { [group]: valueObj["group"] }),
    });
  };

  updateAddress = (data) => {
    const newVal = this.state.payeeRecipients[
      this.state.row.payee_recipient_type
    ].map((x) => {
      if (x.id == data.id) {
        x.payment_address_care_of = data.payment_address_care_of;
        x.payment_mailing_street = data.payment_mailing_street;
        x.payment_mailing_city = data.payment_mailing_city;
        x.payment_mailing_state = data.payment_mailing_state;
        x.payment_mailing_zipcode = data.payment_mailing_zipcode;
      }
      return x;
    });
    // const payeeRecipients = {...this.state.payeeRecipients, this.state.row[payee_recipient_type]: newVal}
    const payeeRecipients = Object.assign(this.state.payeeRecipients, {
      [this.state.row.payee_recipient_type]: newVal,
    });
    this.setState({ payeeRecipients: payeeRecipients });
  };

  getAddressAndAccount = (id) => {
    let data = null;
    this.state.payeeRecipients[this.state.row.payee_recipient_type].map((x) => {
      if (x.id == id) {
        data = x;
        return;
      }
    });
    return data;
  };

  payeeRecipientsAddress = (
    payee_recipient_type,
    payee_recipient,
    getAccountNumber
  ) => {
    let payeeRecipients = this.state.payeeRecipients;
    let payee_recipient_id = payee_recipient["value"];
    let selectedRecipientAddress = "";
    if (
      payeeRecipients !== "undefined" &&
      payeeRecipients !== null &&
      typeof payeeRecipients !== "undefined" &&
      typeof payeeRecipients[payee_recipient_type] !== "undefined" &&
      payeeRecipients[payee_recipient_type] !== null &&
      payeeRecipients[payee_recipient_type].length > 0
    ) {
      selectedRecipientAddress = payeeRecipients[payee_recipient_type].map(
        (object) => {
          if (object.id == payee_recipient_id)
            return !getAccountNumber ? (
              object.payment_address_care_of === null ? (
                <span>"Address not present"</span>
              ) : (
                <div>
                  <span>{object.payment_address_care_of + " "}</span>
                  <br />
                  <span>{object.payment_mailing_street + " "} </span> <br />
                  <span>
                    {object.payment_mailing_city +
                      ", " +
                      object.payment_mailing_state +
                      " " +
                      object.payment_mailing_zipcode}
                  </span>
                </div>
              )
            ) : (
              object.account
            );
        }
      );
    }
    return selectedRecipientAddress;
  };

  toggleDocument = () => {
    this.setState({
      openAddDocumentModal: !this.state.openAddDocumentModal,
    });
  };

  documentInvoiceComponent = () => {
    let payeeRecipients = this.state.payeeRecipients;
    let hoaOptions =
      payeeRecipients && helpers.formatOptions(payeeRecipients.Hoa, "Hoa");
    let paymentTypes = [];
    paymentTypes.push(
      <option value="" key="defaultValue">
        Select...{" "}
      </option>
    );
    for (let paymentType in this.state.paymentTypes) {
      paymentTypes.push(
        <option value={paymentType} key={paymentType}>
          {this.state.paymentTypes[paymentType]}
        </option>
      );
    }

    let PayeeTypeOptions = [];
    PayeeTypeOptions.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    for (let payeeType in config.payeeTypes) {
      PayeeTypeOptions.push(
        <option value={payeeType} key={payeeType}>
          {config.payeeTypes[payeeType]}
        </option>
      );
    }
    let isDisabled =
      config["paymentRead"].includes(this.props.userRole) ||
      this.state.row.eligible_to_approve_or_reject ||
      (!this.state.row.eligible_to_approve_or_reject &&
        this.state.row.rejected_user);
    let settlementStatus = [];
    settlementStatus.push(
      <option value="" key="defaultValue">
        Select Settlement
      </option>
    );
    for (let settlement in config.payment_settlementStatus) {
      settlementStatus.push(
        <option value={settlement} key={settlement}>
          {config.payment_settlementStatus[settlement]}
        </option>
      );
    }

    let reasons = [];
    reasons.push(
      <option value="" key="defaultValue">
        Select Reasons
      </option>
    );
    for (let reason in config.payment_reason) {
      reasons.push(
        <option value={reason} key={reason}>
          {config.payment_reason[reason]}
        </option>
      );
    }

    return (
      <>
        {this.state.isLoading ? (
          <LoaderComponent />
        ) : (
          <div className="payment-section">
            <div className="payment-form">
              <div className="heading">Document/Invoice</div>
              <FormGroup row style={{ paddingLeft: "1.5%" }}>
                <label for="">Tracking Number</label>
                <div style={{ paddingLeft: "3%" }}>
                  <TextValidator
                    type="text"
                    name="usps_tracking_no"
                    id="usps_tracking_no"
                    placeholder="USPS Tracking Number"
                    onChange={this.handleChange}
                    value={this.state.row.usps_tracking_no}
                    disabled={isDisabled ? true : false}
                  />
                </div>
              </FormGroup>
              <FormGroup row>
                {/* {this.state.row.payment_approved_date && ( */}
                <>
                  <label
                    className="payment-status-label"
                    for="payment_approved_date"
                  >
                    Payment Approved Date
                  </label>
                  <div className="payment-approved-input">
                    <div>
                      {this.state.row.payment_approved_date
                        ? dayjs(this.state.row.payment_approved_date).format(
                            config.US_DATE_FORMAT
                          )
                        : ""}
                    </div>
                  </div>
                </>
                {/* )} */}
                <>
                  <label
                    className="payment-status-label"
                    for="payment_sent_date"
                  >
                    Payment Sent Date
                  </label>
                  <div className="payment-status-input">
                    <DatePicker
                      id="payment_sent_date"
                      selected={
                        this.state.row.payment_sent_date
                          ? helpers.restructureDate(
                              this.state.row.payment_sent_date
                            )
                          : null
                      }
                      onChange={(date) => {
                        this.handleDateChange("payment_sent_date", date);
                      }}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </>
                <>
                  <label for="" className="checkNumber">
                    Check Number
                  </label>
                  <div className="checkNumber-input">
                    <TextValidator
                      type="text"
                      name="check_transaction_no"
                      id="check_transaction_no"
                      placeholder="Check transaction Number"
                      onChange={this.handleChange}
                      value={this.state.row.check_transaction_no}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </>

                <>
                  <label for="settlement_date" className="settlementDate">
                    Settlement Date
                  </label>
                  <div className="payment-status-input">
                    <DatePicker
                      id="cleared_date"
                      selected={
                        this.state.row.cleared_date &&
                        this.state.row.cleared_date !== ""
                          ? helpers.restructureDate(this.state.row.cleared_date)
                          : null
                      }
                      onChange={(date) => {
                        this.handleDateChange("cleared_date", date);
                      }}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </>
                <>
                  <label for="cleared_date" className="settlementStatus">
                    Settlement Status
                  </label>
                  <div className="settlementStatus-inputField">
                    <Input
                      type="select"
                      name="settlement_status"
                      id="settlement_status"
                      onChange={this.handleChange}
                      value={this.state.row.settlement_status}
                    >
                      {settlementStatus}
                    </Input>
                  </div>
                </>
                <>
                  <label for="cleared_date" className="reason">
                    Reason
                  </label>
                  <div className="reason-inputField">
                    <Input
                      type="select"
                      name="reason"
                      id="reason"
                      onChange={this.handleChange}
                      value={this.state.row.reason}
                    >
                      {reasons}
                    </Input>
                  </div>
                </>
              </FormGroup>
              <FormGroup row style={{ paddingLeft: "1%", marginTop: "-25px" }}>
                <div>
                  <Button
                    onClick={() =>
                      this.setState({ openAddDocumentModal: true })
                    }
                  >
                    Document File Upload
                  </Button>
                </div>
                {this.state.openAddDocumentModal && (
                  <DocumentsModal
                    toggle={this.toggleDocument}
                    title="Document"
                    open={this.state.openAddDocumentModal}
                    propertyID={this.props.propertyID}
                    pageNo={1}
                    hideDocumentName={true}
                    hoaOptions={hoaOptions}
                    isPayment={true}
                    actionID={this.state.actionID}
                    fetchDocuments={this.getPropertyInfo}
                  />
                )}
                {_.isEmpty(this.state.row.documents) ? (
                  <h6 className="noDocument">No Document Uploaded</h6>
                ) : (
                  <></>
                )}
              </FormGroup>
              <FormGroup row style={{ paddingLeft: "1%", marginTop: "-50px" }}>
                <DocumentsTab
                  propertyID={this.props.propertyID}
                  documents={this.state.row.documents}
                  isPayment={true}
                  deleteDocument={this.deletePaymentDocument}
                  theme={"general-info-tab"}
                />
              </FormGroup>
              <FormGroup row className="payment-form-group">
                <div className="payment-div-inv">
                  <div className="payment-div-inv-label" for="invoice_date">
                    Payment Invoice Date
                  </div>
                  <div className="payment-div-date">
                    <DatePicker
                      id="invoice_date"
                      selected={
                        this.state.row.invoice_date
                          ? helpers.restructureDate(this.state.row.invoice_date)
                          : null
                      }
                      onChange={(date) => {
                        this.handleDateChange("invoice_date", date);
                      }}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </div>
                <div className="payment-div-rec">
                  <div
                    className="payment-div-rec-label"
                    for="invoice_received_date"
                  >
                    Invoice Received Date
                  </div>
                  <div className="payment-div-date">
                    <DatePicker
                      id="invoice_received_date"
                      selected={
                        this.state.row.invoice_received_date
                          ? helpers.restructureDate(
                              this.state.row.invoice_received_date
                            )
                          : null
                      }
                      onChange={(date) => {
                        this.handleDateChange("invoice_received_date", date);
                      }}
                      disabled={isDisabled ? true : false}
                      style={{ textAlign: "center" }}
                    />
                  </div>
                </div>
                <div className="payment-div-due">
                  <div for="due_date" className="payment-div-due-label">
                    Payment Due Date *
                  </div>
                  <div className="payment-div-date">
                    <DatePicker
                      id="due_date"
                      selected={
                        this.state.row.due_date
                          ? helpers.restructureDate(this.state.row.due_date)
                          : null
                      }
                      onChange={(date) => {
                        this.handleDateChange("due_date", date);
                      }}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </div>
                <div className="payment-div-num">
                  <div className="payment-div-num-label" for="">
                    Invoice Number
                  </div>
                  <div className="payment-div-input">
                    <TextValidator
                      type="text"
                      name="invoice_number"
                      id="invoice_number"
                      placeholder="Invoice Number"
                      onChange={this.handleChange}
                      value={this.state.row.invoice_number}
                      disabled={isDisabled ? true : false}
                    />
                  </div>
                </div>
              </FormGroup>
            </div>
          </div>
        )}
      </>
    );
  };

  renderAccountNum = () => {
    if (this.state.row.other_payee === true) {
      return (
        <div className="acc">
          <label for="">Other Payee Account Number *</label>{" "}
          <div className="text">
            <TextValidator
              type="text"
              name="other_account_number"
              id="other_account_number"
              placeholder="Other Account Number"
              onChange={this.handleChange}
              value={this.state.row.other_account_number}
            />
          </div>
        </div>
      );
    } else return null;
    // } else if (
    //   this.state.row.payee_recipient_type &&
    //   this.state.row.payee_recipient_id
    // ) {
    //   return (
    //     <div className="acc">
    //       <label for="">Account Number *</label>

    //       <div className="text">
    //         {this.payeeRecipientsAddress(
    //           this.state.row.payee_recipient_type,
    //           this.state.row.payee_recipient_id,
    //           true
    //         )}
    //       </div>
    //     </div>
    //   );
    // }
  };

  paymentInformationComponent = () => {
    let payeeRecipients = this.state.payeeRecipients;
    let hoaOptions =
      payeeRecipients && helpers.formatOptions(payeeRecipients.Hoa, "Hoa");
    let managementCompanyOptions =
      payeeRecipients &&
      helpers.formatOptions(
        payeeRecipients.ManagementCompany,
        "ManagementCompany"
      );
    let attorneyOptions =
      payeeRecipients &&
      helpers.formatOptions(payeeRecipients.Attorney, "Attorney");
    let groupedOptions = [
      {
        label: "Hoas",
        options: hoaOptions,
      },
      {
        label: "Management Companies",
        options: managementCompanyOptions,
      },
      {
        label: "Attorneys",
        options: attorneyOptions,
      },
    ];
    let paymentTypes = [];
    let i = 0;
    paymentTypes.push(
      <option value="" key="defaultValue">
        Select...{" "}
      </option>
    );
    for (let paymentType in this.state.paymentTypes) {
      paymentTypes.push(
        <option value={paymentType} key={paymentType}>
          {this.state.paymentTypes[paymentType]}
        </option>
      );
    }

    let PayeeTypeOptions = [];
    PayeeTypeOptions.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    for (let payeeType in config.payeeTypes) {
      PayeeTypeOptions.push(
        <option value={payeeType} key={payeeType}>
          {config.payeeTypes[payeeType]}
        </option>
      );
    }
    let isDisabled =
      config["paymentRead"].includes(this.props.userRole) ||
      this.state.row.eligible_to_approve_or_reject ||
      (!this.state.row.eligible_to_approve_or_reject &&
        this.state.row.rejected_user);
    var propertyInfo = this.props.dashboardProperty
      ? this.state.dasboardPropertyInfo
      : this.props.propertyInfo;
    let row = this.state.row;
    return (
      <div className="payment-documents">
        <div className="heading">Payment Information</div>
        <FormGroup className="other-acc">{this.renderAccountNum()}</FormGroup>
        <FormGroup className="payment-info">
          <div className="question">
            <div className="text">Other Payee</div>
            <div className="radio">
              <CustomInput
                id="other_payee_yes"
                type="radio"
                name="other_payee"
                value={true}
                disabled={isDisabled ? true : false}
                onClick={(e) => {
                  this.handleRadioButton(e);
                  row["payee_id"] = null;
                  this.setState({ row });
                }}
                checked={this.state.row.other_payee === true ? "checked" : ""}
              />
              <label>Yes</label>
              {/* </div>
            <div> */}
              <CustomInput
                id="other_payee_no"
                type="radio"
                name="other_payee"
                value={false}
                disabled={isDisabled ? true : false}
                onClick={(e) => {
                  this.handleRadioButton(e);
                  // row["other_payee_details"] = "";
                  this.setState({ row });
                }}
                checked={this.state.row.other_payee === false ? "checked" : ""}
              />
              <label>No</label>
            </div>
          </div>
          {!this.state.row.other_payee && (
            <div className="payee">
              <label className="">Payee *</label>
              <div className="select-box">
                <Select
                  options={groupedOptions}
                  formatGroupLabel={this.formatGroupLabel}
                  onChange={(value) => {
                    this.handleSelection("payee_id", value, "payee_type");
                  }}
                  value={this.state.row.payee_id}
                  isDisabled={isDisabled ? true : false}
                />
              </div>
            </div>
          )}

          {this.state.row.other_payee && (
            <div className="other-payee">
              <label className="">Other Payee Details *</label>
              <div className="select-box">
                <TextValidator
                  type="textarea"
                  name="other_payee_details"
                  id="other_payee_details"
                  placeholder="Details"
                  onChange={this.handleChange}
                  value={this.state.row.other_payee_details}
                  disabled={isDisabled ? true : false}
                />
              </div>
            </div>
          )}
          <div className="memo">
            <label className="memo-head">Memo</label>
            <div className="memo-details">
              {this.state.row.payee_recipient_type &&
                this.state.row.payee_recipient_id && (
                  <div>
                    {/* {this.state.row.other_payee === true ? (
                      <TextValidator
                        type="text"
                        name="other_account_number"
                        id="other_account_number"
                        placeholder="Other Account Number"
                        onChange={this.handleChange}
                        value={this.state.row.other_account_number}
                      />
                    ) : (
                      <> */}
                    {this.state.row.other_payee === true ? (
                      <></>
                    ) : (
                      <>
                        {/* {"Account Number : " + */}
                        {this.payeeRecipientsAddress(
                          this.state.row.payee_recipient_type,
                          this.state.row.payee_recipient_id,
                          true
                          //     )}{" "}
                          // </>
                        )}
                      </>
                    )}
                  </div>
                )}
              <div>
                {/* <div>Property Address : </div> */}
                <div>
                  {propertyInfo &&
                    propertyInfo.street_address +
                      ", " +
                      propertyInfo.city +
                      ", " +
                      propertyInfo.state +
                      " " +
                      propertyInfo.zip}
                </div>
              </div>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="payment-info">
          <div className="question">
            <div className="text">Other Payee Recipient</div>
            <div className="radio">
              <CustomInput
                id="other_payee_recipient_yes"
                type="radio"
                name="other_payee_recipient"
                value={true}
                disabled={isDisabled ? true : false}
                onClick={(e) => {
                  this.handleRadioButton(e);
                  row["payee_recipient_id"] = null;
                  this.setState({ row });
                }}
                checked={
                  this.state.row.other_payee_recipient === true ? "checked" : ""
                }
              />
              <label>Yes</label>
              <CustomInput
                id="other_payee_recipient_no"
                type="radio"
                name="other_payee_recipient"
                value={false}
                disabled={isDisabled ? true : false}
                onClick={(e) => {
                  this.handleRadioButton(e);
                  row["other_payee_recipient_details"] = "";
                  this.setState({ row });
                }}
                checked={
                  this.state.row.other_payee_recipient === false
                    ? "checked"
                    : ""
                }
              />
              <label>No</label>
            </div>
          </div>
          {!this.state.row.other_payee_recipient && (
            <div className="payee">
              <label className="otherPayeeRecipientDetails">
                Payee Recipient*
              </label>
              <div className="select-box">
                <Select
                  options={groupedOptions}
                  formatGroupLabel={this.formatGroupLabel}
                  onChange={(value) => {
                    this.handleSelection(
                      "payee_recipient_id",
                      value,
                      "payee_recipient_type"
                    );
                  }}
                  value={this.state.row.payee_recipient_id}
                  isDisabled={isDisabled ? true : false}
                />
              </div>
            </div>
          )}

          {this.state.row.other_payee_recipient && (
            <div className="other-payee">
              <label className="otherPayeeRecipientDetails">
                Other Payee Recipient Details *
              </label>
              <div className="select-box">
                <TextValidator
                  type="textarea"
                  name="other_payee_recipient_details"
                  id="other_payee_recipient_details"
                  placeholder="Details"
                  onChange={this.handleChange}
                  value={this.state.row.other_payee_recipient_details}
                  disabled={isDisabled ? true : false}
                />
              </div>
            </div>
          )}
          {this.state.row.payee_recipient_type &&
            this.state.row.payee_recipient_id && (
              <div className="memo">
                <label className="memo-head">
                  Payee Recipient Mailing Address
                </label>
                <div className="memo-details">
                  {this.payeeRecipientsAddress(
                    this.state.row.payee_recipient_type,
                    this.state.row.payee_recipient_id
                  )}
                  <div className="memo-edit-icon">
                    <FaEdit
                      style={{ marginRight: "4%" }}
                      size="1.5em"
                      onClick={() => this.toggleAddressModal()}
                    />
                  </div>
                </div>
              </div>
            )}
        </FormGroup>
        <FormGroup row style={{ marginTop: "5%" }}>
          {!isDisabled && (
            <Button
              disabled={this.saveDisabled()}
              style={{
                position: "absolute",
                left: "50%",
                marginleft: "50%",
                bottom: "10px",
              }}
              onClick={this.handleSubmit}
            >
              Save Payment
            </Button>
          )}
        </FormGroup>
      </div>
    );
  };

  handledocumentUpload = (event) => {
    let value = event.target.value;
    if (typeof value === "string") {
      value = helpers.getBoolean(value);
    }
    this.setState({
      [event.target.name]: value,
    });
  };

  formElements = () => {
    var isHideCharge = config["chargesHide"].includes(this.props.userRole);
    let listofTabs = [];
    let tabsComponentsList = null;
    if (isHideCharge) {
      listofTabs = ["Payments"];
      tabsComponentsList = new Array(listofTabs.length).fill(null);
      // tabsComponentsList[0] = this.paymentsDataComponent();
    } else {
      listofTabs = ["Payments", "Charges"];
      tabsComponentsList = new Array(listofTabs.length).fill(null);
      //tabsComponentsList[0] = this.paymentsDataComponent();
      tabsComponentsList[1] = (
        <Charges
          payment={this.state.row}
          propertyID={this.state.propertyID}
          actionID={this.state.actionID}
          paymentHoas={this.state.paymentHoas}
          handleChange={this.handleChange}
          handleDate={this.handleDateChange}
          userRole={this.props.userRole}
          handleSelection={this.handleSelection}
          handleRadioButton={this.handleRadioButton}
          submitPayment={this.handleSubmit}
          refershActions={this.state.refershActions}
          refreshPayment={this.fetchPayment}
          toggleModal={this.toggleModal}
          hoa_id={this.state.row.hoa_id}
          assessments={this.state.assessments}
        />
      );
    }
    return (
      <>
        {this.state.isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            {/* {this.paymentsDataComponent()} */}
            {this.documentInvoiceComponent()}
            <hr></hr>
            <Charges
              payment={this.state.row}
              propertyID={this.state.propertyID}
              actionID={this.state.actionID}
              paymentHoas={this.state.paymentHoas}
              handleChange={this.handleChange}
              handleDate={this.handleDateChange}
              userRole={this.props.userRole}
              handleSelection={this.handleSelection}
              handleRadioButton={this.handleRadioButton}
              submitPayment={this.handleSubmit}
              refershActions={this.state.refershActions}
              refreshPayment={this.fetchPayment}
              toggleModal={this.toggleModal}
              hoa_id={this.state.row.hoa_id}
              assessments={this.state.assessments}
            />{" "}
            <hr></hr>
            {this.paymentInformationComponent()}
            {/* <TabsComponent theme="paymentModal" components={tabsComponentsList}
                    listofTabs={listofTabs} generateTabURL={false} /> */}
          </>
        )}
      </>
    );
  };

  saveDisabled = () => {
    return helpers.isNull(this.state.row.due_date);
  };

  render() {
    return (
      <div>
        <Modal
          className={"area-analyst-form-modal " + this.state.className}
          isOpen={this.state.open}
          toggle={this.toggleModal}
        >
          {this.renderModal()}
        </Modal>

        {this.state.addressModal && (
          <EditAddressModal
            toggle={this.toggleAddressModal.bind(this)}
            title="Hello"
            open={this.state.addressModal}
            propertyID={this.state.propertyID}
            updateAddress={this.updateAddress}
            payee_recipient={this.state.row.payee_recipient_id}
            getAddressAndAccount={this.getAddressAndAccount}
            payee_recipient_type={this.state.row.payee_recipient_type}
          />
        )}
      </div>
    );
  }
}
