import apicall from "../../utils/apicall";

const options = [];
// for (let i = 0; i < 50; ++i) {
//   options.push({
//     value: i + 1,
//     label: `Option ${i + 1}`
//   });
// }

const sleep = ms =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

const optionsPerPage = 10;
var total

const loadOptions = async (search, page) => {
  await sleep(1000);

  let filteredOptions;
  if (!search) {
    filteredOptions = options;
  } else {
    // const searchLower = search.toLowerCase();
    await apicall.searchManagementCompanies(search,null,page,25,true).then(
      resp => {
        total = resp.total;
        filteredOptions = resp.results.map((mc) => { 
                  return {value:mc.id,label: mc.name || '' , point_of_contacts : mc.point_of_contacts}
                
          })
      }
    )
    // filteredOptions = options.filter(({ label }) =>
    //   label.toLowerCase().includes(searchLower)
    // );
  }

  const hasMore = Math.ceil(total / optionsPerPage) > page;
  console.log('hasmore',hasMore,'filteredOptions',filteredOptions)
  // const hasMore = true;
  // const slicedOptions = filteredOptions.slice(
  //   (page - 1) * optionsPerPage,
  //   page * optionsPerPage
  // );
  const slicedOptions = [...filteredOptions]
    console.log('slicedOptions',slicedOptions)
  return {
    options: slicedOptions,
    hasMore
  };
};

export default loadOptions;

