import React, { Component } from "react"
import "../../styles/_general.scss"
import TabsComponent from "../helper_components/TabsComponent"
import ActionStatus from "./ActionStatus"
import "../../styles/_tab.scss"
import NewDashboard from './NewDashboard';
export default class DashboardContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 1,
        }
    }

    render() {
        // let listofTabs = ["New Dasboard","Dashboard", "Action Status"]
        // let tabsComponentsList = new Array(listofTabs.length).fill(null)
        // tabsComponentsList[1] = <Dashboard />
        // tabsComponentsList[2] = <ActionStatus />
        // tabsComponentsList[0] = <NewDashboard/>
         return (
            <React.Fragment>
                <section className="content">
                    {/* <TabsComponent
                        theme="tab"
                        components={tabsComponentsList}
                        listofTabs={listofTabs}
                        generateTabURL={false}
                        activeTab={this.state.activeTab}
                    /> */}
                    <NewDashboard/>
                </section>
            </React.Fragment>
        )
    }
}
