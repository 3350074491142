export default {
	"AssociationVerification" : "#1f77b4",
	"DocumentInfoReport":"#ff7f0e",
	"General":"#2ca02c",
	"Inspection":"#d62728",
	"Lien":"#9467bd",
	"Payment":"#8c564b",
	"Pir":"#e377c2",
	"Violation":"#7f7f7f",
	"AssociationInformation":"#bcbd22",
}
