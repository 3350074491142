import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import '../../styles/navbar.css';
import '../../styles/content.css';
import ListTable from './ListTable';
import apicall from "../../utils/apicall";
import LoaderComponent from '../helper_components/LoaderComponent'
import config from '../../utils/config';
export default class ManagmentCompaniesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            managementCompanies: [],
            isLoading: true,
            searchBoxText: '',
            pageNo: 1,
            userRole: '',
        }
    }

    componentWillMount() {
        config.localforage.getItem('user_role').then(role => {
            this.setState({ userRole: role })
        }).catch(e => console.log(e))
        this.getList(1);
    }

    getList = (event, pageNo, perPage) => {
        console.log("getlist",perPage)
        apicall.getManagementCompanies(pageNo, perPage).then(
            response => {
                this.setState({
                    managementCompanies: response.results,
                    isLoading: false,
                    totalCount: response.total,
                    pageNo,
                    perPage
                })
            }
        );
    }

    handleChange = (event) => {
        this.setState({
            searchBoxText: event.target.value
        });
    }

    handleSubmitForSearch = (event,role, pageNo, perPage) => {
        console.log('submit',perPage)
        this.setState({
            pageNo: (typeof pageNo !== "undefined") ? pageNo : "1",
            perPage: (typeof pageNo !== "undefined") ? perPage : "10"
        });

        let inputValue = this.state.searchBoxText
        apicall.searchManagementCompanies(inputValue,null, pageNo, perPage).then(
            response => {
                this.setState({
                    managementCompanies: response.results,
                    isLoading: false,
                    totalCount: response.total,
                    pageNo
                })
            }
        )
        event.preventDefault();
    }

    handleNextPrviousAction = (event, pageNo, perPage) => {
        if (this.state.searchBoxText == '') {
            this.getList(event, pageNo, perPage)
        } else {
            this.handleSubmitForSearch(event,null, pageNo, perPage)
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isLoading ? <LoaderComponent /> :
                    (
                        <section className="content">
                            <header className="content-title ">
                                {/* <div className='page-title'>Management Companies</div> */}
                                <h2 className='page-heading'><strong>Management Companies</strong></h2>
                                <Form onSubmit={this.handleSubmitForSearch}>
                                    <FormGroup row>
                                        <Col sm={8}>
                                            <Input type="address" name="address" id="companiesSearch" placeholder="Management Companies Search" onChange={this.handleChange} />
                                        </Col>
                                        <Col sm={2}>
                                            <Button className='search-btn'>Search</Button>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </header>
                            <ListTable managementCompanies={this.state.managementCompanies} refreshList={this.handleNextPrviousAction} 
                            pageNo={this.state.pageNo} perPage={this.state.perPage} totalCount={this.state.totalCount} userRole={this.state.userRole}/>
                        </section>
                    )}
            </React.Fragment>
        )
    }
}