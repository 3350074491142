import config from '../config';
import { withRouter } from 'react-router';
import helpers from '../helpers';
var inspectioncategoryapi = {
    getInspectionCategory: async function (propertyID) {
        let url = config.baseUrl + "/api/v1/inspection_categories/category_types?property_id=" + propertyID;
        let inspection_categories = null;
        let init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getting inspection category' + response)
            }).then(responseJson => {
                inspection_categories = responseJson;
            }).catch(function (error) {

            })
        return inspection_categories;
    },
    createInspectionCategory: async function (propertyID, inspection_category) {
        var res = '';
        let url = config.baseUrl + '/api/v1/inspection_categories?property_id=' + propertyID;
        var init = {
            method: "POST",
            headers: await helpers.defaultHeaders(),
            body: JSON.stringify({ inspection_category: inspection_category })
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while creating inspection category' + response)
            }).then(a => {
                res = a;
            }).catch(function (error) {

            })
        return res;
    },
    listInspectionCategories: async function (propertyID) {
        var inspection_categories = [];
        let url = config.baseUrl + '/api/v1/inspection_categories?property_id=' + propertyID;
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getting inspection_categories' + response)
            }).then(resp => {
                inspection_categories = resp;
            }).catch(function (error) {

            })
        return inspection_categories;
    },
    updateInspectionCategory: async function (propertyID, inspectionid, inspectionIntervalMap) {
        var res = '';
        let url = config.baseUrl + '/api/v1/inspection_categories/' + inspectionid + "?property_id=" + propertyID;
        var init = {
            method: "PUT",
            headers: await helpers.defaultHeaders(),
            body: JSON.stringify({ inspection_category: inspectionIntervalMap })
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while inspection category update' + response)
            }).then(a => {
                res = a;
            }).catch(function (error) {

            })
        return res;
    },
    getIntervals: async function (propertyID) {
        var intervals = [];
        let url = config.baseUrl + '/api/v1/inspection_categories/category_intervals?property_id=' + propertyID;
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getting intervals' + response)
            }).then(resp => {
                intervals = resp;
            }).catch(function (error) {

            })
        return intervals;
    },
    deleteInspectionCategory: async function (inspectionid, propertyid) {
        var res = '';
        let url = config.baseUrl + '/api/v1/inspection_categories/' + inspectionid + "?property_id=" + propertyid;
        var init = {
            method: "DELETE",
            headers: await helpers.defaultHeaders()
        };

        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while deleting inspection category' + response)
            }).then(a => {
                res = a;
            }).catch(function (error) {

            })
        return res;
    },
}
export default withRouter(inspectioncategoryapi);