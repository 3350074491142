import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Col, Input } from 'reactstrap';
import apicall from '../utils/apicall';
import TextValidator from './helper_components/TextValidator';
import { ValidatorForm, } from 'react-form-validator-core';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import helpers from '../utils/helpers';
import _ from "lodash"
import config from '../utils/config'

class ActionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentTypes: {},
            actionModal: false,
            actionId: '',
            actionTypes: [],
            description: '',
            creationDateAge: '',
            noteActionsNextSteps: '',
            actionable_type: '',
            current_status: '',
            notes: '',
            due_date: '',
            payment_type: '',
            paymentHoas: [],
            hoa_id: null,
            hideSubmit: false
        }
        this.state = {
            ...this.state,
            ...this.props,
            ...this.props.action
        };
    }

    componentWillMount() {
        apicall.fetchActionTypes().then((actionTypes) => {

            this.setState({
                actionTypes: actionTypes.types
            })
        })

        apicall.fetchPaymentTypes(this.props.propertyID).then(
            response => {
                this.setState({
                    paymentTypes: response
                })
            }
        )
        apicall.fetchPaymentHoas(this.props.propertyID).then(
            response => {
                this.setState({
                    paymentHoas: response
                })
            }
        )

    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            this.setState({
                ...this.state,
                ...newprops,
            });
            if (newprops.updateModal) {
                if (typeof newprops.action !== "undefined" && typeof newprops.action.current_status !== "undefined") {
                    this.setState({
                        current_status: newprops.action.current_status,
                        notes: newprops.action.notes
                    })
                }
            }
        }
    }

    handleDateChange = (value) => {

        this.setState({
            due_date: value
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    notify = (errorMsg) => toast(errorMsg);

    handleSubmit = (event) => {
        let actions = {};
        if (!this.state.updateModal) {
            actions["actionable_type"] = this.state.actionable_type;
            actions["description"] = this.state.actionable_type === 'Payment' ? this.state.payment_type : this.state.description;
            actions["creation_date"] = new Date();
            actions["current_status"] = "Pending";
            actions["due_date"] = helpers.formatApiDate(this.state.due_date);
            actions["payment_type"] = this.state.payment_type
            actions["hoa_id"] = this.state.hoa_id
            apicall.actionCreate(actions, this.state.propertyID)
                .then((actionCreate) => {
                    this.notify(actionCreate.actionable_type + " created successfully");
                    this.resetStateValues()
                    this.props.newActionCreated(actionCreate)
                }).catch(e => console.log(e))
        }
        else {
            actions["current_status"] = this.state.current_status;
            actions["notes"] = this.state.notes;
            apicall.updateActions(this.state.propertyID, this.state.action.id, actions)
                .then((actionUpdate) => {
                    this.notify(actionUpdate.description + " updated successfully");
                    this.resetStateValues()
                }).catch(e => console.log(e))
        }
        setTimeout(() => {
            this.props.getActions(this.state.propertyID);
        }, 3000);
        this.props.toggle();
        event.preventDefault();
    }

    resetStateValues = () => {
        this.setState({
            actionable_type: '',
            description: '',
            due_date: ''
        })
    }

    toggleSubmit = () => {
        if(config.paymentHoaAction.includes(this.state.payment_type) && _.isEmpty(this.state.hoa_id))
        return true
        else
        return false
    }


    renderModal = () => {

        let typeActions = [];
        let i = 0;
        let statuses = []

        if (this.props.action && this.props.action.actionable_type === "AssociationVerification") {
            const arr = ["Pending – RESICAP", "Pending – Vendor", "HOA Exists", "NO HOA Exists", "Verifying HOA Exists", "Verifying NO HOA Exists"]
            statuses = arr.map(x => <option value={x} disabled={x==='HOA Exists' ? true: false}>{x}</option>)
        } else {
            statuses = ["Closed", "Pending"].map(x => <option value={x} >{x}</option>)
        }

        typeActions.push(<option value="">Select Type</option>)
        for (let type in this.state.actionTypes) {
            typeActions.push(<option value={type} >{this.state.actionTypes[type]}</option>)
        }
        let paymentTypes = [];
        i = 0;
        paymentTypes.push(<option value="" key="defaultValue">Select... </option>)
        for (let paymentType in this.state.paymentTypes) {
            paymentTypes.push(<option value={paymentType} key={paymentType}>{this.state.paymentTypes[paymentType]}</option>)
        }
        let paymentHoas = [];
        paymentHoas.push(<option value="" key="defaulthoaValue">Select Payment Hoa</option>)
        for (let hoa of this.state.paymentHoas) {
            paymentHoas.push(<option value={hoa.id} key={hoa.id}>{hoa.name}</option>)
        }

        return (
            <div>
                <ModalHeader toggle={this.props.toggle}>
                    <div className="">
                        <span className="heading">
                            Create Action
                        </span>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <ValidatorForm onSubmit={this.handleSubmit} autocomplete="off">

                        {!this.props.updateModal &&
                            <>
                                <FormGroup>
                                    <Label for="">Action Type</Label>
                                    <Input type="select" name="actionable_type" id="actionable_type" value={this.state.actionable_type} onChange={this.handleChange}>
                                        {typeActions}
                                    </Input>
                                </FormGroup>
                                {this.state.actionable_type == 'Payment' && 
                                <>
                                <FormGroup>
                                    <Label for="">Description</Label>
                                    <Input type="select" name="payment_type" id="payment_type" onChange={this.handleChange} required>
                                        {paymentTypes}
                                    </Input>
                                </FormGroup>
                                {
                                    config.paymentHoaAction.includes(this.state.payment_type) &&
                                    <FormGroup className="">

                                    <Label>Hoa *</Label>
                                    <Input type="select" name="hoa_id" id="hoa_id" value={this.state.hoa_id}
                                        onChange={this.handleChange}>
                                        {paymentHoas}
                                    </Input>
                                    </FormGroup>
                                }
                                
                             </>
                            }
                                {
                                    this.state.actionable_type !== 'Payment' &&
                                    <>
                                        <FormGroup>
                                            <Label for="">Description</Label>
                                            <TextValidator type="text" name="description" id="description" placeholder="Description" onChange={this.handleChange}
                                                value={this.state.description} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label style={{ marginRight: "2%" }} for="due_date">Due Date</Label>
                                            <DatePicker id="due_date" selected={this.state.due_date}
                                                onChange={this.handleDateChange} value={this.state.due_date} />
                                        </FormGroup>
                                    </>
                                }
                            </>}
                        {this.props.updateModal &&
                            <>
                                <FormGroup>
                                    <Label for="">Status</Label>
                                    <Input type="select" name="current_status" id="current_status" value={this.state.current_status} onChange={this.handleChange}>
                                        {statuses}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="">Notes</Label>
                                    <Input style={{height:'150px'}} type="textarea" name="notes" id="notes" placeholder="Notes" onChange={this.handleChange}
                                        value={this.state.notes} />

                                </FormGroup>
                            </>}
                        <Button disabled={this.toggleSubmit()}>Submit</Button>
                    </ValidatorForm>
                </ModalBody>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Modal
                    className="actionModal"
                    isOpen={this.state.modal}
                    toggle={this.props.toggle}
                >
                    {this.renderModal()}
                </Modal>
            </div>
        );
    }
}

export default ActionModal;
