import React, { Component, useState, useEffect } from "react";
import '../../styles/navbar.css';
import '../../styles/content.css'
import { Button ,Col,Row} from 'reactstrap';
import TabsComponent from "../helper_components/TabsComponent"
import "../../styles/_tab.scss"
import apicall from '../../utils/apicall'
import config from '../../utils/config'
import DocumentsTab from "./DocumentsTab"
import DocumentsModal from './DocumentsModal';
import _ from 'lodash'
import HoaAsyncSelect from '../helper_components/HoaAsyncSelect'
import AsyncMultiSelect from '../general_info/AsyncMultiSelect'
import Select from 'react-select'
export default (props) => {
	const [documents, set_Documents] = useState([])
	const [activeTab, set_ActiveTab] = useState(1)
	const [openAddDocumentModal, set_openAddDocumentModal] = useState(false)
	const [hoaOptions, set_HOA] = useState()
	const [hoaID, set_HOA_ID] = useState(props.hoaID)

	useEffect(() => {
		fetchDocuments()
	}, [hoaID])

	useEffect(()=>{
		fetchHoas()
	},[])

	const fetchHoas = () => {
		apicall.getHoaByPropertyID(props.propertyID,1,20).then(
			(response) => {
				let hoaOptions = []
				hoaOptions = response.results.map(
					x => {
						return {label: x.name, value:x.id}
					}
				)
				set_HOA(hoaOptions)
				console.log(hoaOptions)
			}
		)
	}

	const fetchDocuments = () => {
		apicall.propertyDocumentsFetch(props.propertyID,hoaID)
			.then((resp) => { set_Documents(resp.results) })
			.catch(e => console.log(e))
	}

	const requiredDocsNames = _.without(config.documentNames, 'Others').map(x => x.toLowerCase())

	// if(_.isEmpty(documents))
	// 	return <h4>No Documents</h4>

	const requiredDocs = !_.isEmpty(documents) ? documents.filter(x => _.includes(requiredDocsNames, x.name && x.name.toLowerCase())) : []
	const miscDocs = !_.isEmpty(documents) ? documents.filter(x => !_.includes(requiredDocsNames, x.name && x.name.toLowerCase())) : []

	const listofTabs = ["Required", "Misc"]
	const tabsComponentsList = [<DocumentsTab propertyID={props.propertyID} documents={requiredDocs} fetchDocuments={fetchDocuments} required={true} />,
	<DocumentsTab propertyID={props.propertyID} documents={miscDocs} fetchDocuments={fetchDocuments} />]
	console.log("hoaaaaaaaaa",hoaID)
	return (
		<div>
			<Row>
				<Col xs="6" >
					{/* <AsyncMultiSelect isHoa={true} propertyid={props.propertyID} documentFilter={true}
						handleOnChange = {
							(value) => {
								set_HOA(value)
								let hoaIDs = [];
								value.map(
									(hoa,index) => {
										hoaIDs.push(hoa.value)
									}
								)
								set_HOA_ID(hoaIDs)
							}
						}
					/> */}
					 <Select 
					  options={hoaOptions ? hoaOptions: []}
					  isMulti={true}
					  onChange = {
						  (value) => {
							let hoaIDs = [];
							value.map(
								(hoa,index) => {
									hoaIDs.push(hoa.value)
								}
							)
							set_HOA_ID(hoaIDs)
						  }
					  }
					  placeholder="Select Hoa..."
					  className="states-select"
					  />
				</Col>
			</Row>
			<div style={{ "float": "right", "margin-right": "30px", "margin-left": "30px" }}>
				<Button onClick={() => set_openAddDocumentModal(true)}>Add</Button>
			</div>
			<TabsComponent
				theme={'general-info-tab'}
				components={tabsComponentsList}
				listofTabs={listofTabs}
				generateTabURL={false}
				activeTab={activeTab}
			/>
			{openAddDocumentModal && <DocumentsModal toggle={() => set_openAddDocumentModal(false)} title="Document"
				open={openAddDocumentModal} fetchDocuments={fetchDocuments} propertyID={props.propertyID} pageNo={1} hideDocumentName={true} 
				hoaOptions={hoaOptions}/>}
		</div>

	)
}
