import React, { Component, useState, useEffect } from "react"
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Table, Label, Form, Input, CustomInput, Col, Row } from 'reactstrap';
import apicall from "../../utils/apicall"
import Loader from "../helper_components/LoaderComponent"
import { FaEdit } from "react-icons/fa";
import PaginationComponent from "../helper_components/PaginationComponent"
import _ from "lodash"
import dayjs from "dayjs"
import config from '../../utils/config'

export default props => {
	const [state, set_state] = useState({notes: []})
	const [loading, set_loading] = useState(false)

	useEffect(() => {
		getNotes({}, 1)
	}, [])

	const getNotes = (event, pageNo) => {
		set_loading(true)
		apicall
			.getNotes(props.propertyID, pageNo)
			.then(response => {
				set_state({...state,
					notes: response.results.map(x => {
						return {...x.user, ...x}
					}),
					total: response.total,
					pageNo})
				set_loading(false)
			})
			.catch(e => set_loading(false))
	}

    const timestampFormatter = (cell, row, rowIndex, formatExtraData) =>
    	dayjs(row.created_at).format(config.US_DATE_TIME_FORMAT)

    const descFormatter = (cell, row, rowIndex, formatExtraData) =>
    	<div style={{"white-space": "pre-wrap"}}>{cell}</div>

    const editFormatter = (cell, row, rowIndex, formatExtraData) => {
    	    return (
    	        <div
    	            style={{
    	                textAlign: "center",
    	                cursor: "pointer",
    	                lineHeight: "normal"
    	            }}>
    	            <FaEdit style={{ marginRight: "4%" }} size="1.5em" onClick={() => {
    	                set_state({...state, currentNote: row, noteModal: true})
    	            }} />

    	        </div>
    	    );
    	}

    const columns = [{
        dataField: 'description',
        text: 'Note',
        formatter: descFormatter
    }, {
        dataField: 'user.name',
        text: 'User'
    },
    {
        dataField: 'created_at',
        text: 'Timestamp',
        formatter: timestampFormatter
    },
    {
        dataField: 'description',
        text: '',
        formatter: editFormatter
    }]


	return (
		<React.Fragment>
			{loading && <Loader/>}
			<Button onClick={() => set_state({...state, currentNote: null, noteModal: true})}>Create Note</Button>
	        {state.notes.length == 0 ? <h2>No Notes</h2> : <PaginationComponent columns={columns} rows={state.notes}
	            handleSubmit={getNotes} pageNo={state.pageNo} totalCount={state.total}/>}
			{state.noteModal &&
				<NoteModal
					toggle={() => set_state({...state, currentNote: null, noteModal: false}) }
					set_loading={set_loading}
					set_state={(data) => set_state({...state, ...data})}
					propertyID={props.propertyID}
					currentNote={state.currentNote}
				/>}
        </React.Fragment>
	)
}


const NoteModal = (props) => {
	const [description, set_description] = useState(props.currentNote && props.currentNote.description)

	const createNote = () => {
		props.set_loading(true)
		apicall
			.createNote(props.propertyID, description)
			.then(response => {
				props.set_state({notes: response.results,
									total: response.total,
									pageNo: 1,
									noteModal: false})
				props.set_loading(false)
			})
			.catch(e => {
				props.set_loading(false)
				props.set_state({noteModal: false})
			})
	}
	return (
		<Modal isOpen={true} toggle={props.toggle}>
			<ModalHeader>Note</ModalHeader>
			<ModalBody>
				<Form>
					<FormGroup>
						<label>Note: </label>
						<Input
							id="new-note"
							name='new-note'
							type="textarea"
							rows="10"
							value={description}
							onChange={(e) => set_description(e.target.value) } />
					</FormGroup>
					<Button onClick={createNote}>Submit</Button>
				</Form>
			</ModalBody>
	   	</Modal>)
}
