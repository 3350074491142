import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import PaginationComponent from '../helper_components/PaginationComponent';
import helpers from '../../utils/helpers';
export default class PropertyListTable extends Component {
    constructor(props) {
        super(props);
    }

    formatDate = (cell) => {
        return helpers.formatDate(cell)
    }


    render() {
        let properties = this.props.properties;

        const columns = [
            {
                dataField: 'transfer_date',
                text: 'Transfer Date',
                formatter: this.formatDate
            },
            {
                dataField: 'clientid',
                text: 'Client ID'
            },
            {
                dataField: 'entity_name',
                text: 'Entity Name'
            }, {
                dataField: 'property_group',
                text: 'Property Group'
            },
            {
                dataField: 'status',
                text: 'Asset Status'
            },
            {
                dataField: 'street_address',
                text: 'Street Address'
            },
            {
                dataField: 'city',
                text: 'City'
            },
            {
                dataField: 'state',
                text: 'State'
            },
            {
                dataField: 'zip',
                text: 'Zip'
            },
            {
                dataField: 'county',
                text: 'County'
            },
            {
                dataField: 'acquisition_type',
                text: 'Acquisition Type'
            }, {
                dataField: 'acquisition_date',
                text: 'Acquisition Date',
                formatter: this.formatDate
            },

            {
                dataField: 'mortgage_amount',
                text: 'Mortgage Amount'
            },

        ];

        return (
            <PaginationComponent columns={columns} rows={properties}
                handleSubmit={this.props.handleSubmit} pageNo={this.props.pageNo}
                totalCount={this.props.totalCount} />
        )
    }
}



