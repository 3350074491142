import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import '../../styles/navbar.css';
import '../../styles/content.css';
import ListTable from './ListTable';
import apicall from "../../utils/apicall";
import Loader from '../helper_components/LoaderComponent'
import LoaderComponent from "../helper_components/LoaderComponent";
import config from '../../utils/config'
export default class HoasComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoas: [],
            pageNo: 1,
            isLoading: false,
            hoaSearchText: '',
            userRole: '',
            hideAdd: false
        }
        config.localforage.getItem('user_role').then(role => {
            this.setState({ userRole: role })
        }).catch(e => console.log(e))
    }

    componentWillMount() {
        this.getList();
    }

    getList = (event, pageNo, perPage) => {
        this.setState({
            pageNo
        })
        apicall.getHoas(pageNo, perPage).then(
            response => {
                this.setState({
                    hoas: response.results,
                    isLoading: false,
                    totalCount: response.total
                })
            }
        );
    }

    handleChange = (event) => {
        this.setState({
            hoaSearchText: event.target.value
        });
    }

    handleSubmitForSearch = (event, pageNo, perPage) => {
        this.setState({
            pageNo: (typeof pageNo !== "undefined") ? pageNo : "1",
            perPage:(typeof pageNo !== "undefined") ? perPage : "10"
        });

        let inputValue = this.state.hoaSearchText;
        if (typeof inputValue === "undefined" || inputValue.length === 0) {
            this.getList(event, pageNo, perPage);
        }
        else {
            apicall.searchHoa(inputValue, pageNo, false, perPage).then(
                response => {
                    this.setState({
                        hoas: response.results,
                        isLoading: false,
                        totalCount: response.total,
                    })
                }
            )
        }
        if (event) {
            event.preventDefault();
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isLoading ? (<Loader />) :
                    (
                        <section className="content">
                            <header className="content-title">
                                <h2 className='page-heading'><strong>HOA</strong></h2>
                                <Form onSubmit={this.handleSubmitForSearch}>
                                    <FormGroup row>
                                        <Col sm={8}>
                                            <Input type="hoaSearch" name="hoaSearch" id="hoaSearch" placeholder="HOA Search" onChange={this.handleChange} />
                                        </Col>
                                        <Col sm={2}>
                                            <Button className='search-btn'>Search</Button>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </header>

                            <ListTable data={this.state.hoas} refreshList={this.handleSubmitForSearch}
                                pageNo={this.state.pageNo} perPage={this.state.perPage} totalCount={this.state.totalCount} userRole={this.state.userRole}
                                hideAdd={
                                    !config['hoa_mc_access'].includes(this.state.userRole) ? true : false
                                } />
                        </section>
                    )}
            </React.Fragment>
        )
    }
}