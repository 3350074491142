import FormComp from "../crud/FormComp";
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import TextValidator from '../helper_components/TextValidator';
import apicall from "../../utils/apicall";
import TabsComponent from "../helper_components/TabsComponent";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import config from '../../utils/config'
import helpers from '../../utils/helpers'
export default class ViolationModal extends FormComp {
    componentWillMount() {
        this.fetchViolation()
    }

    fetchViolation = () => {
        this.setState({isLoading:true})
        apicall.fetchActionViolation(this.props.actionID, this.props.propertyID).then(
            response => {
                this.setState({
                    row: response,
                    isLoading: false
                })
            }
        )
    }

    deleteDocument = (documentId) => {
        this.setState({ isLoading: true })
        apicall.deleteViolationDoc(this.props.propertyID, this.state.row.id, documentId).then(
            response => {
                helpers.notify("Document deleted successfully")
                this.fetchViolation()
                this.setState({ isLoading: false })
            }
        )
    }

    handleSubmit = () => {
        this.setState({isLoading : true})
        apicall.violationUpdate(this.state.row, this.state.propertyID, this.state.actionID).
            then(
                response => {
                    if (response) {
                        this.notify("Violation Updated Successfully.");
                        this.setState({
                            row: response,
                            isLoading: false
                        })
                        this.props.refershActions(this.state.propertyID)
                    }
                }
            );
    }


    lienDataComponent = () => {
        let dropdownOptions = [];
        dropdownOptions.push(<option value="" key="defaultValue">Select</option>)
        for (let period in config.periodicity) {
            dropdownOptions.push(<option value={config.periodicity[period]} key={period}>{period}</option>)
        }
        const row = this.state.row
        console.log(this.state.isLoading)
        return (
            <>
                <FormGroup>
                    <Label for="">Description *</Label>
                    <TextValidator type="text" name="description" id="description"
                        placeholder="Description" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Description is required']}
                        value={this.state.row.description} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Violation Type *</Label>
                    <TextValidator type="text" name="violation_type" id="violation_type"
                        placeholder="Violation Type" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Violation type is required']}
                        value={this.state.row.violation_type} />
                </FormGroup>
                <FormGroup>
                    <Label style={{ marginRight: "2%" }} for="date_recorded">Violation Date Recorded</Label>
                    <DatePicker id="date_recorded" selected={row.date_recorded ? helpers.restructureDate(row.date_recorded) : null}
                        onChange={(date) => {
                            this.handleDateChange("date_recorded", date)
                        }} />
                </FormGroup>
                <FormGroup>
                    <Label style={{ marginRight: "2%" }} for="received_date">Violation Received Date </Label>
                    <DatePicker id="received_date" selected={row.received_date ? helpers.restructureDate(row.received_date) : null}
                        onChange={(date) => {
                            this.handleDateChange("received_date", date)
                        }} />
                </FormGroup>
                <FormGroup>
                    <Label style={{ marginRight: "2%" }} for="received_date">Violation Correction By Date </Label>
                    <DatePicker id="correction_by_date" selected={row.correction_by_date ? helpers.restructureDate(row.correction_by_date) : null}
                        onChange={(date) => {
                            this.handleDateChange("correction_by_date", date)
                        }} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Responsibility *</Label>
                    <TextValidator type="text" name="responsibility" id="responsibility"
                        placeholder="Responsibility" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Responsibility is required']}
                        value={this.state.row.responsibility} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Respond By</Label>
                    <TextValidator type="text" name="respond_by" id="respond_by"
                        placeholder="Respond By" onChange={this.handleChange}
                        value={this.state.row.respond_by} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Fine Periodicity</Label>
                </FormGroup>
                <FormGroup>
                    <Input type="select" name='fine_periodicity' id='fine_periodicity' value={this.state.row.fine_periodicity}
                        onChange={this.handleChange} required>
                        {dropdownOptions}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="">Fine Amount *</Label>
                    <TextValidator type="number" name="fine_amount" id="fine_amount"
                        placeholder="Fine Amount" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Fine Amount is required']}
                        value={this.state.row.fine_amount} />
                </FormGroup>
                <FormGroup>
                    <Label style={{ marginRight: "2%" }} for="received_date">Hbmm Date </Label>
                    <DatePicker id="entered_in_hbmm_date" selected={row.entered_in_hbmm_date ? helpers.restructureDate(row.entered_in_hbmm_date):null}
                        onChange={(date) => {
                            this.handleDateChange("entered_in_hbmm_date", date)
                        }} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Payment Required *</Label>
                    <TextValidator type="text" name="payment_required" id="payment_required"
                        placeholder="payment_required" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Payment Required is required']}
                        value={this.state.row.payment_required} />
                </FormGroup>
                <FormGroup>
                    <Label style={{ marginRight: "2%" }} for="cleared_date">Cleared Date </Label>
                    <DatePicker id="cleared_date" selected={row.cleared_date && new Date(row.cleared_date)}
                        onChange={(date) => {
                            this.handleDateChange("cleared_date", date)
                        }} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Notes *</Label>
                    <TextValidator type="text" name="notes" id="notes"
                        placeholder="Notes" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Notes is required']}
                        value={this.state.row.notes} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Status *</Label>
                    <TextValidator type="text" name="status" id="status"
                        placeholder="Status" onChange={this.handleChange}
                        validators={['required']} errorMessages={['Status is required']}
                        value={this.state.row.status} />
                </FormGroup>
            </>
        )
    }


    formElements = () => {
        var listofTabs = ["Violation", "Documents"];
        let tabsComponentsList = new Array(listofTabs.length).fill(null);
        tabsComponentsList[0] = this.lienDataComponent();
        tabsComponentsList[1] = this.documentComp();
        return (
            <>
                <TabsComponent theme="modalTabs" components={tabsComponentsList}
                    listofTabs={listofTabs} generateTabURL={false} />

            </>
        )
    }

}