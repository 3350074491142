import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Col,
  Input,
} from "reactstrap";
import apicall from "../../../utils/apicall";
import helpers from "../../../utils/helpers";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
export default (props) => {
  //const [account, setAccount] = useState(props.account);
  const [account_number, setAccount] = useState(props.account);
  const [website_address, setWebsite_address] = useState(props.website_address);
  const [payment_website, setPayment_Website] = useState(props.payment_website);
  const [username, setUsername] = useState(props.username);
  const [password, setPassword] = useState(props.password);
  const [assessment_type_id, set_assessment_type] = useState(
    props.assessment_type_id
  );
  const onSubmitClick = () => {
    apicall
      .updateAccount(
        props.propertyid,
        props.hoaId,
        account_number,
        website_address,
        payment_website,
        username,
        password,
        assessment_type_id
      )
      .then((resp) => {
        props.refreshList();
        props.onCancelClick();
      });
  };

  useEffect(() => {
    if (props.assessment_types.length && props.assessment_types.length === 1) {
      set_assessment_type(props.assessment_types[0].id);
    }
  }, [props.assessment_types]);

  console.log("---->", props.assessment_types);

  const createSelectItems = () => {
    let selectOptions = [];
    selectOptions.push(
      <option value="" key="defaultValue">
        Select...
      </option>
    );
    props.assessment_types.map((assessment_type, index) => {
      selectOptions.push(
        <option key={assessment_type.id} value={assessment_type.id}>
          {assessment_type.name}
        </option>
      );
    });
    return selectOptions;
  };
  return (
    <div>
      <Modal isOpen={true} toggle={props.onCancelClick}>
        <ModalHeader>
          <div className="">
            <span className="heading">{props.heading}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label sm={2} for="">
              Account Number{" "}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="account_number"
                id="account_number"
                placeholder=""
                onChange={(event) => {
                  setAccount(event.target.value);
                }}
                value={account_number}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="">
              HOA Website{" "}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="website_address"
                id="website_address"
                placeholder="HOA_Website"
                onChange={(event) => {
                  setWebsite_address(event.target.value);
                }}
                value={website_address}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="">
              Payment Website{" "}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="payment_address"
                id="payment_address"
                placeholder="Payment_Website"
                onChange={(event) => {
                  setPayment_Website(event.target.value);
                }}
                value={payment_website}
              />
            </Col>
          </FormGroup>
          {/* <FormGroup row>
            <Label sm={2} for="">
              Hoa Website{""}
            </Label>
            <Col sm={10}>
              <Input 
              type = "text"
              name="account_number"
              id=""
              placeholder=""onChange={(event) => {setHoa(event.target.value)}}
              value={hoa_Website} />
               </Col>
          </FormGroup>
        */}
          <FormGroup row>
            <Label sm={2} for="">
              Username{" "}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="username"
                id="username"
                placeholder=""
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
                value={username}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="">
              Password{" "}
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="password"
                id="password"
                placeholder=""
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                value={password}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Label for="">Assessment Description</Label>
            <Input
              type="select"
              name="assessment_type_id"
              id="assessment_type_id"
              placeholder=""
              onChange={(event) => {
                set_assessment_type(event.target.value);
              }}
              value={assessment_type_id}
            >
              {createSelectItems()}
            </Input>
          </FormGroup>
          <Button onClick={onSubmitClick}>Save</Button>
          &nbsp;
          <Button onClick={props.onCancelClick}>Cancel</Button>
        </ModalBody>
      </Modal>
    </div>
  );
};
