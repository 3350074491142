import React from "react";
import '../../../styles/_lease-info.scss';
import apicall from '../../../utils/apicall'
import config from '../../../utils/config'
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import ListComp from "../../crud/ListComp";
import { CustomInput, FormGroup, Label, Button } from 'reactstrap';
import FinanceLeaseFormComponent from './FinanceLeaseFormComponent'
import helpers from "../../../utils/helpers";
import _ from 'lodash'
import Loader from '../../helper_components/LoaderComponent'
export default class FinanceLeaseInfo extends ListComp {
    constructor(props) {
        super(props);
        this.state = {
            propertyid: this.props.propertyid,
            openCreateForm: false,
            openUpdateForm: false,
            openFormModal: false,
            row: {},
            leaseInfoObject: {},
            refreshList: this.loadLeaseInfo,
            hideAdd: true,
            tableHide: true,
            isLoading: true,
            leasing_info_applicable: null
        }

    }

    componentWillMount() {
        this.loadLeaseInfo();
    }

    componentDidMount() {
        apicall.getProperty(this.props.propertyid).then(
            response => {
                this.setState({
                    leasing_info_applicable: response.leasing_info_applicable,
                    row: Object.assign(this.state.row, { hideAdd: response.leasing_info_applicable })
                })
                if (response.leasing_info_applicable === false) {
                    this.setState({
                        hideAdd: true,
                    })
                }
            }
        );
    }




    loadLeaseInfo = () => {
        apicall.getLeaseInfo(this.props.propertyid).then(
            (response) => {
                let hideAdd = true
                if (response && response.length > 0) {
                    hideAdd = true;
                } else if (config['LeaseInfoRead'].includes(this.props.userRole)) {
                    hideAdd = true;
                }
                this.setState({
                    leaseInfoObject: response.results,
                    hideAdd: hideAdd,
                    isLoading: false
                })
            }
        )
    }

    handleLeaseSubmit = () => {
        var requestObject = {
            'leasing_info_applicable': false
        }
        apicall.updateProperty(requestObject, this.state.propertyid).then(
            response => {
                if (response !== null && response.hasOwnProperty('errors')) {
                    let errorMsg = helpers.getErrorMsg(response.error);
                    if (errorMsg)
                        this.notify(errorMsg);

                } else {
                    this.setState({ 'leasing_info_applicable': response.leasing_info_applicable })
                    this.notify("Property Updated Successfully");
                }
            }
        )
    }

    handleRadioButton = (event) => {
        var row = this.state.row;
        let value = event.target.value;
        if (typeof value === 'string') {
            value = helpers.getBoolean(value);
        }
        row[event.target.name] = value
        this.setState({
            row: row,
            hideAdd: !value
        });
    }

    toggleForm = () => {
        this.setState({
            openFormModal: !this.state.openFormModal
        })
    }

    notify = (msg) => toast(msg);

    deleteLeaseInfo = (id) => {
        apicall.deleteLeaseInfo(id, this.state.propertyid).then(
            response => {
                if (response.hasOwnProperty('error')) {
                    this.notify("delete failed")
                }
                else {
                    this.notify("Lease info deleted Successfully")
                    this.loadLeaseInfo();
                }
            }
        )
    }

    editFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div
                style={{
                    textAlign: "center",
                    cursor: "pointer",
                    lineHeight: "normal"
                }}>
                <FaEdit style={{ marginRight: "4%" }} size="1.5em" onClick={() => {
                    this.setState({
                        openFormModal: !this.state.openFormModal,
                        updateModal: true,
                        actionID: row.actionable_id,
                        row: row
                    })
                }} />

            </div>
        );
    }

    columns = () => {
        let columnsEntries = [{
            dataField: 'id',
            text: 'Id'
        },
        {
            dataField: 'created_at',
            text: 'Upload By and Date',
            formatter: this.dateFormatter
        },
        {
            dataField: "action",
            text: "Actions",
            sort: false,
            formatter: this.editFormatter,
            headerAttrs: { width: 200 },
            attrs: { width: 200, class: "table-actions" }
        }];
        const questions = config.legalInfoMap;
        let keysArray = Object.keys(questions);
        for (let i = 0; i < keysArray.length; i++) {
            if (keysArray[i] !== "id" && keysArray[i] !== "created_at") {
                var columnName = {
                    "dataField": keysArray[i],
                    "text": questions[keysArray[i]],
                    "formatter": this.cellFormatter
                }
                columnsEntries.splice(2, 0, columnName);
            }
        }
        return columnsEntries;
    }

    tableInfo = () => {
        let propertyLeaseRadioDiv = null;
        console.log('lease>', this.state.leasing_info_applicable)
        if ((typeof this.state.leaseInfoObject === "undefined") || _.isEmpty(this.state.leaseInfoObject)) {
            propertyLeaseRadioDiv =
                <>
                    {this.state.isLoading ? <Loader /> :
                        <div>
                            {
                                <>
                                    <div className="addMarginBottom">
                                        Is Property Leasing Permitted
                                        </div>

                                    <div>
                                        <FormGroup style={{ display: 'flex' }}>
                                            <CustomInput id="leaseyes" type="radio" name="hideAdd" value={true} onClick={this.handleRadioButton}
                                                checked={(this.state.row.hideAdd === true) ? 'checked' : ''} />
                                            <Label style={{ marginRight: "2%" }}>Yes</Label>
                                            <CustomInput id="leaseno" type="radio" name="hideAdd" value={false} onClick={this.handleRadioButton}
                                                checked={(this.state.row.hideAdd === false) ? 'checked' : ''} />
                                            <Label style={{ marginRight: "2%" }}>No</Label>
                                            {this.state.row.hideAdd == false && <Button onClick={this.handleLeaseSubmit}>Submit</Button>}

                                        </FormGroup>
                                    </div></>

                            }
                        </div>
                    }
                </>

        }

        return propertyLeaseRadioDiv
    }
    generateLeaseInfoDetailsDiv = (leaseInfoObject) => {
        console.log('leaseInfoObject===>', leaseInfoObject)
        let legalInfoMap = config.legalInfoMap;
        let leaseInfoKeysArray = Object.keys(leaseInfoObject);
        let leaseInfoDetailsDiv = leaseInfoKeysArray.map(
            (leaseInfoKey, index) => {
                let leaseInfoValue = leaseInfoObject[leaseInfoKey];
                let leaseInfoName = legalInfoMap[leaseInfoKey];
                if (typeof leaseInfoValue === 'boolean') {
                    if (leaseInfoValue === true) {
                        leaseInfoValue = 'Yes'
                    } else if (leaseInfoValue === false) {
                        leaseInfoValue = 'No'
                    }
                }
                if (legalInfoMap.hasOwnProperty(leaseInfoKey) && leaseInfoValue !== null) {
                    return (
                        <tr className="info" key={'leaseinfo' + index}>
                            <td className="name">
                                {leaseInfoName} {" "}
                            </td>
                            <td className="value">
                                {leaseInfoValue}
                            </td>
                        </tr>
                    )
                }
            }
        )
        return (<table className="table">
            <tr>

                <th>
                    Lease Topic
                </th>
                <th>
                    Status
                </th>
            </tr>
            {leaseInfoDetailsDiv}
        </table>);
    }

    getEditButton = () => {
        if (config['LeaseInfoRead'].includes(this.props.userRole))
            return null
        else {
            return (
                <Button >Edit</Button>
            )
        }
    }

    generateLeaseInfoDiv = (leaseInfoObject) => {
        let leaseInfoDiv =
            <div className="lease-info">
                {this.generateLeaseInfoDetailsDiv(leaseInfoObject)}
                <div className="buttons">
                    <div className="update-btn" onClick={() => {
                        this.setState({
                            openFormModal: !this.state.openFormModal,
                            row: this.state.leaseInfoObject
                        })
                    }}>
                        {this.getEditButton()}
                    </div>

                </div>
            </div>
        return leaseInfoDiv;
    }

    formComp = () => {
        let leaseInfoObject = this.state.leaseInfoObject;
        let leaseInfoDiv = null;

        if (!_.isEmpty(leaseInfoObject)) {
            leaseInfoDiv = this.generateLeaseInfoDiv(leaseInfoObject);
        }
        return <>
            {leaseInfoDiv}
            <FinanceLeaseFormComponent title="Lease Information" open={this.state.openFormModal}
                toggle={this.toggleForm} propertyid={this.state.propertyid}
                reload={this.loadLeaseInfo} isUpdate={true} row={this.state.row} refreshList={this.loadLeaseInfo} />
        </>
    }

}