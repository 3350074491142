// import { React, useState } from "react";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ReactDataGrid from "react-data-grid";
import { Editors } from "react-data-grid-addons";
import config from "../../utils/config";
import apicall from "../../utils/apicall";
import { FaTimes, FaPlus, FaTrashAlt } from "react-icons/fa";
import helpers from "../../utils/helpers";
import {Button} from "reactstrap";

const { DropDownEditor } = Editors;

export default props => {
  console.log("came here assessments props==>");
  const FyStartEditor = <DropDownEditor options={config.Months_Array} />;
  const PeriodicityTypes = [];
  Object.keys(config.periodicity).map((period, index) => {
    let x = { id: config.periodicity[period], value: period };
    PeriodicityTypes.push(x);
  });
  const PeriodicityEditor = <DropDownEditor options={PeriodicityTypes} />;

  const [rows, set_rows] = useState(
    props.assessmentType.assessments ? props.assessmentType.assessments : []
  );

  useEffect(() => {
    set_rows(
      props.assessmentType.assessments ? props.assessmentType.assessments : []
    );
  }, props.assessmentType.assessments);

  const addRows = () => {
    let row = {
      description: "",
      fy_start: "",
      periodicity: "",
      amount: "",
      complete: "",
      special_start: "",
      special_finish: ""
    };
    set_rows([...rows, row]);
  };

  const saveAssessments = () => {
    apicall
      .updateAssessmentType(props.assessmentType.id, props.hoaID, rows)
      .then(resp => {
        console.log("resp===>", resp);
      });
  };

  const deleteAssessmentType = () => {
    apicall
      .deleteAssessmentType(props.assessmentType.id, props.hoaID)
      .then(resp => {
        console.log("hhhhhhhhhhhhhhhhhhh", props.hoaID);
        props.refreshHoa(props.hoaID);
        helpers.notify("Deleted");
      });
  };

  const deleteAssessment = assessmentId => {
    apicall
      .deleteAssessment(props.assessmentType.id, assessmentId, props.hoaID)
      .then(resp => {
        props.refreshHoa(props.hoaID);
        // helpers.notify("deleted");
      });
  };

  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    set_rows(rows => {
      const newRows = rows.slice();
      for (let i = fromRow; i <= toRow; i++) {
        newRows[i] = { ...newRows[i], ...updated };
      }
      return newRows;
    });
  };

  const columns = [
    { key: "description", name: "Description", editable: true },
    {
      key: "fy_start",
      name: "FY Start",
      editable: true,
      editor: FyStartEditor
    },
    {
      key: "periodicity",
      name: "Periodicity",
      editable: true,
      editor: PeriodicityEditor
    },
    { key: "amount", name: "Amount", editable: true },
    { key: "complete", name: "Complete", editable: true },
    { key: "special_start", name: "Special Start", editable: true },
    { key: "special_finish", name: "Special Finish", editable: true },
    { key: "action", name: "Action" }
  ];

  const getCellActions = (column, row) => {
    console.log("came here", column, row);

    if (row.id && column.key === "action") {
      return [
        {
          icon: <FaTrashAlt style={{ cursor: "pointer" }} size="1.5em" />,
          callback: () => deleteAssessment(row.id)
        }
      ];
    }
  };


  return (
    <>
      <div className="assessment_type">
        Assessment Type : {props.assessmentType && props.assessmentType.name}
      </div>
      <FaTrashAlt
        style={{ float: "right", cursor: "pointer" }}
        size="1.5em"
        onClick={deleteAssessmentType}
      />
      <div className="add_line_item" onClick={addRows}>
        Add Line Item
      </div>
      <Button onClick={saveAssessments}>Save</Button>
      <div className="table_allignment">
        <ReactDataGrid
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
          enableCellSelect={true}
          onGridRowsUpdated={onGridRowsUpdated}
          getCellActions={getCellActions}
        />
      </div>
    </>
  );
};
