import React, { useState, useEffect } from "react";
import { Input, FormGroup, Label, Col } from "reactstrap";
import SelectOptions from "../helper_components/SelectOptions";
import config from "../../utils/config";
import apicall from "../../utils/apicall";
import DatePicker from "react-datepicker";
import helpers from "../../utils/helpers";
import dayjs from "dayjs";
import { FaBan, FaTrashAlt } from "react-icons/fa";
import Warning from "./WarningModal";
import _ from "lodash";
export default props => {

  const [isButtonDisabled, set_isButtonDisabled] = useState(true);
  const [rows, set_rows] = useState(
    props.assessmentType.assessments ? props.assessmentType.assessments : []
  );
  const [assessmentName, set_assessmentName] = useState(
    props.assessmentType.name
  );
  const [fy_start, set_fy_start] = useState(props.assessmentType.fy_start);
  const [modal, set_modal] = useState(false);
  const [generate_fy_assessment, set_generate_fy_assessment] = useState(false);
  const toggle = () => set_modal(!modal);


  // useEffect(
  //   () => {
  //     console.log("use effect rows--->", rows);
  //     if (rows.length === 0)
  //       addRows()
  //   }, [props.assessmentType.assessments]
  // );

  useEffect(
    () => {
      set_fy_start(props.assessmentType.fy_start)
    },[props.assessmentType.fy_start]
  )

  useEffect(() => {
    set_isButtonDisabled(false);
    set_assessmentName(props.assessmentType.name);
  }, [props.assessmentType.name]);

  useEffect(() => {
    if (!_.has(props.assessmentType,'assessments') || (_.isEmpty(props.assessmentType.assessments)))
      addRows()
    else
      set_rows(
        props.assessmentType.assessments
      );
  }, [props.assessmentType.assessments]);

  const addRows = () => {
    set_isButtonDisabled(false);
    const tempId = (+new Date() + Math.floor(Math.random() * 999999)).toString(
      36
    );
    let row = {
      id: tempId,
      type_of_charge: "",
      periodicity: "",
      amount: "",
      special_start: "",
      special_finish: "",
      status: "new"
    };
    set_rows([...rows, row]);
  };

  const deleteAssessment = (assessmentId, status) => {
    if (status === "new") {
      deleteArrayObject(assessmentId);
    } else {
      apicall
        .deleteAssessment(props.assessmentType.id, assessmentId, props.hoaID)
        .then(resp => {
          props.refreshHoa(props.hoaID);
          // helpers.notify("deleted");
        });
    }
  };

  const saveAssessments = () => {
    set_isButtonDisabled(true);
    apicall
      .updateAssessmentType(
        props.assessmentType.id,
        props.hoaID,
        rows,
        assessmentName,
         fy_start,
        generate_fy_assessment
      )
      .then(resp => {
        helpers.notify("Assessment Details Saved");
        props.refreshHoa(props.hoaID);
        // props.toggleModal();
      });
  };

  const deleteArrayObject = assessementID => {
    // console.log(rows.slice())
    const idToRemove = assessementID;
    const filternewArray = rows.slice().filter((items) => items.id !== idToRemove);
    //  console.log("deleted assesment" ,filternewArray)
    set_rows(filternewArray);
  };

  const updateTable = newData => {
    set_isButtonDisabled(false);
    const updatedRows =
      rows &&
      rows.slice().map((row, index) => {
        if (row.id == newData.id) {
          let x = { ...row };
          x[newData.name] = newData.value;
          return x;
        } else return row;
      });
    set_rows(updatedRows);
  };

  const handleCellChange = event => {
    const newData = {
      id: event.target.id,
      name: event.target.name,
      value: event.target.value
    };
    updateTable(newData);
  };

  const handleDateChange = (id, value, name) => {
    const newData = {
      id: id,
      name: name,
      value: dayjs(value).format("YYYY-MM-DD")
    };
    updateTable(newData);
  };

  const handleSubmit = () => {};

  const handleFyStart = event => {
    set_isButtonDisabled(false);
    // const Months_Array = config.Months_Array;
    // const currentMonth = dayjs(new Date()).format("MMMM");
    // const futureMonth = dayjs(new Date())
    //   .add(60, "day")
    //   .format("MMMM");
    // if (
    //   currentMonth !== event.target.value &&
    //   Months_Array.indexOf(event.target.value) <=
    //     Months_Array.indexOf(futureMonth)
    // ) {
    //   set_fy_start(event.target.value);
    //   set_modal(true);
    // } else {
    //   set_fy_start(event.target.value);
    // }
    set_fy_start(event.target.value);
  };

  const onYesClick = () => {
    set_generate_fy_assessment(true);
    toggle();
  };

  const onNoClick = () => {
    set_fy_start("");
    toggle();
  };

  return (
    <>
      {modal && (
        <Warning
          toggle={toggle}
          modal={modal}
          onYesClick={onYesClick}
          onNoClick={onNoClick}
        />
      )}
      <div className="form-bordered">
        <div className="update-assessment">
          <FormGroup row>
            <Label for="" sm={2}>
              {" "}
              Assessment Description
            </Label>
            <div className="assessment-input">
              <Col sm={10}>
                <Input
                  type="text"
                  name="assessmentName"
                  id="assessmentName"
                  placeholder=""
                  onChange={event => set_assessmentName(event.target.value)}
                  value={assessmentName}
                />
              </Col>
            </div>
            
            <Label for="" sm={2}  className="assessment-input-select">
              {" "}
              FY Start
            </Label>
            <div className="assessment-input-select">
              <Col sm={10}>
            <Input
              type="select"
              name="fy_start"
              id="fy_start"
              value={fy_start}
              onChange={handleFyStart}
            >
              <SelectOptions options={config.Months_Array} />
            </Input>
            </Col>
          </div>
          <div className="assessment-delete-button">
          <FaTrashAlt
              style={{
                fontSize: "14px",
                marginLeft: "16px",
                cursor: "pointer"
              }}
              size="1.5em"
              onClick={() =>
                props.deleteAssessmentType(props.assessmentType.id, props.hoaID)
              }
            />
            </div>
          </FormGroup>
        </div>
        <Table
          addRows={addRows}
          rows={rows}
          handleChange={handleCellChange}
          handleDateChange={handleDateChange}
          handleSubmit={handleSubmit}
          saveAssessments={saveAssessments}
          deleteAssessment={deleteAssessment}
          isButtonDisabled={isButtonDisabled}
        ></Table>
      </div>
    </>
  );
};

// Table component

function Table(props) {
  const tableRows =
    props.rows &&
    props.rows.map((row, index) => {
      return (
        <Row
          key={row.id}
          index={index}
          handleChange={props.handleChange}
          handleDateChange={props.handleDateChange}
          handleSubmit={props.handleSubmit}
          deleteAssessment={props.deleteAssessment}
          row={row}
        />
      );
    });
  return (
    <div>
      <></>
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Charge Type</th>
              <th>Periodicity</th>
              <th>Amount</th>
              <th>Special Start</th>
              <th>Special Finish</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>{tableRows}</tbody>
        </table>
      </div>
      <div className="charges-header">
        <div className="add-charge">
          <button
            type="button"
            onClick={props.addRows}
            className="btn btn-success pull-right"
          >
            Add Line Item
          </button>
          <button
            type="button"
            onClick={props.saveAssessments}
            disabled={props.isButtonDisabled}
            className="btn btn-secondary pull-right add-charge-button"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

// Row component

function Row(props) {
  const index = props.index;
  const status = props.row.status;
  return (
    <tr>
      <DropDownCell
        handleChange={props.handleChange}
        cellData={{
          type: "type_of_charge",
          value: props.row.type_of_charge,
          id: props.row.id
        }}
      />
      <DropDownCell
        handleChange={props.handleChange}
        cellData={{
          type: "periodicity",
          value: props.row.periodicity,
          id: props.row.id
        }}
      />
      <InputCell
        handleChange={props.handleChange}
        cellData={{ type: "amount", value: props.row.amount, id: props.row.id }}
        isNumber={true}
      />
      <DatePickerCell
        handleDateChange={props.handleDateChange}
        cellData={{
          type: "special_start",
          value: props.row.special_start,
          id: props.row.id
        }}
      />
      <DatePickerCell
        handleDateChange={props.handleDateChange}
        cellData={{
          type: "special_finish",
          value: props.row.special_finish,
          id: props.row.id
        }}
      />
      <td>
        <FaTrashAlt
          style={{ fontSize: "14px", marginLeft: "16px", cursor: "pointer" }}
          size="1.5em"
          onClick={() => props.deleteAssessment(props.row.id, props.row.status)}
        />
      </td>
    </tr>
  );
}

// Cell component

function InputCell(props) {
  return (
    <td>
      <Input
        type={props.isNumber ? "number" : "text"}
        name={props.cellData.type}
        id={props.cellData.id}
        value={props.cellData.value}
        onChange={props.handleChange}
      />
    </td>
  );
}

function DropDownCell(props) {
  return (
    <td>
      <Input
        type="select"
        name={props.cellData.type}
        id={props.cellData.id}
        value={props.cellData.value}
        onChange={props.handleChange}
      >
        {props.cellData.type === "periodicity" ? (
          <SelectOptions options={config.periodicityDummy} />
        ) : (
          <SelectOptions options={config.charge_type} />
        )}
      </Input>
    </td>
  );
}

function DatePickerCell(props) {
  return (
    <td>
      <DatePicker
        id={props.cellData.id}
        selected={
          props.cellData.value
            ? helpers.restructureDate(props.cellData.value)
            : null
        }
        onChange={date => {
          props.handleDateChange(props.cellData.id, date, props.cellData.type);
        }}
      />
    </td>
  );
}
