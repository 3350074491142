import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import create from '../../images/create.png';
import filter from '../../images/filter.png';
import ActionModal from '../ActionModal';
import '../../styles/action.css';
import actionsapi from "../../utils/api/actionsapi";
import paginationFactory from 'react-bootstrap-table2-paginator'
import AskSearchQuestionsModal from '../properties/AskSearchQuestionsModal'
import LienModal from '../lien/LienModal';
import { Link } from 'react-router-dom';
import { FaEdit } from "react-icons/fa";
import InspectionModal from '../inspection/InspectionModal';
import ViolationModal from '../violations/ViolationModal';
import PaymentModal from '../payments/paymentModal';
import Loader from '../helper_components/LoaderComponent'
import config from '../../utils/config';
import AssociationInformationModal from '../hoas/FormComponent';
import _ from 'lodash';
import helpers from '../../utils/helpers'
export default class ActionListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actionModal: false,
            searchModal: false,
            updateModal: false,
            inspectionModal: false,
            violationModal: false,
            lienModal: false,
            paymentModal: false,
            rowIndex: 0,
            propertyID: 0,
            actionArray: [],
            row: {},
            action: {},
            isLoading: true,
            associationinformationModal:false,
        }
        this.state = {
            ...this.state,
            ...this.props
        };
    }

    componentWillMount() {
        this.getActions(this.state.propertyID);
    }

    getActions = (propertyID) => {
        actionsapi.listActions(propertyID).then(
            response => {
                this.setState({
                    actionArray: response.results,
                    isLoading: false
                })
            }
        ).catch(e => console.log(e))
    }


    getActionType = (cell) => {
        if(config['actionTypes'].hasOwnProperty(cell)){
            return config.actionTypes[cell];
        }else{
            return cell;
        }
    }



    actionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (cell.trim().toLowerCase() !== "documentinforeport"
            && cell.trim().toLowerCase() !== "pir" && cell.trim().toLowerCase() !== "associationverification") {
            return (
                <React.Fragment>
                    <div style={{ cursor: "pointer", color: "red" }} onClick={() => {
                        this.setState({
                            [cell.trim().toLowerCase() + "Modal"]: !this.state[cell.trim().toLowerCase() + "Modal"],
                            actionID: row.actionable_id,
                            action: row,
                            row: row
                        })
                    }}>
                        {this.getActionType(cell)}
                    </div>
                </React.Fragment>
            );
        }
        let cellName = cell.trim().toLowerCase();
        let tabNo = (cellName === "documentinforeport") ? 5 : null;
        tabNo = (cellName === "pir") ? 1 : tabNo;
        let hoaID = _.has(row,'hoa_id') ? row.hoa_id : 0;
        if (tabNo !== null) {
            return <Link className="link" to={"/property-manage/" + tabNo + "/" + this.state.propertyID + "/" + hoaID}>{this.getActionType(cell)}</Link>
        }
        return cell;
    }

    toggleActionAnalyst = () => {
        this.setState({
            actionModal: !this.state.actionModal
        })
    }

    dateFormatter = (cell) =>{
        return helpers.formatDate(cell)
    }

    notesFormatter = (cell,row) => {
        let notesArr = !_.isEmpty(cell) && cell.split(/\n/g)
        let notes = _.isArray(notesArr) && notesArr.filter(x=> x !== "").map(
            (x,ind) => {
                return <p>{x}</p>
            }
        )
        return(
            <>
            <p>{notes}</p>
            </>
        )
    }

    editFormatter = (cell, row, rowIndex, formatExtraData) => {
        if(row.actionable_type !== "Payment") {
            return (
                <div
                    style={{
                        textAlign: "center",
                        cursor: "pointer",
                        lineHeight: "normal"
                    }}>
                    <FaEdit style={{ marginRight: "4%" }} size="1.5em" onClick={() => {
                        this.setState({
                            actionModal: !this.state.actionModal,
                            updateModal: true,
                            actionID: row.actionable_id,
                            action: row
                        })
                    }} />
                </div>
            );
        }
    }

    newActionCreated = (actionProperty) => {
        this.setState({
            [actionProperty.actionable_type.trim().toLowerCase() + "Modal"]: !this.state[actionProperty.actionable_type.trim().toLowerCase() + "Modal"],
            actionID: actionProperty.actionable_id,
            action: actionProperty
        })
    }

    actionEdit = () => {
        if(true){
            return {
                dataField: "action",
                text: "Actions",
                sort: false,
                formatter: this.editFormatter,
                headerAttrs: { width: 200 },
                attrs: { width: 200, class: "table-actions" }
            }
        }
    }

    rowStyle = (row, rowIndex) => {
        if (row.actionable_type === 'Payment' && config['paymentHide'].includes(this.props.userRole))  {
            return (
                { display: 'none' }
            )
        }
        if(row.actionable_type === 'Violation' && config['ViolationRead'].includes(this.props.userRole)){
            return (
                { display: 'none' }
            )
        }
        if(row.actionable_type === 'Lien' && config['LiensHide'].includes(this.props.userRole)){
            return (
                { display: 'none' }
            )
        }
    };

    render() {
        let actions = this.state.actionArray;
        const columns = [{
            dataField: 'actionable_type',
            text: 'Action Type',
            formatter: this.actionTypeFormatter,
        }, {
            dataField: 'description',
            text: 'Description'
        }, {
            dataField: 'hoa.name',
            text: 'HOA',
            classes: 'reduce-width'
        },{
            dataField: 'creation_date',
            text: 'Creation Date/Age',
            classes:'reduce-width',
            formatter:this.dateFormatter
        }, {
            dataField: 'due_date',
            text: 'Due Date',
            classes:'reduce-width',
            formatter: this.dateFormatter
        },
        {
            dataField: 'current_status',
            text: 'Current Status',
            classes:'reduce-width'
        },
         {
            dataField: 'notes',
            text: 'Notes/Actions/Next Steps',
            classes:'notes-width',
            formatter:this.notesFormatter
        }, {
            dataField: "action",
            text: "Actions",
            sort: false,
            formatter: this.editFormatter,
            headerAttrs: { width: 200 },
            attrs: { width: 200, class: "table-actions" }}];
        if (this.state.isLoading) {
            return (
                <Loader />
            )
        } else {
            return (
                <React.Fragment>
                    <div className="create">
                        {
                            (config['createAction'].includes(this.props.userRole)) ?
                            <div className="creating">
                            <button type="btn" className="btn btn-secondary" onClick={() => this.setState({
                                actionModal: !this.state.actionModal,
                                updateModal: false
                            })} >
                                <img className="create-img" src={create} alt="" hspace="10" />Create Action
                            <ActionModal modal={this.state.actionModal} toggle={this.toggleActionAnalyst} propertyID={this.state.propertyID}
                                    updateModal={this.state.updateModal} actionID={this.state.actionID}
                                    getActions={this.getActions} action={this.state.action} newActionCreated={this.newActionCreated}/>
                            </button>
                        </div>:null
                        }

                        <div className="filtering">
                            <button type="btn" className="btn btn-secondary">
                                <img className="filter-img" src={filter} alt="" hspace="10" />Filter By Action Type

                        </button>
                        </div>
                    </div>
                    {actions &&
                        <BootstrapTable keyField='id' data={actions} columns={columns} Pagination={paginationFactory} rowStyle={this.rowStyle}/>
                    }
                    {this.state.associationverificationModal && <AskSearchQuestionsModal toggle={() => {
                        this.setState({
                            associationverificationModal: !this.state.associationverificationModal
                        })
                    }}
                        row={this.state.row}
                        modal={this.state.associationverificationModal} propertyID={this.state.propertyID} />}
                    {this.state.lienModal &&
                        <LienModal toggle={() => {
                            this.setState({
                                lienModal: !this.state.lienModal
                            })
                        }} title="Lien" open={this.state.lienModal}
                            propertyID={this.state.propertyID} actionID={this.state.actionID}
                            action={this.state.action} userRole={this.props.userRole}
                            refershActions={this.getActions} />}
                    {this.state.inspectionModal &&
                        <InspectionModal toggle={() => {
                            this.setState({
                                inspectionModal: !this.state.inspectionModal
                            })
                        }} title="Inspection" open={this.state.inspectionModal} row={this.state.row}
                            propertyID={this.state.propertyID} actionID={this.state.actionID} />}
                    {this.state.violationModal &&
                        <ViolationModal toggle={() => {
                            this.setState({
                                violationModal: !this.state.violationModal
                            })
                        }} title="Violation" open={this.state.violationModal}
                            propertyID={this.state.propertyID} actionID={this.state.actionID} refershActions={this.getActions} />
                    }
                    {this.state.paymentModal &&
                        <PaymentModal toggle={() => {
                            this.setState({
                                paymentModal: !this.state.paymentModal
                            })
                        }} title="Payment" open={this.state.paymentModal}
                            propertyID={this.state.propertyID} actionID={this.state.actionID}
                            userRole={this.props.userRole} refershActions={this.getActions}
                            propertyInfo={this.props.propertyInfo} actionHoaId={this.state.action.hoa_id}/>
                    }
                     {this.state.associationinformationModal &&
                        <AssociationInformationModal toggle={() => {
                            this.setState({
                                associationinformationModal: !this.state.associationinformationModal
                            })
                        }} title="HOA" open={this.state.associationinformationModal} 
                                row={_.has(this.state.row,'hoa') && this.state.row.hoa}
                            propertyID={this.state.propertyID} actionID={this.state.actionID} />
                    }

                </React.Fragment>
            );
        }

    }
}
