import React, { Component } from "react";
import "../../../styles/_general.scss";
import apicall from "../../../utils/apicall";
import AsyncMultiSelect from "../AsyncMultiSelect";
import "../../../styles/_display-hoa.scss";
import { Button, CustomInput } from "reactstrap";
import { toast } from "react-toastify";
import Loader from "../../helper_components/LoaderComponent";
import config from "../../../utils/config";
import HoaFormComponent from "../../hoas/FormComponent.js";
import MgmtCoFormComponent from "../../management_companies/FormComponent.js";
import { FaEdit, FaTrashAlt, FaBan } from "react-icons/fa";
import PaginationComponent from "../../helper_components/PaginationComponent";
import ConfirmBox from "../../helper_components/ConfirmBox";
import helpers from "../../../utils/helpers";
import _ from "lodash";
import AccountModal from "./AccountEdit";
export default class DisplayHOA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoas: [],
      propertyid: props.propertyid,
      isLoading: true,
      row: {},
      totalCount: 0,
      pageNo: 1,
      accountModalOpen: false,
    };
  }

  componentWillMount() {
    this.loadHoa();
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  loadHoa = () => {
    apicall
      .getHoaByPropertyID(
        this.props.propertyid,
        this.state.pageNo,
        this.state.perPage
      )
      .then((response) => {
        this.setState({
          hoas: response.results,
          totalCount: response.total,
          isLoading: false,
        });
      });
  };

  refreshList = (event, pageNo, perPage) => {
    this.setState({
      pageNo,
      perPage,
      isLoading: true,
    });
    setTimeout(this.loadHoa, 3000);
  };

  deleteRecord = (row) => {
    apicall.removeHOA(row.id, this.state.propertyid).then((response) => {
      if (response) {
        toast("Deleted successfully");
        this.setState({ isLoading: true });
        setTimeout(this.loadHoa, 3000);
        setTimeout(() => this.props.getActions(this.props.propertyid), 3000);
      }
    });
  };

  buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (config["showHoa"].includes(this.props.userRole)) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <FaTrashAlt
            style={{ marginRight: "4%" }}
            size="1.5em"
            onClick={() => this.setState({ row, deleteModal: true })}
          />
        </div>
      );
    } else {
      return <FaBan className="font-awesome-disabled" size="1.6em" />;
    }
  };
  hoaInfo = (cell, row, rowIndex, formatExtraData) => {
    return this.loadCell(true, row);
  };

  companyInfo = (cell, row, rowIndex, formatExtraData) => {
    return !_.isEmpty(row.management_company)
      ? this.loadCell(false, row)
      : "Nil";
  };

  nameValueDiv = (name, value) => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ width: "30%", fontWeight: "bold" }}>{name}</div>
        <div>{value}</div>
      </div>
    );
  };

  pocDetails = (data, count, isHOA) => {
    let mailId = isHOA ? data["email" + count] : data["email"];
    let phoneNumber = isHOA ? data["phone" + count] : data["phone"];
    let pocName = isHOA ? data["poc" + count] : data["name"];
    let titleName = isHOA ? data["title" + count] : data["title"];
    return (
      <div style={{ marginTop: "7px", marginBottom: "7px" }}>
        {this.nameValueDiv("POC " + count + " Name", pocName)}
        {this.nameValueDiv(
          "Title",
          isHOA ? titleName : config.McPocTitles[titleName]
        )}
        {this.nameValueDiv("Phone Number", phoneNumber)}
        {this.nameValueDiv("Email", mailId)}
      </div>
    );
  };

  loadCell = (isHOA, row) => {
    // let showEdit = config['showHoa'].includes(this.props.userRole);
    let showEdit = true;
    let data = isHOA ? row : row.management_company;
    let nameTitle = isHOA ? "HOA Name" : "Company Name";
    let nameValue = isHOA
      ? row.name
      : row.management_company && row.management_company["name"];
    let status = isHOA && row.status;
    let addressFields = [
      "physical_street_no_and_name",
      "physical_city",
      "physical_state",
    ];
    let extraField = "physical_zip";
    let address =
      data && helpers.formatAddress(data, addressFields, extraField);

    if (isHOA) {
      data = row;
    } else {
      let management_company = row.management_company;
      let pocArray = management_company.point_of_contacts;
      let newArray = [];

      let pocCount = 0;

      let pocresultedArray = [null, null, null];
      for (var i = 0; i < pocArray.length; i++) {
        if (pocArray[i].id === row.management_poc1_id) {
          pocresultedArray[0] = pocArray[i];
        }

        if (pocArray[i].id === row.management_poc2_id) {
          pocresultedArray[1] = pocArray[i];
        }

        if (pocArray[i].id === row.management_poc3_id) {
          pocresultedArray[2] = pocArray[i];
        }
      }
      var filtered = pocresultedArray.filter(function (el) {
        return el != null;
      });

      var pocDIV = filtered.map((point_of_contacts, index) => {
        return this.pocDetails(point_of_contacts, ++pocCount, isHOA);
      });
    }
    const getAssessment_type = (id, assessment_types) => {
      return (
        assessment_types &&
        assessment_types.map((assessment_type, index) => {
          if (assessment_type.id === id) return assessment_type.name;
        })
      );
    };

    return (
      <div>
        <>
          <div className="edit-link">
            {showEdit ? (
              <FaEdit
                style={{ float: "right" }}
                size="1.5em"
                onClick={() => {
                  if (isHOA) {
                    this.setState({
                      row: {
                        ...row,
                        management_company_id: {
                          value: row.management_company_id,
                          label:
                            row.management_company &&
                            row.management_company.name,
                        },
                      },
                      openHoaModal: true,
                    });
                  } else
                    this.setState({
                      row: row,
                      openMCModal: true,
                    });
                }}
              />
            ) : null}
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "30%", fontWeight: "bold" }}>{nameTitle}</div>
            <div>{nameValue}</div>
          </div>
          {isHOA && (
            <>
              <div
                className="acc-number"
                onClick={() => {
                  this.setState({
                    hoaID: row.id,
                    accountModalOpen: true,
                    property_hoa_account: row.property_hoa_account,
                    assessment_types: row.assessment_types,
                    hoas_properties: row.hoas_properties,
                    website_address: row.website_address,
                    payment_website: row.payment_website,
                  });
                }}
              >
                <FaEdit style={{ float: "right" }} size="1.5em" />
                Account Number :{" "}
                <span style={{ fontWeight: "normal", marginLeft: "14px" }}>
                  {data.property_hoa_account}
                </span>
              </div>
            </>
          )}
          {_.has(data, "hoas_properties") && (
            <>
              <div className="hoa_website">
                <div className="hoa_site">HOA Website: </div>
                <div style={{ marginLeft: "5px" }}>
                  <a href={"http://" + data.website_address} target="_blank">
                    {data.website_address}
                  </a>
                </div>
              </div>
              <div className="hoa_website">
                <div className="payment_site">Payment Website: </div>
                <div style={{ width: "60%" }}>
                  <a href={"http://" + data.payment_website} target="_blank">
                    {data.payment_website}
                  </a>
                </div>
              </div>
              <div className="hoa_website">
                <div className="account_name">User Name: </div>
                <div>{data.hoas_properties.username}</div>
              </div>
              <div className="hoa_website">
                <div className="account_password"> Password: </div>
                <div>{data.hoas_properties.password}</div>
              </div>
              <div className="hoa_website">
                <div className="assesment_description">
                  Assessment Description:
                </div>
                <div>
                  {getAssessment_type(
                    data.hoas_properties.assessment_type_id,
                    data.assessment_types
                  )}
                </div>
              </div>
            </>
          )}
        </>
        <div className="address">{this.nameValueDiv("Address", address)}</div>
        {isHOA && this.nameValueDiv("Status", status)}
        {isHOA ? (
          <>
            {this.pocDetails(data, 1, isHOA)}
            {this.pocDetails(data, 2, isHOA)}
            {this.pocDetails(data, 3, isHOA)}
          </>
        ) : (
          <>{pocDIV}</>
        )}

        {isHOA && (
          <>
            {this.nameValueDiv("FY Start", data.fiscal_year_start)}
            {this.nameValueDiv("Assessment Amount", data.assesment_dues)}
            {this.nameValueDiv(
              "Periodicity",
              data.assessment_frequency &&
                data.assessment_frequency.toUpperCase()
            )}
            {data.good_through_date &&
              this.nameValueDiv(
                "Good Through Date",
                helpers.formatDate(data.good_through_date)
              )}
          </>
        )}
      </div>
    );
  };

  toggleConfirmBox = () =>
    this.setState({ toggleConfirmBox: !this.state.toggleConfirmBox });

  toggleAccountModal = () =>
    this.setState({ accountModalOpen: !this.state.accountModalOpen });

  columns = () => {
    return [
      {
        dataField: "name",
        text: "HOA Information",
        formatter: this.hoaInfo,
        classes: "increase-width",
      },
      {
        dataField: "name",
        text: "Management Company",
        formatter: this.companyInfo,
        classes: "increase-width",
      },
      {
        dataField: "action",
        text: "Actions",
        sort: false,
        formatter: this.buttonFormatter,
        headerAttrs: { width: 200 },
        classes: "reduce-width",
        attrs: { width: 200, class: "table-actions" },
      },
    ];
  };

  render() {
    let columns = this.columns();
    let pocArray =
      this.state.row.management_company &&
      this.state.row.management_company.point_of_contacts;
    const renderHoas = () => {
      if (
        this.state.hoas !== null &&
        typeof this.state.hoas !== "undefined" &&
        Object.keys(this.state.hoas).length !== 0
      ) {
        let hoas = this.state.hoas;
        return (
          <React.Fragment>
            {this.state.isLoading ? (
              <Loader />
            ) : (
              <div className="hoa-details">
                {!config["generalInfoRead"].includes(this.props.userRole) && (
                  <div className="mutli-select">
                    <AsyncMultiSelect
                      isHoa={true}
                      propertyid={this.state.propertyid}
                      getActions={this.props.getActions}
                      loadHoaList={this.loadHoa}
                    />
                  </div>
                )}
                {/* <BootstrapTable keyField='id' data={hoas} columns={columns} /> */}
                <PaginationComponent
                  columns={columns}
                  rows={hoas}
                  handleSubmit={this.state.refreshList}
                  pageNo={this.state.pageNo}
                  totalCount={this.state.totalCount}
                />
              </div>
            )}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {this.state.isLoading ? (
              <Loader />
            ) : (
              <div className="hoa-details">
                HOA doesn't exist
                {!config["generalInfoRead"].includes(this.props.userRole) && (
                  <div className="multi-select">
                    <AsyncMultiSelect
                      isHoa={true}
                      propertyid={this.state.propertyid}
                      getActions={this.props.getActions}
                      loadHoaList={this.loadHoa}
                    />
                  </div>
                )}
              </div>
            )}
          </React.Fragment>
        );
      }
    };
    return (
      <React.Fragment>
        {config["showHoa"].includes(this.props.userRole) && (
          <Button
            className="create-hoa"
            onClick={() => this.setState({ openHoaModal: true, row: {} })}
          >
            Create HOA
          </Button>
        )}

        {renderHoas()}

        {this.state.openHoaModal && (
          <HoaFormComponent
            toggle={() => {
              this.setState({ openHoaModal: !this.state.openHoaModal });
              this.loadHoa();
            }}
            title="HOA"
            toggleConfirmBox={this.toggleConfirmBox}
            open={true}
            row={this.state.row}
            setRow={(row) => this.setState({ row })}
            isDisplayHOA={true}
          />
        )}

        {this.state.openMCModal && (
          <MgmtCoFormComponent
            toggle={() => {
              this.setState({ openMCModal: !this.state.openMCModal, row: {} });
              this.loadHoa();
            }}
            title="HOA"
            open={true}
            managementCompany={this.state.row.management_company}
            pocArray={pocArray ? pocArray : []}
          />
        )}

        {this.state.toggleConfirmBox && (
          <ConfirmBox
            onYesClick={() => {
              apicall
                .generatePaymentActions(this.state.row.id)
                .then(() => {
                  helpers.notify("Payment action created.");
                  this.toggleConfirmBox();
                })
                .catch((e) => console.log(e));
            }}
            onNoClick={this.toggleConfirmBox}
            heading={<span>Create payment action?</span>}
          >
            <h6>
              Due date before 60 days, create payment action for related
              properties?
            </h6>
          </ConfirmBox>
        )}
        {this.state.deleteModal && (
          <ConfirmBox
            onYesClick={() => {
              this.deleteRecord(this.state.row);
              this.setState({ deleteModal: false });
            }}
            onNoClick={() => this.setState({ deleteModal: false })}
            heading={
              <span>
                Are you sure you want to delete the HOA: {this.state.row.name}?
              </span>
            }
          >
            <div>
              <p>All HOA related details from properties will be deleted.</p>
            </div>
          </ConfirmBox>
        )}
        {this.state.accountModalOpen && (
          <AccountModal
            heading={<span>Update Property-Association Account Number</span>}
            onCancelClick={this.toggleAccountModal}
            account={this.state.property_hoa_account}
            website_address={this.state.website_address}
            payment_website={this.state.payment_website}
            username={this.state.hoas_properties.username}
            password={this.state.hoas_properties.password}
            propertyid={this.props.propertyid}
            hoaId={this.state.hoaID}
            refreshList={this.loadHoa}
            assessment_types={this.state.assessment_types}
            assessment_type_id={
              this.state.hoas_properties["assessment_type_id"]
            }
          ></AccountModal>
        )}
      </React.Fragment>
    );
  }
}
