import config from '../config';
import { withRouter } from 'react-router';
import helpers from '../helpers';
var violationapi = {
    fetchActionViolation : async function(actionID,propertyID){
        let res = '';
        let url = config.baseUrl + '/api/v1/violations/' + actionID + '?property_id=' + propertyID;
        let init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while violations Fetch' + response)
            }).then(responseJson => {
                res = responseJson;
            }).catch(function (error) {


            })
        return res;
    },
    fetchViolatioList: async function(propertyID, pageNo, perPage) {
        let res = '';
        let url = config.baseUrl + '/api/v1/violations/' + '?property_id=' + propertyID;
        let init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        if (pageNo) {
            url = url + "&page=" + pageNo;
        }
        if (perPage) {
            url = url + "&per_page=" + perPage;
        }
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while violations Fetch' + response)
            }).then(responseJson => {
                res = responseJson;
            }).catch(function (error) {


            })
        return res;
    },
    violationUpdate: async function (violation, propertyID, actionID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/violations/' + actionID + '?property_id=' + propertyID;
        let form = new FormData();
        for (var key in violation) {
            if (!Array.isArray(violation[key])) {
                form.append("violation["+key+"]", violation[key]);
            }
        }
        let init = {
            method: "PUT",
            headers: await helpers.getSavedToken(),
            body: form
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.clone().json();
                }
                throw new Error('Something went wrong, while Violation Update' + response)
            })
            .then(responseJson => {

                res = responseJson;
            }).catch(function (error) {

            })
        return res;
    }, 
    deleteViolationDoc: async function (propertyId, violationId, documentId) {
        let url = config.baseUrl + '/api/v1/violations/'+ violationId + "/delete_documents?document_id=" + documentId + "&property_id=" + propertyId;
        var init = {
            method: "DELETE",   
            headers: await helpers.defaultHeaders()
        };
        return await fetch(url, init)
        .then(helpers.fetchHandler)
        .then(json => json.json)
    },
}
export default withRouter(violationapi);