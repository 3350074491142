import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import apicall from '../../utils/apicall';
import TextValidator from '../helper_components/TextValidator';
import { ValidatorForm } from 'react-form-validator-core';
import helpers from '../../utils/helpers'
import { toast } from 'react-toastify';
import ReactPhoneInput from "react-phone-input-2";
class CreateAnalystModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createAnalystModal: false,
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            role: '',
            mobile: '',
            id: '',
            status:'active',
            userRoles: {}
        }
        this.state = {
            ...this.state,
            ...this.props
        };
    }

    componentWillMount() {
         this.fetchUserRoles()
    }

    toggleModalAnalyst = () => {
        this.state.toggle();
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            this.setState({
                ...this.state,
                ...newprops
            });

            if (typeof newprops.analystArray !== "undefined" && newprops.analystArray !== null
                && typeof newprops.rowIndex !== "undefined" && typeof newprops.analystArray[newprops.rowIndex] !== "undefined") {
                this.setState({
                    name: newprops.row.name,
                    email: newprops.row.email,
                    id: newprops.row.id,
                    mobile: newprops.row.mobile,
                    role: newprops.row.role,
                    status:newprops.row.status
                })
            }
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    notify = (errorMsg) => toast(errorMsg);

    handleSubmit = (event) => {
        let callUpdateAPI = (typeof this.props.rowIndex !== "undefined");
        if (!callUpdateAPI) {
            apicall.createAnalyst(this.state.name, this.state.email, this.state.password, this.state.password_confirmation, this.state.role, this.state.mobile, this.state.status).then(
                response => {
                    if (response !== null && response.hasOwnProperty('errors')) {
                        let errorMsg = helpers.getErrorMsg(response.errors);
                        if (errorMsg)
                            this.notify(errorMsg);
                    } else {
                        this.notify("User Created Successfully");
                        this.toggleModalAnalyst();
                        this.state.getAnalysts();
                    }
                }
            )
        }
        else {
            var userData = {
                name: this.state.name,
                email: this.state.email,
                mobile: this.state.mobile,
                role: this.state.role,
                status: this.state.status
            }

            if(this.state.password.length > 0) {
                userData['password'] = this.state.password
                userData['password_confirmation'] = this.state.password_confirmation
            }

            apicall.updateAnalyst(userData, this.state.id).then(
                response => {

                    if (response !== null && response.hasOwnProperty('errors')) {
                        let errorMsg = helpers.getErrorMsg(response.errors);
                        if (errorMsg)
                            this.notify(errorMsg);

                    } else {
                        this.notify("User Updated Successfully");
                        this.toggleModalAnalyst();
                        this.props.refreshUserList();
                    }
                }
            )
        }

        // if (this.props.updateData) {
            // this.state.getAnalysts();
        // }
        event.preventDefault();
    }

    handleRadioButton = (event) => {
        let value = event.target.value;
        this.setState({'status':value})
    }

    fetchUserRoles = () => {
        apicall.fetchUserRole().then(
            response => {

                this.setState({ userRoles: response})
            }
        )
    }

    renderModal = () => {
        var headerText = '';
        let callUpdateAPI = (typeof this.props.rowIndex !== "undefined");
        if (!callUpdateAPI) {
            headerText = 'Create User';
        }
        else {
            headerText = 'Update User';
        }

        let typeActions = [];
        typeActions.push(<option value="">Select Type</option>)
        for (let role in this.state.userRoles) {
            typeActions.push(<option key={role} value={role}>{this.state.userRoles[role]}</option>)
        }

        return (
            <div>
                <ModalHeader toggle={this.toggleModalAnalyst}>
                    <div className="">
                        <span className="heading">
                            {headerText}
                        </span>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <ValidatorForm onSubmit={this.handleSubmit} autocomplete="off">
                        <FormGroup>
                            <Label for="">Name *</Label>
                            <TextValidator type="text" name="name" id="name" placeholder="Enter name" onChange={this.handleChange} validators={['required', 'maxStringLength: 50']}
                                errorMessages={['Name is required', 'Should not be exceed 50 characters']} value={this.state.name} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Email *</Label>
                            <TextValidator type="email" name="email" id="email" placeholder="Enter email" onChange={this.handleChange} validators={['required', 'isEmail']}
                                errorMessages={['Email is required', 'Email is not valid']} value={this.state.email} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Mobile No</Label>
                            <ReactPhoneInput defaultCountry={'us'} value={this.state.mobile || ''}
                                inputExtraProps={{
                                    name: 'mobile',
                                    required: true,
                                    autoFocus: true
                                }}
                                onChange={(value) => {
                                    this.setState({
                                        mobile: value
                                    });
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Password</Label>
                            <TextValidator type="password" name="password" id="password" placeholder="Enter password" onChange={this.handleChange}
                                validators={[]} value={this.state.password} errorMessages={[]} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Confirm Password</Label>
                            <TextValidator type="password" name="password_confirmation" id="password_confirmation" placeholder="Confirm password"
                                onChange={this.handleChange} validators={[]} value={this.state.password_confirmation}
                                errorMessages={[]} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Role</Label>
                            <Input type="select" name="role" id="role" onChange={this.handleChange} value={this.state.role}>
                                {/* <option value="general_user">General</option>
                                <option value="senior_analyst">Analyst</option>
                                <option value="vendor">Vendor</option>
                                <option value="admin">Admin</option> */}
                                {typeActions}
                            </Input>
                        </FormGroup>
                        <Label for="">User Status</Label>
                        <FormGroup style={{ display: 'flex' }}>
                    <CustomInput id="active" type="radio" name="active"  checked={this.state.status === 'active' ? "checked" : ""} onClick={this.handleRadioButton}
                        value='active'/>
                    <Label style={{ marginRight: "2%" }}>Active</Label>
                    <CustomInput id="inactive" type="radio" name="inactive"  checked={this.state.status === 'inactive' ? "checked" : ""} onClick={this.handleRadioButton}
                         value='inactive'/>
                    <Label style={{ marginRight: "2%" }}>Inactive</Label>
                </FormGroup>
                        <Button>Submit</Button>
                    </ValidatorForm>
                </ModalBody>
            </div>
        );
    }

    render() {

        return (
            <div>
                <Modal
                    className="createAnalystModal"
                    isOpen={this.state.modal}
                    toggle={this.toggleModalAnalyst}
                >
                    {this.renderModal()}
                </Modal>
            </div>
        );
    }
}

export default CreateAnalystModal;