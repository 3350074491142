import React, { Component } from "react";
import '../../../styles/_inspections.scss'
import apicall from "../../../utils/apicall";
import Select from 'react-select';
import { toast } from "react-toastify";
import Loader from '../../helper_components/LoaderComponent'
import config from "../../../utils/config";
import {CustomInput} from 'reactstrap'

export default class Inspections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            propertyid: props.propertyid,
            categoryTypes: null,
            selectedOption: null,
            InspectioncategoriesArray: null,
            intervals: null,
            isLoading: true,
        }
    }

    componentWillMount() {
        apicall.getInspectionCategory(this.state.propertyid).then(
            resp => {
                this.setState({
                    categoryTypes: resp.results,
                    isLoading: false
                })
            }
        )
        this.listInspectionCategories(this.state.propertyid);
        this.getIntervals(this.state.propertyid);
    }

    notify = (msg) => toast(msg);

    createInspectionCategory = (propertyid, inspection_category) => {
        apicall.createInspectionCategory(this.state.propertyid, inspection_category).then(
            resp => {
                if (resp.hasOwnProperty('errors')) {
                    this.notify("Inspection Category Creation Failed");
                } else {
                    this.notify("Inspection Category Created");
                    this.listInspectionCategories(propertyid)
                }
            }
        )
    }

    listInspectionCategories = (propertyid) => {
        apicall.listInspectionCategories(propertyid).then(
            resp => {
                this.setState({
                    InspectioncategoriesArray: resp.results
                })
            }
        )
    }


    getIntervals = (propertyid) => {
        apicall.getIntervals(propertyid).then(
            resp => {
                this.setState({
                    intervals: resp
                })
            }
        )
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            this.setState({
                ...this.state,
                ...newprops
            });
        }
    }

    handleChange = (selectedvalue) => {
        let value = selectedvalue.value;
        this.setState({
            selectedOption: value
        })
    }


    handleIntervalChange = (inspectionid, event) => {
        let inspectionIntervalMap = {};
        inspectionIntervalMap['inspection_interval'] = event.value;
        apicall.updateInspectionCategory(this.state.propertyid, inspectionid, inspectionIntervalMap).then(
            resp => {
                if (resp.hasOwnProperty('errors')) {
                    this.notify("Interval Update Failed");
                } else {
                    this.notify("Interval updated");
                    this.listInspectionCategories(this.state.propertyid)
                }
            }
        )
    }

    handleSubmit = (event) => {
        let categoryMap = {};
        let propertyid = this.state.propertyid;
        categoryMap['inspection_category'] = this.state.selectedOption;
        this.createInspectionCategory(propertyid, categoryMap)
        event.preventDefault();
    }

    handleDelete = (inspectionid) => {
        apicall.deleteInspectionCategory(inspectionid, this.state.propertyid).then(
            resp => {
                if (resp.hasOwnProperty('errors')) {
                    this.notify("Deletion Failed");
                } else {
                    this.notify("Inspection Category deleted");
                    this.listInspectionCategories(this.state.propertyid)
                }
            }
        )
        //event.preventDefault();
    }

    listCategoriesDiv = () => {
        let InspectioncategoriesArray = this.state.InspectioncategoriesArray;
        let intervalsArray = this.state.intervals;
        let Inspectiondiv = InspectioncategoriesArray.map(
            (inspectionobject, index) => {
                let inspectionName = inspectionobject.inspection_name;
                let inspectionid = inspectionobject.id;
                let value = { 'label': inspectionobject.interval_name, 'value': inspectionobject.inspection_interval }
                if (value.label === null) {
                    value.label = 'Select Interval..';
                    value.value = 'null';
                }
                return (
                    <div className="intervals-info" key={'interval' + index}>
                        <div className="heading">
                            {inspectionName}
                        </div>
                        <div className="select-box">
                            {intervalsArray && <Select options={intervalsArray}
                                placeholder='Select Interval'
                                onChange={this.handleIntervalChange.bind(this, inspectionid)}
                                defaultValue={value} isDisabled={config['generalInfoRead'].includes(this.props.userRole) ? true : false}
                            />}
                        </div>
                        {/* <button onClick={this.handleDelete.bind(this, inspectionid)}>
                            delete
                        </button> */}
                    </div>
                )

            }
        )
        return Inspectiondiv;
    }

    render() {
        return (
            <>
                <div className="inspections">
                    {/* <div className="inspections-info">
                    {this.state.categoryTypes &&
                        <Select options={this.state.categoryTypes}
                            isSearchable
                            placeholder='Select Inspection Category Type'
                            onChange={this.handleChange}
                        />}
                    <button onClick={this.handleSubmit}>
                        Add
                    </button>
                </div> */}
                    {this.state.isLoading ? <Loader /> : (
                        <div className="inspections-intervals">
                            {this.state.InspectioncategoriesArray && this.listCategoriesDiv()}
                        </div>
                    )}
                </div>
            </>
        )
    }
}