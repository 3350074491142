import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from './components/page_layout_components/Login';
import PrivateRoute from './components/helper_components/PrivateRoute';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropertyUploadComponent from './components/properties/property-upload/PropertyUploadComponent'
import PropertyManageComponent from './components/properties/property-manage/PropertyManageComponent'
import ActionStatusUploadContainer from './components/properties/action-status-upload/ActionStatusUploadContainer'
import CreateAnalystComponent from './components/analysts/CreateAnalystComponent'
import DashboardContainer from './components/homepage/DashboardContainer'
import Reports from './components/reports/Reports'
import AreaAnalystsContainer from './components/area_analysts/AreaAnalystsContainer'
import SidebarComponent from './components/page_layout_components/SidebarComponent'
import NavbarComponent from './components/page_layout_components/NavbarComponent'
import apicall from './utils/apicall';
import './App.css'
import PropertySearchComponent from './components/properties/property-search/PropertySearchComponent'
import ManagmentCompaniesComponent from './components/management_companies/ManagementCompaniesComponent';
import HoasComponent from './components/hoas/HoasComponent';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import GeneralInformation from './components/EmailLinkAccess/GeneralInformation';
const RouteWithSidebar = ({ component: Component, ...props }) => {
  return (
    <PrivateRoute
      {...props}
      component={routeProps => (
        <React.Fragment>
          <NavbarComponent />
          <SidebarComponent />
          <div className="componentDiv">
            <Component {...routeProps} {...props} />
          </div>
        </React.Fragment>
      )}
    />
  )
}

const MINUTES_UNITL_AUTO_LOGOUT = 120 // in mins
const CHECK_INTERVAL = 15000 // in ms
const STORE_KEY = 'lastAction';


class App extends Component {
  constructor(props) {
    super(props);
    this.check();
    this.initListener();
    this.initInterval();
  }

  setLastAction(lastAction) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  initInterval() {
    setInterval(() => {
      this.checkDebounce();
    }, CHECK_INTERVAL);
  }

  reset() {
    this.setLastAction(Date.now());
  }
  getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }

  notify = () => toast('Session timeout!!! Please login again')

  checkDebounce = () => AwesomeDebouncePromise(this.check, CHECK_INTERVAL * 2);

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    if (isTimeout) {
      this.notify();
      apicall.logout();
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <RouteWithSidebar path="/homepage" component={DashboardContainer} />
          <RouteWithSidebar path="/property-upload" component={PropertyUploadComponent} />
          <RouteWithSidebar path="/action-status-upload" component={ActionStatusUploadContainer} />
          <RouteWithSidebar path="/users" component={CreateAnalystComponent} />
          <RouteWithSidebar path="/area-analysts" component={AreaAnalystsContainer} />
          <RouteWithSidebar exact path="/property-manage/" component={PropertyManageComponent} />
          <RouteWithSidebar path="/property-manage/:activeTab/:propertyID/:hoaID" component={PropertyManageComponent} />
          <RouteWithSidebar exact path="/property-search/:searchText/:page/:perPage" component={PropertySearchComponent} />
          <RouteWithSidebar path="/property-search" component={PropertySearchComponent} />
          <RouteWithSidebar path="/management-companies" component={ManagmentCompaniesComponent} />
          <RouteWithSidebar path="/hoas" component={HoasComponent} />
          <RouteWithSidebar path="/reports" component={Reports} />
          <Route path="/general-info" component={GeneralInformation}/>
        </Switch>
        <ToastContainer />
      </BrowserRouter>
    );
  }
}

export default App;
