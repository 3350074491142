import React from "react";
import FormComp from "../crud/FormComp";
import { FormGroup, Label, CustomInput, Input } from "reactstrap";
import Select from "react-select";
import apicall from "../../utils/apicall";
import DocumentUploadComponent from "../helper_components/DocumentUploadComponent";
import config from "../../utils/config";
export default class DocumentsModal extends FormComp {
  handleSelectChange = (selectedType) => {
    let selectedTypeValue = selectedType.value;
    let row = this.state.row;
    row["documents_attributes[][name]"] = selectedTypeValue;
    //row["documents_attributes[][category]"] = null;

    this.setState({
      selectedTypeValue,
      row,
      hideDocumentName: selectedTypeValue === "Others" ? false : true,
    });
  };

  handleAvailable = () => {
    this.setState({
      row: Object.assign(this.state.row, {
        "documents_attributes[][applicable]": !this.state.row[
          "documents_attributes[][applicable]"
        ],
      }),
    });
  };

  formElements = () => {
    var documentOptions = config.documentNames.map((c) => {
      return { label: c, value: c };
    });

    const categoryOptions = () => {
      let categories = [
        "Architectural",
        "Checklist",
        "Forms",
        "Governance",
        "Policies & Rules",
      ];
      if (this.state.selectedTypeValue === "Others") {
        categories = [
          "Annual Meetings",
          "Architectural",
          "Board Meetings",
          "Community Items",
          "Contracts",
          "Financial / Budget",
          "Forms",
          "Governing Documents",
          "Insurance",
          "Legal",
          "Newsletter",
          "Policies & Rules",
          "Questionnaire",
          "Reserve Study",
        ];
      }

      return categories.map((c) => {
        return { label: c, value: c };
      });
    };
    console.log(this.state.row["documents_attributes[][applicable]"]);
    return (
      <div>
        <FormGroup>
          <Label for="">Category</Label>
          <Select
            name="document_category"
            id="documents_attributes[][category]"
            value={{
              value: this.state.row["documents_attributes[][category]"],
              label: this.state.row["documents_attributes[][category]"],
            }}
            onChange={(selectedType) => {
              let row = this.state.row;
              row["documents_attributes[][category]"] = selectedType.value;
              this.setState({ row });
            }}
            options={categoryOptions()}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="">Name</Label>
          <Select
            name="document_name"
            id="documents_attributes[][name]"
            value={{
              value: this.state.row["documents_attributes[][name]"],
              label: this.state.row["documents_attributes[][name]"],
            }}
            onChange={this.handleSelectChange}
            options={documentOptions}
          />
        </FormGroup>
        <Select
          style={{ marginBottom: "10px" }}
          options={this.props.hoaOptions ? this.props.hoaOptions : []}
          onChange={(hoa) => {
            let row = this.state.row;
            row["documents_attributes[][hoa_id]"] = hoa && hoa.value;
            hoa && this.setState({ row });
          }}
          placeholder="Select Hoa..."
          className="property-hoa-select"
          isClearable
        />
        <FormGroup>
          <CustomInput
            style={{ padding: "15px" }}
            type="checkbox"
            name="documents_attributes[][applicable]"
            id="documents_attributes[][applicable]"
            label={"Not Applicable"}
            value={false}
            onClick={this.handleAvailable}
            checked={
              this.state.row["documents_attributes[][applicable]"]
                ? true
                : false
            }
          />
        </FormGroup>

        <DocumentUploadComponent
          row={this.state.row}
          hideDocumentName={this.state.hideDocumentName}
          onChange={this.handleChange}
          showDocuments={true}
          notApplicable={this.state.row["documents_attributes[][applicable]"]}
        />
      </div>
    );
  };

  handleSubmit = () => {
    this.props.isPayment
      ? apicall
          .paymentUpdate(
            this.state.row,
            this.props.propertyID,
            this.props.actionID
          )
          .then((response) => {
            if (response) {
              this.notify("Document Uploaded Successfully.");
              this.setState({
                row: response,
              });
              this.props.toggle();
              this.props.fetchDocuments();
            }
          })
      : apicall
          .propertyDocumentUpload(this.state.row, this.state.propertyID)
          .then((response) => {
            if (response) {
              this.notify("Document Uploaded Successfully.");
              this.setState({
                row: response,
              });
              this.props.toggle();
              this.props.fetchDocuments();
            }
          });
  };
}
