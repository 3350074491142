import React, { useState } from "react";
import AsyncPaginate from "react-select-async-paginate";
import loadOptions from "./loadOptions";

const defaultAdditional = {
  page: 1
};

const customStyles = {
  container: (provided, state) => ({
      ...provided,
      width: '100%'
  })
}
const loadPageOptions = async (q, prevOptions, { page }) => {
  const { options, hasMore } = await loadOptions(q, page);
  return {
    options,
    hasMore,

    additional: {
      page: page + 1
    }
  };
};

export default function AsyncSelectManagementCompany(props) {
  const [value, onChange] = useState(null);

  return (
    <div>
      <AsyncPaginate
        additional={defaultAdditional}
        value={value}
        loadOptions={loadPageOptions}
        onChange={value => {
          props.handleOnChange(props.id, value);
        }}
        isDisabled={props.isDisabled}
        isRequired={props.isRequired}
        isClearable={props.isClearable}
        className='react-select-container'
        styles={customStyles}
        value={props.valueShown}
      />
    </div>
  );
}
