import config from '../config';
import { withRouter } from 'react-router';
import helpers from '../helpers';
var amenitiesapi = {

    getAmenities: async function (propertyID) {
        let url = config.baseUrl + '/api/v1/amenities?property_id=' + propertyID;
        let init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        return await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getAmenities' + response)
            }).catch(function (error) {


            })
    },
    updateAmenities: async function (propertyID, amenities, utilities) {
        let url = config.baseUrl + '/api/v1/amenities/bulk_update?property_id=' + propertyID;

        const requestObject = {amenities: {amenities: amenities, utilities: utilities}}
        var init = {
            method: "PUT",
            headers: await helpers.defaultHeaders(),
            body: JSON.stringify(requestObject)
        };
        return await fetch(url, init)
            .then(response => {
                return response.json();
            }).catch(function (error) {

            })
    },
}
export default withRouter(amenitiesapi);