import config from './config';
import { withRouter } from 'react-router';
import helpers from './helpers';
import Propertyapi from './api/Propertyapi';
import lienapi from './api/lienapi';
import analystapi from './api/analystapi';
import leaseapi from './api/leaseapi';
import hoaapi from './api/hoaapi';
import managementapi from './api/managementapi';
import inspectioncategoryapi from './api/inspectioncategoryapi';
import violationapi from './api/violationapi';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import accesscodesapi from './api/accesscodesapi';
import actionsapi from './api/actionsapi';
import inspectionapi from './api/inspectionapi';
import tenantmoveinapi from './api/tenantmoveinapi';
import paymentsapi from './api/paymentsapi';
import attorneyapi from './api/attorneyapi';
import dashboardapi from './api/dashboardapi';
import notesapi from './api/notesapi';
import reportsapi from './api/reportsapi';
var apicall = {
    getOauthToken: async function (email, password, props) {
        var url = config.baseUrl + "/api/v1/auth/sign_in";

        var requestObject = {
            'email': email,
            'password': password
        }
        var init = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestObject)
        };

        var loginResp = null;
        await fetch(url, init)
            .then(response => {
                if (response.ok) {

                    let accessToken = response.headers.get(config.accessToken);
                    let uid = response.headers.get('uid');
                    let expiry = response.headers.get('expiry');
                    let client = response.headers.get('client');
                    if (typeof accessToken !== "undefined" && accessToken !== null) {

                        config.localforage.setItem(config.accessToken, accessToken);
                        config.localforage.setItem("tokenDate", new Date());
                        config.localforage.setItem("email", email);
                        config.localforage.setItem("uid", uid);
                        config.localforage.setItem('expiry', expiry);
                        config.localforage.setItem('client', client);
                        //Wait till the data is stored
                        setTimeout(() => {
                            props.history.push("/homepage");
                        }, 600)

                    }
                    return response.json()
                } else {
                    return response.json()
                }
            }).then(
                responsejson => {
                    loginResp = responsejson;
                }
            )
            .catch(function (error) {

            });
        return loginResp;
    },

    logout: function (props) {
        config.localforage.clear();
        window.location.href = config.loginUrl;
    },

    getSearchQuestions: async function (clientId) {
        var questions = {};
        let url = config.baseUrl + '/api/v1/properties/' + clientId + '/hoa_questions';
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while getting search questions' + response)
            }).then(questionsJson => {
                questions = questionsJson;
            }).catch(function (error) {

            })
        return questions;
    },
    ...Propertyapi,
    ...lienapi,
    ...analystapi,
    ...leaseapi,
    ...hoaapi,
    ...managementapi,
    ...inspectioncategoryapi,
    ...violationapi,
    ...accesscodesapi,
    ...actionsapi,
    ...inspectionapi,
    ...tenantmoveinapi,
    ...paymentsapi,
    ...attorneyapi,
    ...dashboardapi,
    ...notesapi,
    ...reportsapi
}
//500ms lock to call the specific api methods to avoid calling the same API again and again
Object.keys(apicall).map(function (key, index) {
    apicall[key] = AwesomeDebouncePromise(apicall[key], config.debounceTimer);
});
export default withRouter(apicall);