import React from "react";
import _ from "lodash";
function SelectOptions(props) {
  let optionsArray = [];
  optionsArray.push(
    <option value="" key="defaultValue">
      Select...
    </option>
  );
  if (_.isArray(props.options)) {
    props.options.map((title, index) => {
      optionsArray.push(
        <option value={title} key={title + index}>
          {title}
        </option>
      );
    });
  } else {
    for (let option in props.options) {
      optionsArray.push(
        <option value={option} key={option}>
          {props.options[option]}
        </option>
      );
    }
  }

  return <>{optionsArray}</>;
}

export default SelectOptions;
