import React, { Component } from "react";
import { Link } from 'react-router-dom';
import '../../styles/sidebar.css';
import config from '../../utils/config';
import { FaChevronDown } from 'react-icons/fa';
export default class SidebarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            propertyDropDown: false,
            userRole: ""
        }
        config.localforage.getItem('user_role').then(role => {
            this.setState({ userRole: role })
        }).catch(e => console.log(e))
    }

    showMore = (event) => {
        const id = event.target.id;

        this.setState({
            [id]: !this.state[id]
        })
    }

    sidebarTapAction = (event) => {

    }
    render() {

        const render_users_analysts_link = () => {
            if (config['propertyUpload'].includes(this.state.userRole)) {
                return (
                    <React.Fragment>
                        <Link to="/users" onClick={this.sidebarTapAction}>
                            <li className="list-item">
                                <img className="" src="" alt="" />Users
                                </li>
                        </Link>
                        <Link to="/area-analysts" onClick={this.sidebarTapAction}>
                            <li className="list-item">
                                <img className="" src="" alt="" />Area Analysts
                            </li>
                        </Link>
                    </React.Fragment>
                )
            } else {
                return null
            }
        }

        return (
            <React.Fragment>
                <div className="sidebar-section">
                    <aside className="sidebar">
                        <div className="scrollbar-inner">
                            <ul className="navigation">

                                <Link to="/homepage" id='dashboard' onClick={this.sidebarTapAction}>
                                    <li className="list-item" >
                                        <img className="" src="" alt="" />Dashboard
                                        </li>
                                </Link>

                                {render_users_analysts_link()}
                                <div className="customDropdown">
                                    <div className="button" id="propertyDropDown" onClick={(event) => this.showMore(event)}>Properties <FaChevronDown /> </div>
                                    <div className={"dropdown-content " + (this.state.propertyDropDown ? "unhide" : '')}>
                                        {
                                            (config['propertyUpload'].includes(this.state.userRole)) ?
                                                <Link className="link" to="/property-upload">
                                                    <img className="" src="" alt="" />Property Upload
                                            </Link> : null
                                        }
                                        <Link className="link" to="/property-search">
                                            <img className="" src="" alt="" />Property Search
                                        </Link>
                                        <Link className="link" to="/action-status-upload">
                                            <img className="" src="" alt="" />Action Status Upload
                                        </Link>
                                    </div>
                                </div>
                                <>
                                    <Link to={"/management-companies/" + this.state.userRole}>
                                        <li className="list-item">
                                            <img className="" src="" alt="" />Management Companies
                                        </li>
                                    </Link>
                                    <Link to={"/hoas/" + this.state.userRole}>
                                        <li className="list-item">
                                            <img className="" src="" alt="" />HOA
                                            </li>
                                    </Link>

                                    <Link to={"/reports"}>
                                        <li className="list-item">
                                            <img className="" src="" alt="" />Reports
                                        </li>
                                    </Link>
                                </>
                            </ul>
                        </div>
                    </aside>
                </div>
            </React.Fragment >
        )
    }
}
