import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input } from 'reactstrap';
import apicall from '../../../utils/apicall';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TextValidator from '../../helper_components/TextValidator';
import { ValidatorForm } from 'react-form-validator-core';
import { toast } from 'react-toastify';
import helpers from '../../../utils/helpers'
import _ from 'lodash'

class CreatePropertyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState()
        this.state = {
            ...this.state,
            ...this.props
        };


    }

    initialState() {
        return {
            createPropertyModal: false,
            clientid: '',
            hbid: '',
            transfer_date: new Date(),
            entity_name: '',
            property_group: '',
            transfer_group: '',
            acquisition_type: '',
            acquisition_date: new Date(),
            status: '',
            mortgage_amount: '',
            street_address: '',
            address_2: '',
            city: '',
            state: '',
            zip: '',
            county: '',
            propertyid: ''
        }
    }

    toggleCreateProperty = () => {
        this.state.toggle();
    }

    componentWillReceiveProps(newprops) {
        if (!_.isEqual(newprops, this.props)) {
            this.setState({
                ...this.state,
                ...newprops
            });
            //            console.log(newprops);
            if (typeof newprops.property !== "undefined" && newprops.property !== null) {
                //               console.log(newprops.property);
                this.setState({
                    propertyid: newprops.property.id,
                    'clientid': newprops.property.clientid,
                    'hbid': newprops.property.hbid,
                    'transfer_group': newprops.property.transfer_group,
                    'transfer_date': newprops.property.transfer_date,
                    'entity_name': newprops.property.entity_name,
                    'property_group': newprops.property.property_group,
                    'acquisition_type': newprops.property.acquisition_type,
                    'acquisition_date': newprops.property.acquisition_date,
                    'status': newprops.property.status,
                    'mortgage_amount': newprops.property.mortgage_amount,
                    'street_address': newprops.property.street_address,
                    'address_2': newprops.property.address_2,
                    'city': newprops.property.city,
                    'state': newprops.property.state,
                    'zip': newprops.property.zip,
                    'county': newprops.property.county
                })
            }
        }
    }

    handleChange = (event) => {
        let value = event.target.value.replace(/  +/g, ' ');
        this.setState({
            [event.target.id]: value
        });
    }

    handleTransferDate = (date) => {
        this.setState({
            transfer_date:date
        })
    }

    handleForeclosureDate = (date) => {
        this.setState({
            acquisition_date: date
        })
    }

    notify = (errorMsg) => toast(errorMsg);

    handleSubmit = (event) => {
        let callUpdateAPI = this.state.updateProperty;
        var requestObject = {
            'clientid': this.state.clientid,
            'hbid': this.state.hbid,
            'transfer_date': helpers.formatApiDate(this.state.transfer_date),
            'transfer_group': this.state.transfer_group,
            'entity_name': this.state.entity_name,
            'property_group': this.state.property_group,
            'acquisition_type': this.state.acquisition_type,
            'acquisition_date': helpers.formatApiDate(this.state.acquisition_date),
            'status': this.state.status,
            'mortgage_amount': this.state.mortgage_amount,
            'street_address': this.state.street_address,
            'address_2': this.state.address_2,
            'city': this.state.city,
            'state': this.state.state,
            'zip': this.state.zip,
            'county': this.state.county
        }
        if (!callUpdateAPI) {
            apicall.createProperty(requestObject).then(
                result => {
                    this.notify("Property Created Successfully");
                    this.toggleCreateProperty();
                    this.state.getProperties();
                    this.setState(this.initialState())

                }
            ).catch(e => console.log(e))
        } else {
            apicall.updateProperty(requestObject, this.state.propertyid).then(
                response => {
                    if (response !== null && response.hasOwnProperty('errors')) {
                        let errorMsg = helpers.getErrorMsg(response.error);
                        if (errorMsg)
                            this.notify(errorMsg);

                    } else {
                        this.notify("Property Updated Successfully");
                        this.toggleCreateProperty();
                        this.state.reloadProperty(this.state.propertyid)
                        this.setState(this.initialState())
                    }
                }
            )
            // this.state.getProperties();
            event.preventDefault();
        }
    }

    renderModal = () => {
        let callUpdateAPI = this.state.updateProperty;
        let headerText = '';
        if (callUpdateAPI) {
            headerText = 'Update Property'
        } else {
            headerText = 'Create Property'
        }
        ValidatorForm.addValidationRule('isZipcodeMatch', (value) => {
            var zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
            return zipCodePattern.test(value);
        });
        ValidatorForm.addValidationRule('isNotNumber', (value) => {
            return isNaN(value);
        });
        return (
            <div>
                <ModalHeader toggle={this.toggleCreateProperty}>
                    <div className="">
                        <span className="heading">
                            {headerText}
                        </span>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <ValidatorForm ref="form" onSubmit={this.handleSubmit} autocomplete="off">
                        <FormGroup>
                            <Label for="">Client Id *</Label>
                            <TextValidator type="text" name="clientid" id="clientid" placeholder="Client ID*" onChange={this.handleChange} value={this.state.clientid}
                                validators={['required', 'maxStringLength: 50']} errorMessages={['Client Id is required', 'Should not exceed 50 characters']} />
                        </FormGroup>
                        {
                            !(callUpdateAPI) &&
                            <FormGroup>
                                <Label for="">HBID *</Label>
                                <TextValidator type="text" name="hbid" id="hbid" onChange={this.handleChange} validators={['required', 'maxStringLength: 50']} value={this.state.hbid}
                                    errorMessages={['HBID is required', 'Should not exceed 50 characters']} />
                            </FormGroup>
                        }
                        <FormGroup>
                            <Label for="" style={{ 'marginRight': '10px' }}>Transfer Date *</Label>
                            <DatePicker id="transfer_date" selected={this.state.transfer_date ? helpers.restructureDate(this.state.transfer_date) : null} onChange={this.handleTransferDate} 
                            // value={this.state.transfer_date} 
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Transfer Group *</Label>
                            <TextValidator type="text" name="transfer_group" id="transfer_group" onChange={this.handleChange} validators={['required', 'maxStringLength: 50']} value={this.state.transfer_group}
                                errorMessages={['is required', 'Should not exceed 50 characters']} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Entity name *</Label>
                            <TextValidator type="text" name="entity_name" id="entity_name" onChange={this.handleChange} validators={['required', 'maxStringLength: 50']} value={this.state.entity_name}
                                errorMessages={['Entity name is required', 'Should not exceed 50 characters']} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Property Group *</Label>
                            <TextValidator type="text" name="property_group" id="property_group" onChange={this.handleChange} validators={['required', 'maxStringLength: 50']} value={this.state.property_group}
                                errorMessages={['is required', 'Should not exceed 50 characters']} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Acquisition Type *</Label>
                            <Input type="select" name="acquisition_type" id="acquisition_type" onChange={this.handleChange} value={this.state.acquisition_type} required>
                                <option value=''>Select</option>
                                <option>MLS</option>
                                <option>Foreclosure</option>
                                <option>DIL</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="" style={{ 'marginRight': '10px' }}>Acquisition Date *</Label>
                            <DatePicker id="acquisition_date" selected={this.state.acquisition_date ? helpers.restructureDate(this.state.acquisition_date) : null} 
                            onChange={this.handleForeclosureDate} 
                            // value={this.state.acquisition_date}
                         />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Status *</Label>
                            <Input type="select" name="status" id="status" onChange={this.handleChange}
                                value={this.state.status} required>
                                <option value=''>Select</option>
                                <option value='active'>Active</option>
                                <option value='sold'>Sold</option>
                                <option value='rescinded'>Rescinded</option>
                                <option value='not managed'>Not Managed</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Mortgage Amount</Label>
                            <TextValidator type="text" name="mortgage_amount" id="mortgage_amount" onChange={this.handleChange} validators={['isFloat']} value={this.state.mortgage_amount}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Street Address *</Label>
                            <TextValidator type="text" name="street_address" id="street_address" onChange={this.handleChange} validators={['required', 'maxStringLength: 50']} value={this.state.street_address}
                                errorMessages={['Street Address is required', 'Should not exceed 50 characters']} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Address 2</Label>
                            <TextValidator type="text" name="address_2" id="address_2" onChange={this.handleChange} value={this.state.address_2} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">City *</Label>
                            <TextValidator type="text" name="city" id="city" onChange={this.handleChange} validators={['required', 'maxStringLength: 50','isNotNumber']} value={this.state.city}
                                errorMessages={['City is required', 'Should not exceed 50 characters', "Enter valid City"]} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">State *</Label>
                            <TextValidator type="text" name="state" id="state" onChange={this.handleChange} validators={['required', 'maxStringLength: 50','isNotNumber']} value={this.state.state}
                                errorMessages={['State is required', 'Should not exceed 50 characters', "Enter valid State"]} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">Zip *</Label>
                            <TextValidator type="text" name="zip" id="zip" onChange={this.handleChange} validators={['required', 'isZipcodeMatch']} value={this.state.zip}
                                errorMessages={['zip is required', 'Zip Code Should be XXXXX']} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="">County *</Label>
                            <TextValidator type="text" name="county" id="county" onChange={this.handleChange} validators={['required', 'maxStringLength: 50','isNotNumber']} value={this.state.county}
                                errorMessages={['county is required', 'Should not exceed 50 characters', 'Enter valid county']} />
                        </FormGroup>
                        <Button disabled={(this.state.acquisition_date && this.state.transfer_date) ? false : true}>Submit</Button>
                    </ValidatorForm>
                </ModalBody>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Modal
                    className="createPropertyModal"
                    isOpen={this.state.modal}
                    toggle={this.toggleCreateProperty}
                >
                    {this.renderModal()}
                </Modal>
            </div>
        );
    }
}

export default CreatePropertyModal;