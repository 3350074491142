import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Link } from 'react-router-dom'
import config from '../../../utils/config';
import PaginationComponent from "../../helper_components/PaginationComponent";
export default class PropertySearchTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openQuestionsModal: false,
            modalAnalystsWindow: new Array(20).fill(false),
            rowIndex: 0,
            row: {},
            aerialSearchid: '',
            userId: null
        }
        this.state = {
            ...this.state,
            ...this.props
        };
    }

    componentDidMount() {
        config.localforage.getItem('userid').then(
            resp => {
                this.setState(
                    {
                        userId: resp
                    }
                )
            }
        )
    }
    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            this.setState({
                ...this.state,
                ...newprops
            });
        }
    }

    toggleQuestionsModal = () => {
        this.setState({
            openQuestionsModal: !this.state.openQuestionsModal
        });
    }

    linkFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (typeof row !== "undefined") {
            return (
                <Link to={"/property-manage/2/" + row.id + "/0"} key={cell + row.id}>
                    {cell}
                </Link>
            )
        }
        return null;
    }
    render() {
        let properties = this.state.properties;
        const columns = [{
            dataField: 'clientid',
            text: 'Client ID',
            formatter: this.linkFormatter,
        },
        {
            dataField: 'street_address',
            text: 'Street Address'
        },
        {
            dataField: 'address2',
            text: 'Address 2'
        },
        {
            dataField: 'city',
            text: 'City'
        },
        {
            dataField: 'state',
            text: 'State'
        },
        {
            dataField: 'zip',
            text: 'Zip'
        },
        {
            dataField: 'county',
            text: 'County'
        }
        ];

        return (
            <>
                <PaginationComponent columns={columns} rows={properties} 
                    handleSubmit={this.props.handleSubmit} pageNo={this.props.pageNo} 
                    perPage={this.props.page} totalCount={this.props.totalCount}/>
            </>
        )
    }
}



