import React from "react";
import {
  Button,
  FormGroup,
  Label,
  ModalHeader,
  ModalBody,
  Modal,
  Input,
  Row,
  Col,
} from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import TextValidator from "../helper_components/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import apicall from "../../utils/apicall";
import helpers from "../../utils/helpers";
import "../../styles/_editAddress.scss";
export default class EditAddressModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row: {},
      title: props.title,
    };
    console.log(props);
  }

  toggleModal = () => {
    this.props.toggle();
  };

  componentWillMount() {
    var data = this.props.getAddressAndAccount(
      this.props.payee_recipient.value
    );
    let newRow = {
      payment_address_care_of: data.payment_address_care_of,
      payment_mailing_street: data.payment_mailing_street,
      payment_mailing_city: data.payment_mailing_city,
      payment_mailing_state: data.payment_mailing_state,
      payment_mailing_zipcode: data.payment_mailing_zipcode,
    };
    this.setState({
      row: Object.assign(this.state.row, newRow),
    });
  }

  handleChange = (event,isZip) => {
    let targetID = event.target.id;
    let value = event.target.value;
    if(isZip && value.length > 5 ){
      console.log("zip value ---->",value)
          var len = value.length
          if(value.substring(5,6) != "-"){
             // console.log("indise substrin IF")
              var res = value.substring(0,5) + "-" + value.substring(5,len);
          }else{
              var res = value
          }
          this.setState({
              row: Object.assign(this.state.row, { [targetID]: res })
          });
      
  }else{
      this.setState({
          row: Object.assign(this.state.row, { [targetID]: value })
      });
  }
    // this.setState({
    //   row: Object.assign(this.state.row, {
    //     [event.target.id]: event.target.value,
    //   }),
    // });
  };

  handleAddressSubmit = (event) => {
    let row = this.state.row;
    let payee_recipient_id = this.props.payee_recipient.value;
    let payee_recipient_type = this.props.payee_recipient_type;
    row["id"] = payee_recipient_id;
    let updateApi = null;
    if (payee_recipient_type === "Hoa") {
      updateApi = "updateHoa";
    } else if (payee_recipient_type === "ManagementCompany") {
      updateApi = "updateManagementCompany";
    } else {
      updateApi = "updateAttorney";
    }
    apicall[updateApi](row, this.props.propertyID).then((response) => {
      if (response) {
        this.props.updateAddress(response);
        helpers.notify("Updated successfully.");
        this.toggleModal();
      }
    });
    event.preventDefault();
  };

  renderModal = () => {
    return (
      <div>
        <ModalHeader toggle={this.toggleModal}>
          <div className="">
            <span className="heading">{"Update Payment Mailing Address"}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <ValidatorForm onSubmit={this.handleAddressSubmit} autocomplete="off">
            <FormGroup row>
              <Label for="" sm={2}>
                {this.props.payee_recipient_type + " Name"}
              </Label>
              <Col sm={10}>
                <Input disabled value={this.props.payee_recipient.label} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="" sm={2}>
                C/O Name *
              </Label>
              <Col sm={10}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText
                      style={{ borderRadius: "14px 0px 0px 14px" }}
                    >
                      C/o
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="address-name"
                    name="payment_address_care_of"
                    id="payment_address_care_of"
                    placeholder="Mailing contact name"
                    onChange={this.handleChange}
                    validators={["required"]}
                    errorMessages={["Mailing contact name is required"]}
                    value={this.state.row.payment_address_care_of}
                  />
                </InputGroup>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="" sm={2}>
                Address *
              </Label>
              <Col sm={10}>
                <TextValidator
                  type="text"
                  name="payment_mailing_street"
                  id="payment_mailing_street"
                  placeholder="Mailing street name"
                  onChange={this.handleChange}
                  validators={["required"]}
                  errorMessages={["Mailing street name is required"]}
                  value={this.state.row.payment_mailing_street}
                />
              </Col>
            </FormGroup>
            <div>
              <Row form>
                <Col md={6}>
                  <FormGroup row>
                    <Label for="" sm={4}>
                      City
                    </Label>
                    <Col sm={8}>
                      <TextValidator
                        type="text"
                        name="payment_mailing_city"
                        id="payment_mailing_city"
                        placeholder="Mailing city"
                        onChange={this.handleChange}
                        validators={["required"]}
                        errorMessages={["Mailing city is required"]}
                        value={this.state.row.payment_mailing_city}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup row>
                    <Label for="" sm={4}>
                      State
                    </Label>
                    <Col sm={8}>
                      <TextValidator
                        type="text"
                        name="payment_mailing_state"
                        id="payment_mailing_state"
                        placeholder="Mailing state"
                        onChange={this.handleChange}
                        validators={["required"]}
                        errorMessages={["Mailing state is required"]}
                        value={this.state.row.payment_mailing_state}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup row>
                    <Label for="" sm={2}>
                      Zip
                    </Label>
                    <Col sm={10}>
                      <TextValidator
                        type="text"
                        name="payment_mailing_zipcode"
                        id="payment_mailing_zipcode"
                        placeholder="XXXXX-XXXX"
                        maxLength="10"
                        onChange={(event) => this.handleChange(event,true)}
                        validators={["required", "isAddressZipcodeMatch"]}
                        errorMessages={["zip is required", "Invalid Zip Code"]}
                        value={this.state.row.payment_mailing_zipcode}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <Button>Submit</Button>
          </ValidatorForm>
        </ModalBody>
      </div>
    );
  };

  render() {
    console.log(this.state.row);
    return (
      <div>
        <Modal className="edit-address" isOpen={true} toggle={this.toggleModal}>
          {this.renderModal()}
        </Modal>
      </div>
    );
  }
}
