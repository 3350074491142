import React, { Component } from "react";
import '../../../styles/content.css'
import { Form, FormGroup, Label, Input, Col, Button } from 'reactstrap'
import apicall from '../../../utils/apicall'
import helpers from '../../../utils/helpers'
import PropertySearchTable from './PropertySearchTable'
import { toast } from "react-toastify";
import _ from 'lodash';
import config from '../../../utils/config'
export default class PropertySearchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientid: '',
            hoa: '',
            address: '',
            properties: [],
            isSearch: false,
            showSearchResult: false,
            pageNo: 1,
            perPage: 10
        }
    }


    getProperties = () => {
        apicall.getProperties().then(
            response => {
                this.setState({
                    properties: response.results,
                    totalCount:response.total,
                    pageNo: 1,
                    perPage: 10
                })
            }
        )
    }

    componentWillReceiveProps(newProps){
        console.log('componentWillReceiveProps',newProps)
    }

    componentDidMount() {
        //this.getProperties();
        console.log('componentDidMount');
        
       
        if(this.props.match){
            let searchtext = this.props.match.params.searchText; 
            console.log(this.props.match)
            console.log('searchtext',searchtext)           
            config.localforage.getItem('searchResult').then(searchresult => {
                this.setState({ properties: searchresult.results,
                showSearchResult:true,totalCount:searchresult.total})
            }).catch(e => console.log(e)) 
            config.localforage.getItem('page-number').then(pageNo => {
                console.log('page',pageNo)
                this.setState({ pageNo: pageNo})
            }).catch(e => console.log(e))
            config.localforage.getItem('per-page').then(perPage => {
                console.log('perpage',perPage)
                this.setState({ perPage: perPage})
            }).catch(e => console.log(e))
            
            this.setState({
                address: searchtext               
            })
        }
       
    }
   


    notify = (message) => toast(message);

    handleSubmit = (event, pageNo, perPage) => {
        config.localforage.setItem("search-text",this.state.address);
        config.localforage.setItem("page-number",pageNo);
        config.localforage.setItem("per-page",perPage);
    
        this.setState({
            pageNo: pageNo,
            perPage:perPage
        });
           
        this.state.address && apicall.searchProperties(this.state.address,pageNo, perPage).then(
            response => {
                if (response !== null && response.hasOwnProperty('error')) {
                    let errorMsg = helpers.getErrorMsg(response.error);
                    if (errorMsg)
                        this.notify("Search Failed: No Records Found: " + errorMsg);
                } else {
                    if (typeof response !== 'undefined' && response.length === 0) {
                        this.notify("No Records Found: ");
                    } else {
                        if (_.isString){
                        config.localforage.setItem("searchResult",response); 
                        
                        }
                        this.setState({
                            properties: response.results,
                            showSearchResult: true,
                            totalCount: response.total
                        })
                    }
                }
            }
        );
        event.preventDefault();
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    render() {
        return (
            <React.Fragment>
                <section className="content">
                    <header className="content-title">
                        <h2 className='page-heading'><strong>Property Search</strong></h2>
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup row>
                                <Col sm={8}>
                                    <Input type="address" name="address" id="address" value={this.state.address} placeholder="Search" onChange={this.handleChange} />
                                </Col>
                                <Col sm={2}>
                                    <Button className='search-btn'>Search</Button>
                                </Col>
                            </FormGroup>
                        </Form>
                    </header>
                    <div className="search-result">
                        {this.state.showSearchResult && <PropertySearchTable properties={this.state.properties} handleSubmit={this.handleSubmit}
                            pageNo={this.state.pageNo}  page={this.state.perPage} totalCount={this.state.totalCount}/>}
                    </div>
                </section>
            </React.Fragment>
        )
    }
}