import React, { Component } from "react";
import '../../styles/_general.scss';
import AccessCodes from './access_codes/AccessCodes'
import DisplayHOA from './hoa_info/DisplayHOA'
import FinanceLeaseInfo from './lease_info/FinanceLeaseInfo'
import Amenities from './Amenities'
import TabsComponent from '../helper_components/TabsComponent'
import Inspections from './inspections/Inspections'
import TenantMoveInCheckList from "./tenant/TenantMoveInCheckList";
import AttorneysComponent from "../attorneys/AttorneysComponent"
import config from "../../utils/config";
export default class GeneralComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            property: null,
            propertyid: props.propertyID,
            activeTab: 1
        }
    }

    render() {
        let listofTabs = '';
        if(config['LeaseHide'].includes(this.props.userRole)){
            listofTabs = ['HOA Information', 'Access Codes', 'Inspections', 'Utilities & Amenities', 'Tenant Move-in Check', 'Attorneys'];
        }else{
            listofTabs = ['HOA Information', 'Access Codes', 'Inspections', 'Utilities & Amenities', 'Tenant Move-in Check', 'Attorneys','Leasing Information'];
        }
        let tabsComponentsList = new Array(listofTabs.length).fill(null);
        tabsComponentsList[0] = <DisplayHOA propertyid={this.state.propertyid}
            userRole={this.props.userRole} getActions={this.props.getActions}/>
        tabsComponentsList[1] = <AccessCodes propertyid={this.state.propertyid}
        userRole={this.props.userRole} getPropertyInfo={this.props.getPropertyInfo} />
        tabsComponentsList[2] = <Inspections propertyid={this.state.propertyid}
        userRole={this.props.userRole} />
        tabsComponentsList[3] = <Amenities propertyid={this.state.propertyid}
        userRole={this.props.userRole} />
        tabsComponentsList[4] = <TenantMoveInCheckList propertyid={this.state.propertyid}
        userRole={this.props.userRole}/>
        tabsComponentsList[5] = <AttorneysComponent propertyid={this.state.propertyid}
        userRole={this.props.userRole} />
        tabsComponentsList[6] = <FinanceLeaseInfo propertyid={this.state.propertyid}
        userRole={this.props.userRole} />
        return (
            <React.Fragment >
                <div className="general-component">
                    <TabsComponent theme="tab" components={tabsComponentsList} listofTabs={listofTabs}  generateTabURL={false}
                        activeTab={this.state.activeTab} theme={'general-info-tab'} />
                </div>
            </React.Fragment>
        )
    }
}
