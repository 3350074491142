import config from '../config';
import { withRouter } from 'react-router';
import helpers from '../helpers';
var leaseapi = {
    getLeaseInfo: async function (propertyId) {
        var leaseInfo = [];
        let url = config.baseUrl + '/api/v1/leasing_property_accesses?property_id=' + propertyId;
        var init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error('Something went wrong, while getting access codes' + response)
            }).then(responseJson => {
                leaseInfo = responseJson;
            }).catch(function (error) {

            })

        return leaseInfo;
    },
    createLeaseInfo: async function (leaseInfo, propertyid) {
        let url = config.baseUrl + '/api/v1/leasing_property_accesses?property_id=' + propertyid
        var requestObject = leaseInfo;
        var init = {
            method: "POST",
            headers: await helpers.defaultHeaders(),
            body: JSON.stringify(requestObject)
        };
        var leaseResponse = null;
        await fetch(url, init)
            .then(response => {
                return response.json();
            }).then(
                responsejson => {
                    leaseResponse = responsejson;
                }
            )
            .catch(function (error) {

            })
        return leaseResponse;
    },
    updateleaseInfo: async function (leaseInfo, propertyid, leaseInfoId) {
        let url = config.baseUrl + '/api/v1/leasing_property_accesses/' + leaseInfoId + '?property_id=' + propertyid;
        var requestObject = leaseInfo;
        var init = {
            method: "PUT",
            headers: await helpers.defaultHeaders(),
            body: JSON.stringify(requestObject)
        };
        var updateLease = null;
        await fetch(url, init)
            .then(response => {
                return response.json();
            }).then(
                responsejson => {
                    updateLease = responsejson;
                }
            )
            .catch(function (error) {

            })
        return updateLease;
    },
    deleteLeaseInfo: async function (leaseInfoId, propertyid) {
        var res = '';
        let url = config.baseUrl + '/api/v1/leasing_property_accesses/' + leaseInfoId + '?property_id=' + propertyid;
        var init = {
            method: "DELETE",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while deleting lease info' + response)
            }).then(a => {
                res = a;
            }).catch(function (error) {

            })
        return res;
    }
}
export default withRouter(leaseapi);