import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import apicall from '../../utils/apicall';
import ListComp from '../crud/ListComp';
import helpers from '../../utils/helpers';
import { FaLink, FaCheckSquare, FaTimesCircle } from 'react-icons/fa';
import AttorneyModal from './AttorneyModal'
export default class AttorneysListTable extends ListComp {

    onSuccess = () => {
        helpers.notify("File URL copied to ClipBoard")
    }

    applicableFormatter = () => {
        return (
            <div style={{ textAlign: "center" }}>
                <FaCheckSquare size="2em" />
                {/* <FaTimesCircle size="2em" /> */}
            </div>
        )
    }

    deleteRecord = (row) => {
        apicall.deleteAttorney(row.id, this.props.propertyid).then(
            response => {
                if (response) {
                    helpers.notify("Deleted successfully");
                    this.state.refreshList();
                }
            }
        );
    }

    columns = () => {
        return [{
            dataField: 'id',
            text: 'ID',
        }, {
            dataField: 'information',
            text: 'Information',
            formatter: this.cellFormatter
        }, {
            dataField: 'name',
            text: 'Name',
            formatter: this.cellFormatter

        },

        {
            dataField: 'contact_phone',
            text: 'Phone Number',
            formatter: this.cellFormatter
        }, {
            dataField: 'contact_email',
            text: 'Email'
        },

        {
            dataField: 'contact_fax',
            text: 'Fax',
            formatter: this.cellFormatter
        },

        {
            dataField: 'mailing_contact_name',
            text: 'Mailing Address',
            formatter: this.mailAddressFormatter
        }, {
            dataField: 'website',
            text: 'Website',
        }, {
            dataField: 'status',
            text: 'Status',
        }
            , {
            dataField: "action",
            text: "Actions",
            sort: false,
            formatter: this.buttonFormatter,
            headerAttrs: { width: 200 },
            attrs: { width: 200, class: "table-actions" }
        }
        ];
    }


    formComp = () => {
        return <AttorneyModal toggle={this.toggleEditFormModal} title="Attorney"
            open={this.state.openFormModal} row={this.state.row} propertyid={this.props.propertyid} />;
    }

}