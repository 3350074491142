import React, { Component } from "react";
import spinner from "../../images/search.gif"

export default class LoaderComponent extends Component {
    render() {
        return (
            <div className="loader-img-div">
                <img className="loader-img" src={spinner} alt="loading...."></img>
            </div>
        )
    }
}
