import React, { Component } from "react";
import '../../styles/navbar.css';
import '../../styles/content.css'
import apicall from '../../utils/apicall'
import UpdatePropertyModal from '../properties/property-upload/CreatePropertyModal';
import dayjs from 'dayjs'
import _ from "lodash"
import helpers from "../../utils/helpers";
import config from "../../utils/config";

export default class PropertyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            property: {},
            openUpdateModal: false
        }
    }

    componentWillReceiveProps(newprops) {
        this.setState({
            property: newprops.propertyInfo
        })
    }

    reloadProperty = (propertyId) => {
        apicall.getProperty(propertyId).then(
            response => {
                this.setState({
                    property: response
                })
            }
        )
    }

    toggleUpdateModal = () => {
        this.setState({
            openUpdateModal: !this.state.openUpdateModal
        });
    }

    handleOnClick= ()=> {
        this.props.handleHistoryTab()
    }

    generatePropertyInfoDiv = (property) => {
        const renderAssocVerifStatus = () => {
            const associationverifications = (this.props.actionArray && !_.isEmpty(this.props.actionArray.results)) && this.props.actionArray.results.filter(x => x.actionable_type === "AssociationVerification")
            if (!_.isEmpty(associationverifications)) {
                {
                    let DIV =
                    _.toLower(associationverifications[0].current_status) === _.toLower("HOA Exists") ?
                     <div style={{ fontSize: '15px',  color: 'green', marginTop: '5px' }}>HOA Status: {associationverifications[0].current_status}</div>
                    :
                
                    <div style={{ fontSize: '15px',  color: 'red', marginTop: '5px' }}>HOA Status: {associationverifications[0].current_status}</div>

                    return DIV;
                }
            }
        }

        let addressFields = ['street_address', 'city', 'state']
        let extraField = 'zip';
        return (
            <div onClick={this.handleOnClick} className="property-info">
                <div>
                    {
                        !this.props.hideLink ?
                            <a href={`/property-manage/${this.props.listofTabs.length}/${property.id}/0`}>{property.clientid}</a>
                            : property.clientid
                    }
                </div>
                {/* <div>
                    {property.clientid}
                </div> */}
                <div>
                    {property.entity_name}
                </div>
                <div>
                    {helpers.formatAddress(property, addressFields, extraField)}
                </div>
                <div>
                    Foreclosure Date : {helpers.formatDate(property.acquisition_date)}
                </div>
                <div>
                    Transfer Date: {helpers.formatDate(property.transfer_date)}
                </div>
                {
                    <div className={_.toLower(property.status) === 'active' ? 'green' : 'red'}>
                        Property Status: {
                            property.status ?
                                helpers.capitalizeFirstLetter(property.status)
            
                                : '---'
                        }
                    </div>
                }
                {renderAssocVerifStatus()}
            </div>
        )
    }



    render() {

        if (this.state.property !== null && this.state.property !== 'undefined' && Object.keys(this.state.property).length !== 0) {
            let property = this.state.property;

            let PropertyInfoDiv = this.generatePropertyInfoDiv(property);
            let userRole = this.props.userRole

            return (
                <React.Fragment>
                    <div className={"property-details " + this.props.className}>
                        <div onClick={() => this.toggleUpdateModal()}>
                            {userRole === 'admin' && <button className="edit-btn">
                                Edit
                            </button>}
                            <UpdatePropertyModal toggle={this.toggleUpdateModal} modal={this.state.openUpdateModal} property={property}
                                updateProperty={true} reloadProperty={this.reloadProperty} />
                        </div>
                        {PropertyInfoDiv}
                    </div>
                </React.Fragment>
            )
        } else {
            return null;
        }

    }
}
