import React from "react";
import apicall from "../../utils/apicall";
import { FaLink, FaTrashAlt, FaCheckSquare } from "react-icons/fa";
import "../../styles/_access-codes.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import dayjs from "dayjs";
import _ from "lodash";
import config from "../../utils/config";
import "../../styles/_payments.scss";
export default (props) => {
  const applicableFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }}>
        {row.applicable === false && <FaCheckSquare size="1.4em" />}
      </div>
    );
  };

  const deleteDocument = (row) => {
    if (props.isPayment === true) {
      props.deleteDocument(row.id);
    } else {
      apicall.deletePropertyDocument(props.propertyID, row.id);
      setTimeout(() => props.fetchDocuments(), 3000);
    }
  };

  const trashFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }}>
        <FaTrashAlt size="1.4em" onClick={() => deleteDocument(row)} />
      </div>
    );
  };
  const renderFileURL = (cell) => {
    return (
      <a
        href={cell}
        style={{ float: "none", color: "black" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLink size="1.5em" />
      </a>
    );
  };
  const renderTimestamp = (cell) => {
    return dayjs(cell).format(config.US_DATE_FORMAT);
  };

  const columns = [
    {
      dataField: "category",
      text: "Category",
    },
    {
      dataField: "name",
      text: "Name",
    },
    props.required
      ? { text: "Not Applicable", formatter: applicableFormatter }
      : { text: "Notes", dataField: "memo_notes" },
    {
      dataField: "file_url",
      text: "Link",
      formatter: renderFileURL,
      classes: "center-align",
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: renderTimestamp,
    },
    {
      text: "Action",
      formatter: trashFormatter,
    },
  ];

  return _.isEmpty(props.documents) ? (
    <h6></h6>
  ) : (
    <div className="documentTab">
      <BootstrapTable keyField="id" data={props.documents} columns={columns} />
    </div>
  );
};
