import React, { Component } from "react";
import { Button } from 'reactstrap';
import '../../styles/navbar.css';
import '../../styles/content.css';
import CreateAnalystModal from './CreateAnalystModal';
import AnalystListTable from './AnalystListTable';
import apicall from "../../utils/apicall";
import '../../styles/_users.scss'
import LoaderComponent from '../helper_components/LoaderComponent'
export default class CreateAnalystComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createAnalystModal: false,
            analystArray: [],
            isLoading: true,
            pageNo:1,
        }
    }

    componentWillMount() {
        this.getAnalysts();
    }

    getAnalysts = (event, pageNo, perPage, role) => {
        apicall.getAnalysts(pageNo, role, perPage).then(
            response => {
                this.setState({
                    analystArray: response.results,
                    totalCount:response.total,
                    isLoading: false,
                    pageNo: pageNo,
                })
            }
        );
    }

    toggleCreateAnalyst = () => {
        this.setState({
            createAnalystModal: !this.state.createAnalystModal,
        });
    }

    render() {

        return (
            <React.Fragment>
                {
                    this.state.isLoading ? (<LoaderComponent />) : (
                        <section className="content">
                            <header className="content-title users">
                                <div className="page-title">Users</div>
                                <div>
                                    <Button onClick={() => this.toggleCreateAnalyst()} className="createBtn">
                                        create
                                        </Button>
                            { this.state.createAnalystModal &&
                            <CreateAnalystModal toggle={this.toggleCreateAnalyst} modal={this.state.createAnalystModal}
                            getAnalysts={this.getAnalysts} /> }
                                </div>
                            </header>
                            <AnalystListTable analystArray={this.state.analystArray} getAnalysts={this.getAnalysts}
                                isLoading={this.state.isLoading} pageNo={this.state.pageNo} totalCount={this.state.totalCount} toggle={this.toggleCreateAnalyst}/>
                        </section>
                    )
                }
            </React.Fragment>
        )
    }
}