import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { FaEdit, FaTrashAlt, FaBan } from 'react-icons/fa';
import { Button } from 'reactstrap';
import FormComponent from './FormComponent';
import apicall from "../../utils/apicall";
import PaginationComponent from "../helper_components/PaginationComponent";
import config from '../../utils/config';
import helpers from '../../utils/helpers';
import ConfirmBox from '../helper_components/ConfirmBox';
import _ from 'lodash'
import Loader from '../helper_components/LoaderComponent'
export default class ListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openFormModal: false,
            refreshList: props.refreshList,
            pageNo: 1,
            row: {}
        }
    }

    toggleEditFormModal = () => {
        let existingStatus = this.state.openFormModal
        this.setState({
            openFormModal: !this.state.openFormModal
        });
        if(!existingStatus){
            this.props.refreshList();
        }
    }

    toggleAddFormModal = () => {
        this.setState({
            row: {},
            openFormModal: !this.state.openFormModal
        });
        this.props.refreshList();
    }

    toggleImportModal = () => {
        this.setState({
            openImportModal: !this.state.openImportModal
        });
        this.props.refreshList();
    }

    buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (config['hoa_mc_access'].includes(this.props.userRole)) {
            return (
                < div
                    style={{
                        textAlign: "center",
                        cursor: "pointer",
                        lineHeight: "normal"
                    }}>

                    <FaEdit style={{ marginRight: "4%" }} size="1.5em" onClick={() => {
                        this.setState({
                            row: row,
                            openFormModal: true
                        })
                    }} />

                    {
                        _.isEmpty(row.hoas) ?
                            <FaTrashAlt style={{ marginRight: "4%" }} size="1.5em"
                                onClick={() => this.deleteRecord(row)} /> :
                            <FaTrashAlt style={{ marginRight: "4%" }} size="1.5em"
                                onClick={() => this.setState({ row, deleteModal: true })} />
                    }
                </div>
            );
        } else {
            return (
                <FaBan className='font-awesome-disabled' size='1.6em' />

            )
        }
    }

    deleteRecord = (row) => {
        this.setState({isLoading: true})
        apicall.deleteManagementCompany(row.id).then(
            response => {
                if (response) {
                    helpers.notify("Management Company Deleted successfully")
                    this.props.refreshList();
                }
                this.setState({isLoading:false})
            }
        );
    }

    mailAddressFormatter = (cell, row, rowIndex, formatExtraData) => {
        let addressFields = ['mailing_street_name', 'mailing_city', 'mailing_state']
        let extraFields = 'mailing_zip';
        return (
            <>
                {helpers.formatAddress(row, addressFields, extraFields)}
            </>
        )
    }

    render() {
        let managementCompanies = this.props.managementCompanies;
        const columns = [{
            dataField: 'name',
            text: 'Management Company Name'
        }, //{
        //     dataField: 'information',
        //     text: 'Information'
        // },
        {
            dataField: 'contact_email',
            text: 'Contact Email'
        }, {
            dataField: 'contact_phone',
            text: 'Contact Phone'
        }, {
            dataField: 'mailing_contact_name',
            text: 'Mailing Address',
            formatter: this.mailAddressFormatter
        }, {
            dataField: "action",
            text: "Actions",
            sort: false,
            formatter: this.buttonFormatter,
            headerAttrs: { width: 200 },
            attrs: { width: 200, class: "table-actions" }
        }];

        let managementCompany = this.state.row;
        let pocArray = managementCompany.point_of_contacts;
        // delete managementCompany.point_of_contacts;
        console.log('managementCompany--->',managementCompany)
        return (
            <>
                {this.state.isLoading && <Loader/>}
                {
                    (config['hoa_mc_access'].includes(this.props.userRole)) &&
                    <div className="btn-group addactions">
                        <Button onClick={() => this.toggleAddFormModal()}>Add</Button>
                    </div>
                }
                <PaginationComponent columns={columns} rows={managementCompanies}
                    handleSubmit={this.props.refreshList} perPage={this.props.perPage} pageNo={this.props.pageNo}  totalCount={this.props.totalCount} />
              {  this.state.openFormModal && <FormComponent toggle={this.toggleEditFormModal} open={this.state.openFormModal} 
                    managementCompany={managementCompany} pocArray={pocArray ? pocArray : []}
                    area_analyst={{}} />}
                {this.state.deleteModal && <ConfirmBox
                    onYesClick={() => {
                        this.deleteRecord(this.state.row)
                        this.setState({ deleteModal: false })
                    }}
                    onNoClick={() => this.setState({ deleteModal: false })}
                    heading={<span>Are you sure you want to delete the Management Company: {this.state.row.name}?</span>}>
                    <div>
                        <p>HOAs {'['} {helpers.joinArrayBy(this.state.row.hoas, ",")} {']'} linked to this company will be permanently deleted</p>
                    </div>
                </ConfirmBox>
                }
            </>
        )
    }
}