import config from '../config';
import { withRouter } from 'react-router';
import helpers from '../helpers';
var paymentsapi = {
    fetchPayee: async function (propertyID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/payments/payees_and_recipients?property_id=' + propertyID;
        let init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while payee Fetch' + response)
            }).then(responseJson => {
                res = responseJson;
            }).catch(function (error) {


            })
        return res;
    },
    fetchPaymentTypes: async function (propertyID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/payments/payment_types?property_id=' + propertyID;
        let init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while payment types Fetch' + response)
            }).then(responseJson => {
                res = responseJson;
            }).catch(function (error) {


            })
        return res;
    },
    fetchPaymentHoas: async function (propertyID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/payments/property_hoas?property_id=' + propertyID;
        let init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while payment hoas Fetch' + response)
            }).then(responseJson => {
                res = responseJson;
            }).catch(function (error) {


            })
        return res;
    },
    fetchPaymentData: async function (actionID, propertyID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/payments/' + actionID + '?property_id=' + propertyID;
        let init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, while payment types Fetch' + response)
            }).then(responseJson => {
                res = responseJson;
            }).catch(function (error) {


            })
        return res;
    },
    paymentUpdate: async function (payment, propertyID, actionID) {
        let url = config.baseUrl + '/api/v1/payments/' + actionID + '?property_id=' + propertyID;
        let form = new FormData();
        for (var key in payment) {
            if (!Array.isArray(payment[key])) {
                form.append("payment[" + key + "]", payment[key]);
            }
        }
        let init = {
            method: "PUT",
            headers: await helpers.getSavedToken(),
            body: form
        };
        return await fetch(url, init)
            .then(helpers.fetchHandler)
            .then(json => json.json)
    },
    deleteCharge: async function (chargeID,actionID, propertyID) {
        let url = config.baseUrl + '/api/v1/payments/' + actionID + '/delete_charges?charge_id=' +chargeID + '&property_id=' + propertyID;
        var init = {
            method: "DELETE",
            headers: await helpers.defaultHeaders()
        };
        return await fetch(url, init)
        .then(helpers.fetchHandler)
        .then(json => json.json)
    },

    paymentCreate: async function (payment, propertyID) {
        let res = '';
        let url = config.baseUrl + '/api/v1/payments/?property_id=' + propertyID;
        let form = new FormData();
        for (var key in payment) {
            if (!Array.isArray(payment[key])) {
                form.append("payment[" + key + "]", payment[key]);
            }
        }
        let init = {
            method: "POST",
            headers: await helpers.getSavedToken(),
            body: form
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.clone().json();
                }
                throw new Error('Something went wrong, while payment Update' + response)
            })
            .then(responseJson => {

                res = responseJson;
            }).catch(function (error) {

            })
        return res;
    },
    addComments: async function (actionID,comments) {
        let url = config.baseUrl + '/api/v1/payments/' + actionID + '/payment_comments/bulk_create';
        let init = {
            method: "POST",
            headers: await helpers.defaultHeaders(),
            body:JSON.stringify(comments)
        };
        return await fetch(url, init)
        .then(helpers.fetchHandler)
        .then(json => json.json)
    },
    deletePaymentDoc: async function (propertyId, paymentId, documentId) {
        let url = config.baseUrl + '/api/v1/payments/'+ paymentId + "/delete_documents?document_id=" + documentId + "&property_id=" + propertyId;
        var init = {
            method: "DELETE",
            headers: await helpers.defaultHeaders()
        };
        return await fetch(url, init)
        .then(helpers.fetchHandler)
        .then(json => json.json)
    },
     fetchPropertyAssessments: async function (propertyID,hoa_id) {
        let res = '';
        let url = `${config.baseUrl}/api/v1/assessment_types/property_hoa_assessment_type?property_id=${propertyID}&hoa_id=${hoa_id}`;
        let init = {
            method: "GET",
            headers: await helpers.defaultHeaders()
        };
        await fetch(url, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong, assessment fetch' + response)
            }).then(responseJson => {
                res = responseJson;
            }).catch(function (error) {


            })
        return res;
    },
}
export default withRouter(paymentsapi);
