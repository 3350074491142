import React, {useState, useEffect } from "react"
import _ from "lodash"
import Loader from "../helper_components/LoaderComponent"
import '../../styles/_quick-stats.scss'
import Select from 'react-select'
import apicall from '../../utils/apicall';
export default props => {
	const [total_properties, set_totalProperties] = useState(null)
	const [active_properties, set_activeProperties] = useState(null)
	const [properties_association, set_propertyAssc] = useState(null)
	const [state_properties, set_stateProperties] = useState(null)
	const [state_properties_hoas, set_state_propertiesHoas] = useState(null)
	const [state_Hoas, set_stateHoa] = useState(null)
	const [loading, set_loading] = useState(false)
	const [selectedStates, set_selectedStates] = useState([])
	// const [states, set_States] = useState(null)
	// useEffect(
	// 	() => {
	// 		getStates()
	// 	}, []
	// )
	useEffect(
		()=> getQuickStats(),[selectedStates]
	)

	// const getStates = () => {
	// 	apicall
	// 		.getStates()
	// 		.then(response => {
	// 			let statesArray = [];
	// 			statesArray = response.results.states.map(
	// 				x => { return { value: x, label: x } }
	// 			)
	// 			set_States(statesArray)
	// 		})
	// }

	const getQuickStats = () => {
		set_loading(true)
		apicall.getQuickStats(selectedStates).then(
			response => {
				set_totalProperties(response.results.aggregate.total)
				set_activeProperties(response.results.aggregate.active)
				set_propertyAssc(response.results.aggregate.association_verification)
				set_stateProperties(response.results.state_filter.all_properties)
				set_state_propertiesHoas(response.results.state_filter.hoa_properties)
				set_stateHoa(response.results.state_filter.hoas)
				set_loading(false)
			}
		)
	}

	const keyValueDiv = (key, value) => {
		return (
			<div className="key-value">
				<div className="key">
					{key}
				</div>
				<div className="value">
					{value}
				</div>
			</div>
		)
	}

	let statesText = '';
	if(	_.isEmpty(selectedStates)){
		statesText = 'all States'
	}else{
		statesText = 'selected states'
	}
	return (
		<div className="quick-stats">
			{loading && <Loader />}
			<div className="heading">
				Quick Stats
			</div>
			<div className="section">
				<div className="properties">
					{keyValueDiv('Total Properties', total_properties)}
					{keyValueDiv('Active Properties', active_properties)}
				</div>
				<div className="properties-av">
					{keyValueDiv('Properties in Association Verification', properties_association)}
				</div>
				<div className="state-filter">
					<div className="dropdown">
					  <Select 
					  options={props.states ? props.states: []}
					  isMulti={true}
					  onChange = {
						  (value) => {
							set_selectedStates(value)
						  }
					  }
					  placeholder="Select States..."
					  className="states-select"
					  />
					</div>
					<div className="results">
						{keyValueDiv(`Properties in ${statesText}`, state_properties)}
						{keyValueDiv(`Properties with HOA's in ${statesText}`, state_properties_hoas)}
						{keyValueDiv(`HOA's in ${statesText}` , state_Hoas)}
					</div>
				</div>
			</div>
		</div>
	)

}