import React, { Component } from "react"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import { Link } from "react-router-dom"
import config from "../../utils/config"
import dashboardapi from "../../utils/api/dashboardapi"
import apicall from "../../utils/apicall"
import dayjs from "dayjs"
import _ from "lodash"
import PaginationComponent from "../helper_components/PaginationComponent"
import AskSearchQuestionsModal from "../properties/AskSearchQuestionsModal"
import LienModal from "../lien/LienModal"
import InspectionModal from "../inspection/InspectionModal"
import ViolationModal from "../violations/ViolationModal"
import PaymentModal from "../payments/paymentModal"

export default class DashboardTable extends Component {
    constructor(props) {
        console.log("222222222222222222222222222")
        console.log(props)
        super(props)
        this.state = {
            properties: [],
            pageNo: 1,
            perPage:10,
            associationverificationModal: false
        }
    }

    componentWillMount() {
    config.localforage.getItem('user_role').then(role => {
        this.setState( { userRole: role })
    }).catch(e => console.log(e))
    config.localforage.getItem('search-text').then(searchText => {
        this.setState({ searchText: searchText})
    }).catch(e => console.log(e))
    config.localforage.getItem('page-number').then(pageNo => {
        this.setState({ pageNo: pageNo})
    }).catch(e => console.log(e))

    config.localforage.getItem('per-page').then(perPage => {
        this.setState({ perPage: perPage})
    }).catch(e => console.log(e))
}
    // componentDidUpdate(prevProps) {
    //     if (!_.isEqual(prevProps, this.props)) {
    //         this.setState({
    //             properties: [],
    //             pageNo: 1,
    //         })
    //         this.getActionTypePropertyActions(1)
    //     }
    // }

    getActionTypePropertyActions(pageNo,perPage) {
        dashboardapi
            .getActionTypePropertyActions(this.props, pageNo,perPage)
            .then(response => {
                this.setState({ properties: response.results, totalCount: response.total })
            })
            .catch(e => console.log(e))
    }

    handleSubmit(event, pageNo,perPage) {
        this.setState({
            pageNo: pageNo,
            perPage: perPage
        })
        this.getActionTypePropertyActions(pageNo,perPage)
        event.preventDefault()
    }
    actionTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (cell.trim().toLowerCase() !== "documentinforeport"
            && cell.trim().toLowerCase() !== "pir" && cell.trim().toLowerCase() !== "associationverification") {
            return (
                <React.Fragment>
                    <div
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => {

                            this.setState({
                                [cell.trim().toLowerCase() + "Modal"]: !this.state[cell.trim().toLowerCase() + "Modal"],
                                row: row,
                            })
                        }}
                    >
                        {cell}
                    </div>
                </React.Fragment>
            )
        }
        let tabNo = cell.trim().toLowerCase() === "documentinforeport" ? 5 : null
        tabNo = cell.trim().toLowerCase() === "pir" ? 1 : tabNo
        if (tabNo !== null) {
            return (
                <Link className="link" to={"/property-manage/" + tabNo + "/" + row.property_id} target="_blank">
                    {cell}
                </Link>
            )
        }
        return cell
    }
    linkFormatter = (cell, row, rowIndex, formatExtraData) => {
        if (typeof row !== "undefined") {
            return (
                <Link to={"/property-manage/2/" + row.property_id + '/' + row.hoa_id} target="_blank" key={cell + row.id}>
                    {cell}
                </Link>
            )
        }
        return null
    }
    columns() {
        return [
            {
                dataField: "clientid",
                text: "Client ID",
                formatter: this.linkFormatter,
            },
            {
                dataField: "actionable_type",
                text: "Action Type",
                formatter: this.actionTypeFormatter,
            },
            {
                dataField: "full_address",
                text: "Address",
            },
            {
                dataField: "zip",
                text: "Zip",
            },
            {
                dataField: "age",
                text: "Age in days",
            },
            {
                dataField: "due_date",
                text: "Due Date",
            },
            {
                dataField: "current_status",
                text: "Current Status",
            },
            {
                dataField: "notes",
                text: "Notes",
            },
        ]
    }
    render() {
        let properties = this.props.tableData
            .map(x => {
                return { ...x, ...x.property }
            })
            .map(x => {
                const full_address = [x.street_address, x.city, x.county, x.state].join(", ")
                let diff
                if (x.due_date) diff = dayjs(x.due_date).diff(dayjs(), "day")

                const age = diff && diff < 0 ? 0 : diff

                return { ...x, full_address, age }
            })

        return (
            <React.Fragment key={this.props.action_type}>
                <PaginationComponent
                    columns={this.columns()}
                    rows={properties}
                    handleSubmit={(event, pageNo,perPage) => this.handleSubmit(event, pageNo,perPage)}
                    pageNo={this.state.pageNo}
                    perPage={this.state.perPage}
                    totalCount={this.props.table_data&&this.props.table_data.total}
                    hidePerPage={true}
                />
                {this.state.associationverificationModal && (
                    <AskSearchQuestionsModal
                        toggle={() => {
                            this.setState({
                                associationverificationModal: !this.state.associationverificationModal,
                            })
                        }}
                        modal={true}
                        row={this.state.row}
                        propertyID={this.state.row.property_id}
                    />
                )}
                {this.state.lienModal && (
                    <LienModal
                        toggle={() => {
                            this.setState({
                                lienModal: !this.state.lienModal,
                            })
                        }}
                        title="Lien"
                        open={true}
                        row={this.state.row}
                        propertyID={this.state.row.property_id}
                        actionID={this.state.row.actionable_id}
                        action={this.state.row}
                    />
                )}
                {this.state.inspectionModal && (
                    <InspectionModal
                        toggle={() => {
                            this.setState({
                                inspectionModal: !this.state.inspectionModal,
                            })
                        }}
                        title="Inspection"
                        open={true}
                        row={this.state.row}
                        propertyID={this.state.row.property_id}
                        actionID={this.state.row.actionable_id}
                    />
                )}
                {this.state.violationModal && (
                    <ViolationModal
                        toggle={() => {
                            this.setState({
                                violationModal: !this.state.violationModal,
                            })
                        }}
                        title="Violation"
                        open={true}
                        row={this.state.row}
                        propertyID={this.state.row.property_id}
                        actionID={this.state.row.actionable_id}
                    />
                )}
                {this.state.paymentModal && (
                    <PaymentModal
                        toggle={() => {
                            this.setState({
                                paymentModal: !this.state.paymentModal,
                            })
                        }}
                        title="Payment"
                        open={true}
                        row={this.state.row}
                        propertyID={this.state.row.property_id}
                        actionID={this.state.row.actionable_id}
                    />
                )}
                   {/* {this.state.associationinformationModal &&
                        <AssociationInformationModal toggle={() => {
                            this.setState({
                                associationinformationModal: !this.state.associationinformationModal
                            })
                        }} title="HOA" open={this.state.associationinformationModal} 
                                row={_.has(this.state.row,'hoa') && this.state.row.hoa}
                            propertyID={this.state.propertyID} actionID={this.state.actionID} />
                    } */}
            </React.Fragment>
        )
    }
}
